import { useEffect, useState } from 'react';
import { DefaultTabsListContainer } from './style';
import { MdAddCircle } from 'react-icons/md';
import api from '../../../services/axios';
import _ from 'lodash';

export function DefaultTabsList({ setSelectedTab, existTabs }) {
  const [defaultTabs, setDefaultTabs] = useState(null);

  async function handleGetDefaultTabs() {
    try {
      const response = await api.get('tabs');
      const tabsDefaultArray = parseTabsArray(response.data);
      setDefaultTabs(tabsDefaultArray);
    } catch (error) {
      console.log(error)
    }
  }

  function parseTabsArray(data) {
    const tabArray = existTabs.map(({ tab_id }) => {
      if (tab_id.is_default) {
        return { _id: tab_id._id, title: tab_id.title }
      }
    })

    const result = _.differenceWith(data, tabArray, _.isEqual);
    return result
  }

  useEffect(() => {
    if (!defaultTabs) {
      handleGetDefaultTabs();
    }
  }, [])

  return (
    <>
      {defaultTabs?.length > 0 &&
        <DefaultTabsListContainer>
          <section>
            <div className="header">
              <h3>Adicionar Aba Padrão</h3>
            </div>
            {defaultTabs.map((tab) => (
              <li className="tab__container">
                <h3>{tab.title}</h3>
                <button onClick={() => setSelectedTab(tab, true)}>
                  <MdAddCircle />
                </button>
              </li>
            ))}
          </section>
        </DefaultTabsListContainer>
      }
    </>
  );
}
