import { uploadBytesResumable, ref } from 'firebase/storage';
import { toast } from 'react-toastify';
import { useStorage } from 'reactfire';
import api from '../../../../services/axios';

import { HandleShowImage } from '../../../../services/firebase/config';

export default function UploadImageContainer({
  setCoverImage,
  coverImage,
  initialImage,
}) {
  const storage = useStorage();

  async function uploadImage(img) {
    try {
      const parsedImageName = img.name
        .normalize('NFD')
        .replace(/([\u0300-\u036f]|[^0-9a-zA-Z.])/g, '');

      const newRef = ref(
        storage,
        `images/${parsedImageName}`
      );

      await api.put('/image/update-by-name', {imageName: parsedImageName})

      uploadBytesResumable(newRef, img);

      setCoverImage({
        ...coverImage,
        imageHasChange: true,
        file: img,
        url: `images/${parsedImageName}`
      });

    } catch (error) {
      toast.error('Ocorreu um erro!', {
        theme: 'colored',
        closeOnClick: true,
      });
      console.log(error);
    }
  }

  return (
    <>
      <input
        className="input__image"
        type="file"
        accept="image/*"
        onChange={
          (e) => uploadImage(e.target.files[0])
        }
      />
      <HandleShowImage
        image={
          coverImage?.imageHasChange
            ? coverImage.url
            : initialImage
        }
      />
    </>
  );
}
