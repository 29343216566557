import { useEffect, useState } from 'react';
import _ from 'lodash';
import { toast } from 'react-toastify';

import api from '../../../services/axios';
import ContentListItem from '../ContentListItem';
import { AddContentOnCard } from '../AddContentOnCard';

import { Container } from './style';

export function ContentList({ card, setContentChange }) {
  const [contentArray, setContentArray] = useState(null);
  const [isContentsLoad, setIsContentsLoad] = useState(false);
  const [deleteCallbackIsOn, setDeleteCallbackIsOn] = useState(false);

  const [isAddContent, setIsAddContent] = useState(false);

  function changePosition(from, to) {
    const newArr = [...contentArray];

    newArr[from - 1] = { ...newArr[from - 1], position: to };
    newArr[to - 1] = { ...newArr[to - 1], position: from };
    newArr.sort((a, b) => a.position - b.position);
    for (const content of newArr) {
      if (content.position === from || content.position === to) {
        handlePositionChange(content);
      }
    }
    setContentArray([...newArr]);
  }

  function handlePositionChangeByDelete(deletedContent) {
    // remover o conteudo deletado com lodash, percorrer o array validando as posições e salvando no banco as diferentes
    let aux = [...contentArray];
    _.remove(aux, deletedContent)

    aux.map(async (content, idx) => {
      if(content.position !== (idx + 1)) {
        await handlePositionChange({...content, position: idx + 1})
      }
    })

    setIsContentsLoad(false)
  }

  async function handlePositionChange(aux) {
    try {
      await api.put(`/content/update/${aux._id}`, { position: aux.position });
    } catch (error) {
      toast.error('Ocorreu um erro!', {
        theme: 'colored',
        closeOnClick: true,
      });
      console.log(error);
    }
  }

  async function handleGetContents() {
    try {
      const response = await api.get(`/card/${card.card_id._id}`);
      setContentArray(response.data.contents_id);

      setIsContentsLoad(true);
    } catch (error) {}
  }

  useEffect(() => {
    if (card) {
      setIsContentsLoad(false);
    }
  }, [card]);

  useEffect(() => {
    if (!isContentsLoad) {
      handleGetContents();
    }
  }, [isContentsLoad]);

  return (
    <>
      {!isAddContent ? (
        <>
          <li>
            <h3>Selecione o conteúdo:</h3>
          </li>
          <Container>
            <section className="contentList">
              {contentArray &&
                isContentsLoad &&
                contentArray
                  .sort((a, b) => a.position - b.position)
                  .map((content) => (
                    <ContentListItem
                      setIsLoad={setIsContentsLoad}
                      deleteCallback={handlePositionChangeByDelete}
                      cardId={card?.card_id._id}
                      content={content}
                      changePosition={changePosition}
                      contentArray_length={contentArray.length}
                      setContentChange={setContentChange}
                    />
                  ))}
            </section>
          </Container>
          <li className="content__listItem">
            <button
              onClick={() => setIsAddContent(true)}
              className="listItem__button"
            >
              Adicionar conteúdo
            </button>
          </li>
        </>
      ) : (
        <AddContentOnCard
          closeModal={() => {
            setIsAddContent(false);
            setIsContentsLoad(false);
          }}
          card={card}
          contentArrayLength={contentArray?.length}
        />
      )}
    </>
  );
}
