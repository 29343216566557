import { useContext, useEffect, useState } from "react";

import EditorHtml from "../../atoms/EditorHtml";
import ModalImageComponent from "../../molecules/ModalImageComponent";
import { ContextAplication } from "../../../context";
import { contentTypeList } from "../../../services/utils/contentTypeList";
import { HandleShowImage } from '../../../services/firebase/config'
import { handleSubmitContent } from "../../atoms/EditorHtml/utils/handleSubmitContent";

import { Container } from "./style";
import { SwitchButton } from "../../atoms/SwitchButton";

const accordionType = [
  { title: "Texto", value: "TX" },
  { title: "Enumeração", value: "EN" },
  { title: "Tabela", value: "TA" },
  { title: "Fluxograma", value: "FL" },
  { title: "Calculadora", value: "CA" },
  { title: "Imagem", value: "" },
];

export function TextEditor({ setContentChange, routineId }) {
  const { isSelected, currentContent, handleGetCurrentContent } =
    useContext(ContextAplication);

  const [content, setContent] = useState(null);

  const [textBeforeEditing, setTextBeforeEditing] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const [imagesToShow, setImagesToShow] = useState(null);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [contentTextState, setContentTextState] = useState(null);
  const [arrayOfImagesBeforeEditing, setArrayOfImagesBeforeEditing] = useState(
    []
  );
  const [arrayOfLinksBeforeEditing, setArrayOfLinksBeforeEditing] = useState(
    []
  );

  async function submitHandler() {
    if (
      await handleSubmitContent(
        content,
        contentTextState,
        arrayOfImagesBeforeEditing,
        content?.content_type_id.code,
        textBeforeEditing,
        arrayOfLinksBeforeEditing
      )
    ) {
      handleGetCurrentContent(content._id);
      setContentChange(true);
    }
  }

  function handleParseImagesToContentText(images) {
    let parsedHTMLWithImages = `<p>${images.map(
      (img) => `<img src="${img}">`
    )}</p>`;

    parsedHTMLWithImages = parsedHTMLWithImages.replaceAll(",", "");
    setContent({ ...content, text: parsedHTMLWithImages });
  }

  function handleParseScrollableImagesToContentText(images) {
    let parsedHTMLWithImages = `${images.map(
      (img) => `<img src="${img}">`
    )}`;

    parsedHTMLWithImages = parsedHTMLWithImages.replaceAll(",", "");
    setContent({ ...content, text: parsedHTMLWithImages });
  }

  function handleParseImagesToShow() {
    const arrayOfImages = content?.text?.match(/<img(.*?)>/g);

    if (!arrayOfImages || arrayOfImages?.length === 0) return;

    const aux = arrayOfImages?.map((img) => img.replace(/<img src=\"|">/g, ""));
    setImagesToShow(aux);
  }

  useEffect(() => {
    // textBeforeEditing vai servir para poder listar as imagens antes da edição para validar caso tenha mudado alguma em conteúdos que nao possuem o editorHTML
    setContent(currentContent);
    setTextBeforeEditing(currentContent?.text);
    setArrayOfImagesBeforeEditing([]);
    setArrayOfLinksBeforeEditing([]);
    setImagesToShow(null);
  }, [currentContent]);

  useEffect(() => {
    if (
      content?.content_type_id?.code === "caroseul" ||
      content?.content_type_id?.code === "scrollable_image" ||
      content?.content_type_id?.code === "image"
    ) {
      handleParseImagesToShow();
    }
  }, [content]);

  const formByContentType = {
    text: (
      <div>
        <EditorHtml
          routineId={routineId}
          setContentTextState={setContentTextState}
          content={content}
          setContent={setContent}
          isSubmitting={isSubmitting}
          setArrayOfImagesBeforeEditing={setArrayOfImagesBeforeEditing}
          setArrayOfLinksBeforeEditing={setArrayOfLinksBeforeEditing}
          arrayOfImagesBeforeEditing={arrayOfImagesBeforeEditing}
        />
      </div>
    ),

    accordion: (
      <div>
        <label className="input__label">
          <h3>Tipo do ícone</h3>
          <select
            onChange={(e) =>
              setContent({ ...content, component_type: e.target.value })
            }
            value={content?.component_type}
          >
            <option value=''>Selecione o tipo de accordion</option>
            {accordionType?.map((type) => (
              <option value={type.value}>{type.title}</option>
            ))}
          </select>
        </label>
        <h3>Estado</h3>
        <label className='openOrClose__label'>
          <SwitchButton 
            checked={content?.component_open}
            onChange={(e) =>
              setContent({ ...content, component_open: e.target.checked })
            }
          />
        </label>
        <EditorHtml
          routineId={routineId}
          setContentTextState={setContentTextState}
          content={content}
          setContent={setContent}
          isSubmitting={isSubmitting}
          setArrayOfImagesBeforeEditing={setArrayOfImagesBeforeEditing}
          setArrayOfLinksBeforeEditing={setArrayOfLinksBeforeEditing}
          arrayOfImagesBeforeEditing={arrayOfImagesBeforeEditing}
        />
      </div>
    ),

    accordion_html: (
      <div>
        <label  className="input__label">
          <h3>Tipo do ícone</h3>
          <select
            onChange={(e) =>
              setContent({ ...content, component_type: e.target.value })
            }
            value={content?.component_type}
          >
            <option value=''>Selecione o tipo de accordion</option>
            {accordionType?.map((type) => (
              <option value={type.value}>{type.title}</option>
            ))}
          </select>
        </label>
        <h3>Estado</h3>
        <label className='openOrClose__label'>
          <SwitchButton 
            checked={content?.component_open}
            onChange={(e) =>
              setContent({ ...content, component_open: e.target.checked })
            }
          />
        </label>

        <label>
          <h3>Conteúdo HTML</h3>
          <textarea
            value={content?.text}
            onChange={(e) => setContent({ ...content, text: e.target.value })}
            name=''
            id=''
          />
        </label>
      </div>
    ),

    caroseul: (
      <div>
        <button className='image__button' onClick={() => setIsOpen(true)}>
          Adicionar carrossel de imagens
        </button>
        {isOpen && (
          <ModalImageComponent
            initialImages={imagesToShow}
            onAddImage={handleParseImagesToContentText}
            closeModal={() => setIsOpen(false)}
          />
        )}
        {imagesToShow && (
          <div className='showContentImages__container'>
            {imagesToShow.map((image) => (
              <HandleShowImage image={image} style={{ width: "100px", height: "100px" }}/>
            ))}
          </div>
        )}
      </div>
    ),

    container_accordion_title: (
      <div>
        <label className="input__label">
          <h3>Tipo do ícone</h3>
          <select
            onChange={(e) =>
              setContent({ ...content, component_type: e.target.value })
            }
            value={content?.component_type}
          >
            <option value=''>Selecione o tipo de accordion</option>
            {accordionType?.map((type) => (
              <option value={type.value}>{type.title}</option>
            ))}
          </select>
        </label>
        <h3>Estado</h3>
        <label className='openOrClose__label'>
          <SwitchButton 
            checked={content?.component_open}
            onChange={(e) =>
              setContent({ ...content, component_open: e.target.checked })
            }
          />
        </label>
      </div>
    ),

    container_accordion: (
      <div>
        <label className="input__label">
          <h3>Tipo do ícone</h3>
          <select
            onChange={(e) =>
              setContent({ ...content, component_type: e.target.value })
            }
            value={content?.component_type}
          >
            <option value=''>Selecione o tipo de accordion</option>
            {accordionType?.map((type) => (
              <option value={type.value}>{type.title}</option>
            ))}
          </select>
        </label>
      </div>
    ),

    image: (
      <div>
        <button className='image__button' onClick={() => setIsOpen(true)}>
          Adicionar imagem
        </button>
        {isOpen && (
          <ModalImageComponent
            initialImages={imagesToShow}
            onAddImage={handleParseImagesToContentText}
            closeModal={() => setIsOpen(false)}
          />
        )}
        {imagesToShow && (
          <div className='showContentImages__container'>
            {imagesToShow.map((image) => (
              <HandleShowImage image={image} style={{ width: "100px", height: "100px" }}/>
            ))}
          </div>
        )}
      </div>
    ),

    popup_html: (
      <div>
        <label className="input__label">
          <h3>Tipo do ícone</h3>
          <select
            onChange={(e) =>
              setContent({ ...content, component_type: e.target.value })
            }
            value={content?.component_type}
          >
            <option value=''>Selecione o tipo de accordion</option>
            {accordionType?.map((type) => (
              <option value={type.value}>{type.title}</option>
            ))}
          </select>
        </label>
        <h3>Estado</h3>
        <label className='openOrClose__label'>
          <SwitchButton 
            checked={content?.component_open}
            onChange={(e) =>
              setContent({ ...content, component_open: e.target.checked })
            }
          />
        </label>

        <label>
          <h3>Conteúdo HTML</h3>
          <textarea
            value={content?.text}
            onChange={(e) => setContent({ ...content, text: e.target.value })}
            name=''
            id=''
          />
        </label>
      </div>
    ),

    scrollable_image: (
      <div>
        <button className='image__button' onClick={() => setIsOpen(true)}>
          Adicionar imagens scrolláveis
        </button>
        {isOpen && (
          <ModalImageComponent
            initialImages={imagesToShow}
            onAddImage={handleParseScrollableImagesToContentText}
            closeModal={() => setIsOpen(false)}
          />
        )}
        {imagesToShow && (
          <div className='showContentImages__container'>
            {imagesToShow.map((image) => (
              <HandleShowImage image={image} style={{ width: "100px", height: "100px" }}/>
            ))}
          </div>
        )}
      </div>
    ),
  };

  return (
    <Container>
      {content ? (
        <>
          <h4 className='title__type'>{content.content_type_id.title}</h4>
          <section className='content__header'>
            <label className="input__label">
              <h3>Título</h3>
              <input
                type='text'
                value={content?.title}
                onChange={(e) =>
                  setContent({ ...content, title: e.target.value })
                }
              />
            </label>

            {isSelected && (
              <label  className="input__label">
                <h3>Tipo</h3>
                <select
                  id=''
                  onChange={(e) =>
                    setContent({
                      ...content,
                      content_type_id: { code: e.target.value },
                    })
                  }
                  value={content?.content_type_id.code}
                >
                  <option value=''>Selecione o tipo do conteúdo</option>
                  {contentTypeList?.map((contentType) => (
                    <option value={contentType.code}>
                      {contentType.title}
                    </option>
                  ))}
                </select>
              </label>
            )}
          </section>

          <section className='content__body'>
            {formByContentType[content?.content_type_id.code]}
          </section>

          <section className='submit__button'>
            <button onClick={() => submitHandler()}>Salvar</button>
          </section>
        </>
      ) : (
        <div className='loading__div'>
          <lottie-player
            src='https://assets2.lottiefiles.com/packages/lf20_qqudyjgw.json'
            background='transparent'
            speed='1'
            style={{ width: "300px", height: "300px" }}
            loop
            autoplay
          />
          <h3>Carregando...</h3>
        </div>
      )}
    </Container>
  );
}
