import styled from "styled-components";

export const SingleAccordion = styled.div`
  .accordion {
    background-color: var(--dark-color);
    border-radius: 5px;
    padding: 5px;
  }

  .accordion-header {
    display: flex;
    align-items: center;
    min-height: 48px;
    padding-bottom: 5px;
    position: sticky;
    top: var(--topValue);
    background-color: var(--dark-color);
    z-index: 2;
  }

  .accordion-type-icon {
    width: 48px;
    height: 48px;
  }

  .accordion-title {
    width: 100%;
    padding-left: 10px;
    margin: 5px 0;
    color: white;
  }

  .accordion-open-close-indicator {
    font-size: 25px;
    margin-right: 10px;
    color: white;
    &:hover {
      cursor: pointer;
    }
  }
  
  .closed{
    display: none;
  }
  
  .accordion-body {
    padding: 10px;
    background-color: var(--soft-color);
  }

  .accordion-body > div > * {
    margin-bottom: 10px;
    word-break: break-word;
  }

  .accordion-body > p {
    margin-bottom: 10px;
    word-break: break-word;
  }
`;
