import { stateToHTML } from 'draft-js-export-html';

let arrayOfImagesAfterEditing = []
let arrayOfLinksAfterEditing = [];

async function parseCarousel(text) {
  const before = await text.match(/<div class="carousel js-carousel"[^>]*>(.*?)<\/div>/gm)
  if (before) {

    const after = await before.map((item) => {
      let aux = item.replace(/<div class="carousel js-carousel" data-content="|">(.*?)<\/div>/g, '').split(',')

      aux = `<div class="carousel js-carousel"><div class="carousel-items js-carousel-items"><p>${aux?.map((img) => {
        arrayOfImagesAfterEditing.push(img.replace('images/', ''))
        return `<img src="${img}">`
      })}</p></div></div>`
      return aux.replaceAll(',', '')
    })
    await before.forEach((_, idx) => {
      text = text.replace(before[idx], after[idx])
    })
  }
  return text
}

async function parseScrollableImage(text) {
  const temp = text.replaceAll(/<div class="scrollable-image js-scrollable-container">a<\/div>/gm, '')
  let before = await temp.match(/<div class="scrollable-image js-scrollable-container"[^>]*>(.*?)<\/div>/gm)
  if (before) {

    before = before.filter((value, idx) => before.indexOf(value) === idx);

    const after = await before.map((item) => {
      let aux = item.replace(/<div class="scrollable-image js-scrollable-container" data-content="|">(.*?)<\/div>/g, '').split(',')
      arrayOfImagesAfterEditing.push(aux[0].replace('images/', ''))

      aux = `<div style="--img-height: 239px;" class="scrollable-image js-scrollable-container"><div class="scrollable-indicator js-scrollable-indicator"><span></span></div><img src="${aux[0]}"></div>`
      return aux
    })

    await before.forEach((_, idx) => {
      text = text.replace(before[idx], after[idx])
    })
  }

  text = text.replaceAll(/<div class="scrollable-image js-scrollable-container">a<\/div>/gm, '')
  return text;
}

async function parseImage(text) {
  const before = await text.match(/<div class="image-to-parse"[^>]*>(.*?)<\/div>/gm)
  if (before) {

    const after = await before.map((item) => {
      let aux = item.replace(/<div class="image-to-parse" src="|">(.*?)<\/div>/g, '').split(',')
      arrayOfImagesAfterEditing.push(aux[0].replace('images/', ''))

      aux = `<p><img src="${aux[0]}"></p>`
      return aux
    })


    await before.forEach((_, idx) => {
      text = text.replace(before[idx], after[idx])
    })
  }

  return text;
}
async function parseLinks(text) {
  const before = await text.match(/<div class="link-to-parse"[^>]*>(.*?)<\/div>/gm)

  if(before) {
    const parsed = await before.map((item) => {
      const isPendingLink = item.includes('data-pending="true"')
      const linkText = item.replace(/<div(.*?)">|<\/div>/g, '')
      const linkHref = item.replace(/<div class="link-to-parse" href="|" data-pending="true|">(.*?)<\/div>/gm, '')
     
      let aux = `<a href="${linkHref}"${isPendingLink ? ' data-pending="true"' : ''}>${linkText}</a>`


      arrayOfLinksAfterEditing.push({ 
        link_id: linkHref.replace(/%7B%7Blink=%22|%22%7D%7D/gm, ''), 
        link_origin_text: linkText 
      })

      return {before: item, after: aux}
    })

    await parsed.forEach((item) => {
      text = text.replace(item.before, item.after)
    })
  }

  return text;
}


async function cleanHTML(text) {
  text = await text.replace(
    /<figure>|<\/figure>|<p><\/p>|<p> <\/p>|&nbsp;<\/img><\/figure>|<p><br><\/p>|a<\/figure>|<br>|&nbsp;/gi, ''
  )

  return text;
}

async function parseHTML(html) {
  html = await parseCarousel(html)
  html = await parseScrollableImage(html)
  html = await parseImage(html)
  html = await parseLinks(html)

  html = await cleanHTML(html)
  return html;
}

export async function convertContentToHTML(contentState) {
  arrayOfImagesAfterEditing = []
  arrayOfLinksAfterEditing = []

  let arrayOfScrollableImages = []

  let options = {
    inlineStyles: {
      BOLD: { element: 'strong' },
      ITALIC: { element: 'em' },
      SUBSCRIPT: { element: 'sub'},
      SUPERSCRIPT: { element: 'sup'}
    },
    blockRenderers: {
      atomic: () => {
        return null;
      },
    },
    entityStyleFn: (entity) => {
      const entityType = entity.get('type');


      if (entityType === 'LINK') {
        const data = entity.getData();

        if(data?.isExternalLink) {
          return {
            element: 'a',
            attributes: {
              href: data.href
            }
          }
        }

        return {
          element: 'div',
          attributes: {
            class: 'link-to-parse',
            href: data.href,
            'data-pending': data?.isPendingLink || null
          },
        };
      }

      if (entity.type === 'carousel') {
        const data = entity.getData();

        return {
          element: 'div',
          attributes: {
            class: "carousel js-carousel",
            'data-content': data?.imgarray
          },
        }
      }

      if (entity.type === 'scrollable-image') {
        const data = entity.getData();

        if(arrayOfScrollableImages.includes(data?.src)) {
          return null;
        } else {
          arrayOfScrollableImages.push(data?.src)
          return {
            element: 'div',
            attributes: {
              class: "scrollable-image js-scrollable-container",
              'data-content': data?.src
            }
          }
        }


      }

      if (entity.type === 'image') {
        const data = entity.getData();

        return {
          element: 'div',
          attributes: {
            class: "image-to-parse",
            src: data.src
          }
        }
      }

      if (entity.type === 'font-color') {
        const data = entity.getData();

        return {
          element: 'span',
          style: {
            color: data.color
          }
        }
      }
    },
  }

  let html = stateToHTML(contentState, options);

  const parsedHtml = await parseHTML(html)

  return { parsedHtml, arrayOfImagesAfterEditing, arrayOfLinksAfterEditing };
}
