import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { ContextAplication } from "../../../context";
import { BackButtonComponent } from "./style";

export function BackButton({ route }) {
  const history = useHistory()
  const { handleGetCurrentContent } = useContext(ContextAplication)
  function handleBack() {
    handleGetCurrentContent(null);
    history.push(`/${route}`)
  }
  return (
    <BackButtonComponent onClick={() => handleBack()}>
      Voltar
    </BackButtonComponent>
  )
}
