import { useState } from "react";
import { updateResumeCardKeywords } from "../../../services/utils/updateResumeCardKeywords";
import { CardFormComponent } from "../CardFormComponent";
import { RoutineTabCardsHeaderContainer } from './style';

export function RoutineTabCardsHeader({ tab, setIsLoad, routine_keywords = null, routine_title = '' }) {
  const [isCreateCardOpen, setIsCreateCardOpen] = useState(false);

  async function closeModalCallback(e) {
    if(e) {
      await updateResumeCardKeywords(e, routine_keywords, routine_title)
    }
    setIsCreateCardOpen(false); 
    setIsLoad(false)
  }

  return (
    <RoutineTabCardsHeaderContainer>
      <div className="header">
        <h3>Cards da aba "{tab.tab_id.title}":</h3>
        <button
          className="addNewCard__button"
          onClick={() => setIsCreateCardOpen(true)}>
          Adicionar novo card
        </button>
      </div>
      {isCreateCardOpen && (
        <CardFormComponent creation={true} closeModal={(e) => closeModalCallback(e)} tab={tab} tabLength={tab.routine_tabs_cards_id} />
      )}
    </RoutineTabCardsHeaderContainer>
  );
}
