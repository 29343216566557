import JwtDecode from "./jwtDecode";

export const TOKEN_KEY = "BBK_ROUTINE_TOKEN";

export const isAuthenticated = () => {
  const token = JwtDecode();
  if (!token) {
    return false;
  }
  var dateNow = new Date();
  if (token.exp < dateNow.getTime() / 1000) {
    return false;
  }
  return true;
};

export const handleLogin = (token) => {
  localStorage.setItem(TOKEN_KEY, token);
};

export const getToken = () => localStorage.getItem(TOKEN_KEY) || null;
