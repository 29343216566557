import styled from 'styled-components';

export const RoutineTabCardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--content);
  border-radius: 5px;
  margin-top: 1rem;

  .header {
    border-radius: 5px 5px 0 0;
    background-color: var(--secondary);
    padding: 1rem;

    .header__title {
      font-size: 1.3rem;
    }
  }

  .content {
    padding: 1rem;

    .tab__select {
      background-color: unset;
      width: 100%;
      padding: 1rem;
      border: none;
      border-bottom: 1px solid var(--secondary);
      color: white;
      &:focus {
        outline: none;
      }

      option {
        color: black;
      }
    }

    .selectedTab__container {
      padding: 0.5rem;
      margin-top: 0.5rem;
      border-radius: 5px;
      background-color: var(--background);
      
      display: flex;
      flex-direction: column;


      h3 {
        padding: 1rem;
      }
      .card {
        padding: 0.5rem;
        border-bottom: 1px solid var(--secondary);

        display: flex;
        justify-content: space-between;

        .cardPosition {
          display: flex;
          align-items: center;

          button {
            height: 2rem;
            border: none;
            background-color: unset;
            color: var(--soft-color);
            font-size: 1.3rem;
            
            cursor: pointer;
            
            svg {
              vertical-align: middle;
            }
            
            &:hover {
              filter: brightness(0.8);
            }
            
            &:disabled {
              filter: brightness(0.5);
            }
          }
          
          h1 {
            margin-left: 0.5rem;
          }
        }

        .cardActions {
          display: flex;
          align-items: center;
        }



      }

      .submit__button {
        background-color: var(--tertiary);
        color: white;
        border: none;
        border-radius: 5px;
        padding: 0.6rem;
        margin: 1rem;
        align-self: flex-end;
        &:hover {
          opacity: 0.9;
        }
      }

    }
  }


`;
