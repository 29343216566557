import _ from "lodash";
import { toast } from "react-toastify";

import api from "../../../../services/axios";
import { convertContentToHTML } from './convertContentToHTML'

function splitHTMLImages(html) {
  const arrayOfImages = html?.match(/src="images\/(.*?)"/g);

  const aux = arrayOfImages?.map((img) => {
    return img.replace(/src="images\/|"/g, "");
  });

  return aux;
}

function validateImages(contentBefore, contentAfter) {
  let imagesBefore = splitHTMLImages(contentBefore);
  let imagesAfter = splitHTMLImages(contentAfter);
  imagesBefore = imagesBefore?.map((img) => img?.replace('svgxml', 'svg+xml'))
  imagesAfter = imagesAfter?.map((img) => img?.replace('svgxml', 'svg+xml'))

  let imagesAdd = _.difference(imagesAfter, imagesBefore);
  let imagesRemove = _.difference(imagesBefore, imagesAfter);

  return { imagesAdd, imagesRemove };
}

export async function handleSubmitContent(
  content,
  contentTextState,
  arrayOfImagesBeforeEditing = null,
  type,
  textBeforeEditing,
  arrayOfLinksBeforeEditing
) {
  let parseContentToSubmit = null;

  let imagesRemove = [];
  let imagesAdd = [];
  let linksToUpdate = [];
  let linksToDelete = [];

  if (type === "text" || type === "accordion") {
    const response = await convertContentToHTML(contentTextState.getCurrentContent());

    if (arrayOfLinksBeforeEditing.length) {
      const aux = _.differenceWith(
        arrayOfLinksBeforeEditing,
        response.arrayOfLinksAfterEditing,
        _.isEqual
      );

      aux.forEach((link) => {
        const findedLink = response.arrayOfLinksAfterEditing.find(
          (linkObj) => linkObj.link_id === link.link_id
        );

        if (findedLink) {
          linksToUpdate.push(findedLink);
        } else {
          linksToDelete.push(link.link_id);
        }
      });
    }

    if (arrayOfImagesBeforeEditing) {
      imagesRemove = _.difference(
        arrayOfImagesBeforeEditing,
        response.arrayOfImagesAfterEditing
      );
      imagesAdd = _.difference(
        response.arrayOfImagesAfterEditing,
        arrayOfImagesBeforeEditing
      );
    }

    parseContentToSubmit = {
      ...content,
      text: response.parsedHtml,
      imagesAdd: imagesAdd,
      imagesRemove: imagesRemove,
      linksToDelete: linksToDelete,
      linksToUpdate: linksToUpdate,
    };
  } else if (type === "link") {
    const response = await convertContentToHTML(contentTextState.getCurrentContent());

    parseContentToSubmit = {
      ...content,
      text: response.parsedHtml,
      imagesAdd: [],
      imagesRemove: [],
    };
  } else {
    const validateResponse = validateImages(textBeforeEditing, content.text);

    parseContentToSubmit = {
      ...content,
      text: content?.text?.replaceAll('svgxml', 'svg+xml'),
      imagesAdd: validateResponse?.imagesAdd ? validateResponse.imagesAdd : [],
      imagesRemove: validateResponse?.imagesRemove
        ? validateResponse.imagesRemove
        : [],
    };
  }

  try {
    await api.put(`/content/update/${content._id}`, parseContentToSubmit);
    if (type !== "link") {
      toast.success("Conteúdo atualizado com sucesso!", {
        theme: "colored",
        closeOnClick: true,
      });
    }
    return true;
  } catch (error) {
    console.error(error);
    toast.error("Ocorreu um erro!", {
      theme: "colored",
      closeOnClick: true,
    });
    return false;
  }
}
