import { DefaultTabsList } from '../../atoms/DefaultTabsList';
import { TabsForm } from '../TabsForm/Index';
import { RiCloseFill } from 'react-icons/ri';
import { toast } from 'react-toastify';

import { AddTabOnRoutineFormContainer } from './style';
import api from '../../../services/axios';

export default function AddTabOnRoutineForm({ closeModal, routineId, tabs }) {

  async function handlePostTabOnRoutine(tab, isDefault = false) {
    try {
      if (isDefault) {
        const response = await api.post(`/tab/add-default/${routineId}/${tab?._id}`)
      } else {
        const response = await api.post(`/tab/create/${routineId}`, tab)
      }
      closeModal()
      toast.success("Aba criada com sucesso!", {
        theme: "colored",
        closeOnClick: true,
      })
    } catch (error) {
    }
  }

  return (
    <AddTabOnRoutineFormContainer>
      <div className="content">
        <section>
          <div className="header">
            <h3>Adicionar Aba Customizada</h3>
            <button onClick={closeModal}>
              <RiCloseFill />
            </button>
          </div>
          <TabsForm afterSubmitFunction={handlePostTabOnRoutine} />
        </section>

        <DefaultTabsList
          setSelectedTab={handlePostTabOnRoutine}
          existTabs={tabs}
        />
      </div>
    </AddTabOnRoutineFormContainer>
  );
}
