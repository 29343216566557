import { useHistory, useParams } from 'react-router-dom';
import { Table } from './style';
import { MdEdit } from 'react-icons/md';
import { FiTrash } from 'react-icons/fi';
import { FaEye } from 'react-icons/fa';
import { useState, useEffect } from 'react';
import { Pagination } from '../../atoms/Pagination';
import { LoadingComponent } from '../../atoms/loadingCircleComponent';

export function DataTable({
  data,
  route,
  deleteFunction,
  isLoadingData = false,
}) {
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [dataTableArray, setDataTableArray] = useState(null);

  function handleDataTableArray() {
    setDataTableArray(data?.slice((page - 1) * 10, page * 10));
  }

  useEffect(() => {
    if (data) {
      handleDataTableArray();
    }
  }, [data, page]);

  useEffect(() => {
    setPage(1);
  }, [data]);

  return (
    <>
      {!isLoadingData ? (
        <>
          <Table>
            <thead>
              <tr>
                <th>Título</th>
                {route === 'routines' && <th></th>}
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {dataTableArray &&
                dataTableArray.map((row) => (
                  <tr key={row._id}>
                    <td>
                      {row.link_origin_text}
                      {route !== 'pendinglinks' && `${row.title}`}
                      {route === 'routines' && ` - ${row.legend}`}
                    </td>

                    {route === 'routines' && (
                      <td>
                        {row.is_reviewed ? (
                          <span style={{ color: 'var(--green)' }}>
                            Revisado
                          </span>
                        ) : (
                          <span style={{ color: 'var(--red)' }}>
                            Não revisado
                          </span>
                        )}
                      </td>
                    )}
                    <td>
                      {route !== 'pendinglinks' && (
                        <button
                          className="update__button"
                          onClick={() =>
                            history.push(`${route}/update/${row._id}`)
                          }
                        >
                          <MdEdit />
                        </button>
                      )}

                      {route === 'specialties' && (
                        <button
                          className="delete__button"
                          onClick={() => deleteFunction(row._id)}
                        >
                          <FiTrash />
                        </button>
                      )}

                      {route === 'pendinglinks' && (
                        <button
                          title="Ver link"
                          className="showLink__button"
                          onClick={() =>
                            history.push(`${route}/update/${row._id}`)
                          }
                        >
                          <FaEye />
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>

          {data?.length > 10 && (
            <Pagination
              currentPage={page}
              totalCountOfRegisters={data.length}
              onPageChange={(e) => setPage(e)}
            />
          )}
        </>
      ) : (
        <LoadingComponent />
      )}
    </>
  );
}
