import { useLocation } from 'react-router-dom';
import { FirebaseAppProvider } from 'reactfire';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import AplicationProvider from './context';
import { firebaseConfig } from './services/firebase/config';
import { DefaultTemplate } from './components/tempaltes/DefaultTemplate';
import { Routers } from './routes';

import './style/global.css';

function App() {
  const location = useLocation();

  if (location.pathname === '/login') {
    return (
      <>
        <Routers />
        <ToastContainer
          position="top-right"
        />
      </>
    )
  }

  return (
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <AplicationProvider>
        <DefaultTemplate>
          <Routers />
          <ToastContainer
            position="top-right"
          />
        </DefaultTemplate>
      </AplicationProvider>
    </FirebaseAppProvider>
  );
}

export default App;
