export const tabsStyles = {
  black: {
    title: "Resumo (Black)",
    hexdecimal_color: "#BABCC5",
    is_treatment: false,
  },
  blue: {
    title: "O que é (Blue)",
    hexdecimal_color: "#2A7EC6",
    is_treatment: false,
  },
  cyan: {
    title: "Quando suspeitar (Cyan)",
    hexdecimal_color: "#258138",
    is_treatment: false,
  },
  brown: {
    title: "Como confirmar (Brown)",
    hexdecimal_color: "#8C502E",
    is_treatment: false,
  },
  green: {
    title: "Tratamento 1 (Green)",
    hexdecimal_color: "#1D9637",
    is_treatment: true,
  },
  orange: {
    title: "Tratamento 2 (Orange)",
    hexdecimal_color: "#B97B32",
    is_treatment: true,
  },
  red: {
    title: "Tratamento 3 (Red)",
    hexdecimal_color: "#BD4A43",
    is_treatment: true,
  },
  indigo: {
    title: "Mais (Indigo)",
    hexdecimal_color: "#7148C3",
    is_treatment: false,
  }
}