import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  background-color: var(--content);
  height: fit-content;
  margin-top: 4rem;
  min-width: 20rem;

  padding: 1rem;

  .title__type {
    font-weight: bold;
    margin-bottom: 2rem;
  }

  label {
    input {
      background-color: transparent;
      border: none;
      border-bottom: 1px solid var(--secondary);
      width: -webkit-fill-available;
      color: white;
      margin-bottom: 1rem;
      padding: 0.5rem 0;
      &:focus{
        outline: none;
      }
    }

    select {
      background-color: transparent;
      border: none;
      border-bottom: 1px solid var(--secondary);
      color: white;
      margin-bottom: 1rem;
      padding: 0.5rem 0;
      &:focus {
        outline: none;
      }

      option {
        color: black;
      }
    }
    
    textarea {
      background-color: var(--background);
      border: none;
      color: white;
      margin-top: 0.5rem;
      border-radius: 5px;
      width: 99.5%;
      height: 6rem;
    }

    &.openOrClose__label {
      display: flex;
      margin-top: 10px;
      margin-bottom: 10px;
      .openOrClose__checkbox {
        width: unset;
        margin-bottom: 0;
      }
    }
  }

  .content__header {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-bottom: 2rem;
  }

  .image__button {
    border: none;
    padding: 0.5rem;
    border-radius: 5px;
    background-color: var(--tertiary);
    color: white;
    &:hover {
      filter: brightness(0.9);
    }
  }

  .submit__button {
    display: flex;
    justify-content: flex-end;
    button {
      color: white;
      border: none;
      border-radius: 5px;
      background-color: var(--tertiary);
      padding: 0.5rem;
      margin-top: 10px;
      &:hover {
        filter: brightness(0.9);
      }
    }
  }

  .loading__div {
    margin-top: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
      margin-top: 15px;
      font-size: 0.9rem;
    }
  }

  .input__label {
    font-size: 0.9rem;

    select, input {
      font-size: 1rem;
      padding: 0.5rem;
    }
  }
`