import styled from 'styled-components';

export const LoginContainer = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: var(--gray-900);
  display: flex;
  justify-content: center;
  overflow: hidden;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    max-height: calc(100vh - 10rem);
    margin-top: 5rem;

    .logo__box {
      img {
        width: 300px;
      }
    }

    .input__box {
      background-color: var(--content);
      padding: 1.5rem;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      
      label {
        padding: 0;
        color: white;
        display: flex;
        flex-direction: column;
        
        &.showPassword__input {
          margin-top: 0.5rem;
          flex-direction: row !important;
          align-items: center;
          justify-content: flex-end;
          font-size: 0.8rem;
        }

        h3 {
          background: var(--content);
          transform: translateY(0.5rem);
          width: 3rem;
          padding: 0 1rem 0 0.5rem;
          margin-left: 1rem;
        }
        
        input {
          color: white;
          border: 1px solid var(--background);
          border-radius: 5px;
          /* margin: 0.3rem; */
          padding: 1rem;
          background-color: var(--content);
          transition: 0.2s;
          &:hover {
            border: 1px solid var(--secondary);
          }
          &:focus {
            outline: none;
            border: 1px solid var(--secondary);
          }
        }
      }
    }

    button {
      border-radius: 5px;
      border: none;
      background-color: var(--secondary);
      color: white;
      padding: 1rem;
      font-weight: bold;
      margin-top: 0.5rem;
      transition: linear 0.2s;
      &:hover {
        background-color: var(--tertiary);
      }
    }
  }
`