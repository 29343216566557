import styled from "styled-components";

export const LinksDiv = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--content);
  margin-top: 1rem;

  .linksHeader {
    align-items: center;
    display: flex;
    justify-content: space-between;
    background-color: var(--secondary);
    padding: 1rem;
    border-radius: 5px 5px 0 0;
  }

  .linksHeader__title {
    font-size: 1.3rem;
  }

  .linksTables {
    padding: 1rem;
  }

  .linksTables__title {
    padding: 1rem 0;
    font-weight: 600;
    font-size: 1.5rem;
  }

  .linksTables__table {
  width: 100%;
  padding: 1rem;
  table-layout: fixed;

  .table__header {

      .table__headerItem {
        text-align: left;

        &:last-child {
            text-align: right;
        }
      }
    }

    .table__headerRow, .table__bodyRow {
      border-bottom: 1px solid var(--background);
      height: 2rem;
    }
  }

  .table__item {
    vertical-align: middle;
    padding: 0.5rem 0;

    &:last-child {
      text-align: right;
      button {
        background-color: transparent;
        border: none;
        font-size: 1.3rem;
        color: var(--tertiary);
        cursor: pointer;
        
        &:hover {
          filter: brightness(0.9);
        }
      }
    }
  }
`