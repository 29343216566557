import { Route, Redirect } from"react-router-dom";
import { isAuthenticated } from '../services/auth'
import PropTypes from 'prop-types';

export function PrivateRoute({component: Component, ...rest}) {
    return (
       <Route
    {...rest}
    render={(props) =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{ pathname: '/login', state: { from: props.location } }}
        />
      )
    }
  />
);
}

PrivateRoute.propTypes = {
component: PropTypes.func.isRequired,
location: PropTypes.object,
};