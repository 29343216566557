import { useState } from 'react';
import api from '../../../services/axios';
import { updateResumeCardKeywords } from '../../../services/utils/updateResumeCardKeywords';

export function KeywordInputComponent({
  route,
  parentId,
  setKeywordsList,
  resumeTabCardsIds = null,
  callbackFunction = () => {}
}) {
  const [inputValue, setInputValue] = useState('');

  async function handleSubmitKeywords() {
    try {
      const response = await api.put(`${route}/add-keywords/${parentId}`, {
        title: inputValue,
      });

      let oldKeywords = response.data.keywords.filter((keyword) => {
        return (
          response.data.keywordsAdded.findIndex(
            (value) => keyword._id === value._id
          ) === -1
        );
      });
      response.data.keywordsAdded.forEach((keyword) => {
        keyword['new'] = true;
      });
      setKeywordsList([...response.data.keywordsAdded, ...oldKeywords]);

      const resumeTabCards = await resumeTabCardsIds?.();

      resumeTabCards?.cards_ids.map(async (card) => {
        await updateResumeCardKeywords(
          card, 
          [...response.data.keywordsAdded, ...oldKeywords], 
          resumeTabCards.routineTitle
        )
      });

      callbackFunction()

      setInputValue('');
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <div className="input__container">
        <input
          type="text"
          placeholder="Digite a palavra-chave"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
        />
        <button
          className="input__addKeywordButton"
          onClick={() => {
            handleSubmitKeywords();
          }}
        >
          +
        </button>
      </div>
    </>
  );
}
