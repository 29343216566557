import { useLayoutEffect, useEffect, useState, useRef } from 'react';

import { Accordion } from '../../tempaltes/PreviewTemplates/Accordion';
import { ContainerAccordion } from '../../tempaltes/PreviewTemplates/ContainerAccordion';
import { AccordionHTML } from '../../tempaltes/PreviewTemplates/AccordionHTML';
import { AccordionWithTitle } from '../../tempaltes/PreviewTemplates/AccordionWithTitle';
import { Caroseul } from '../../tempaltes/PreviewTemplates/Caroseul';
import { Popup } from '../../tempaltes/PreviewTemplates/Popup';
import api from '../../../services/axios';

import { Container } from './style';

let htmlToReact = require('html-to-react').Parser;

export function RoutineCardPreview({
  card,
  tabColor,
  setContentChange,
  contentChange,
}) {
  const conteudo = new htmlToReact();
  const topRef = useRef();
  const [cards, setCards] = useState(null);

  function arraySorter(array) {
    let sortedArray = [];
    array.map((content) => {
      sortedArray.push(content);
      sortedArray.sort((a, b) => a.position - b.position);
    });
    return sortedArray;
  }
  async function handleGetCards() {
    try {
      setCards(null);
      const response = await api.get(`/card/${card.card_id._id}`);
      setCards(response.data);
      setContentChange(false);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (card) {
      handleGetCards();
    } else {
      setCards(null);
    }
  }, [card, contentChange]);

  useLayoutEffect(() => {
    const topValue = topRef.current?.getBoundingClientRect().height + 45;
    document.documentElement.style.setProperty('--topValue', `${topValue}px`);
  });

  function handleShowContent(content) {
    const temp = content.text?.match(/<img src="(.*?)">/gm);

    temp?.length &&
      temp.map((image) => {
        const aux = image
          .replaceAll(
            '<img src="images/',
            `<img src="https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/o/images%2F`
          )
          .replaceAll('">', '?alt=media">');

        content.text = content.text.replaceAll(image, aux);
      });

    content.text = content?.text?.replaceAll('svg+xml', 'svg%2Bxml');
    const contentType = content?.content_type_id?.code;
    const conteudoAtual = conteudo.parse(content?.text);
    switch (contentType) {
      case 'text':
        return conteudoAtual;

      case 'image':
        return (
          <div className="image-full-width">
            {conteudo.parse(content?.text)}
          </div>
        );

      case 'scrollable_image':
        return <div className="scrollable-image">{conteudoAtual}</div>;

      case 'caroseul':
        return <Caroseul content={content} />;

      case 'accordion_html':
        return (
          <AccordionHTML
            title={content.title}
            content={conteudoAtual}
            type={content.component_type}
            isOpen={content.component_open}
          />
        );

      case 'container_accordion_title':
        return (
          <AccordionWithTitle
            title={content.title}
            children={arraySorter(content.children_id)}
            type={content.component_type}
            isOpen={content.component_open}
          />
        );

      case 'container_accordion':
        return (
          <ContainerAccordion content={arraySorter(content.children_id)} />
        );

      case 'accordion':
        return (
          <Accordion
            content={content}
            isOpen={content.component_open}
            type={content.component_type}
          />
        );

      case 'popup_html':
        return (
          <Popup
            title={content.title}
            content={content.text}
            type={content.component_type}
          />
        );

      default:
        return conteudoAtual;
    }
  }
  return (
    <Container tabColor={tabColor}>
      {cards && card && (
        <div className="content">
          <div className="headerContainer">
            <div className="cardMenu">
              <div className="cardMenu__like"></div>
              <div className="cardMenu__bookmark"></div>
              <div className="cardMenu__options"></div>
            </div>
            <div className="routineCard__header">
              <h1 ref={topRef} className="routineTitle">
                {cards?.title}
              </h1>
              <hr className="routineTitle__separator" />
            </div>
          </div>
          <div className="content__card">
            <div className="routineCard">
              {arraySorter(cards?.contents_id)?.map((content) => (
                <div className="routineCard__content">
                  {handleShowContent(content)}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </Container>
  );
}
