import { useState, useEffect, useLayoutEffect } from 'react';
import { useParams } from 'react-router';
import { ContentTabCard } from '../../components/molecules/ContentTabCard';
import { RoutineCardPreview } from '../../components/organisms/RoutineCardPreview';
import { TextEditor } from '../../components/organisms/TextEditor';

import { Container } from './style';

export function Content() {
  const [currentContent, setCurrentContent] = useState(null);
  const [currentCard, setCurrentCard] = useState({
    card: null,
    tabColor: null,
  });
  const [contentChange, setContentChange] = useState(false);

  const { id } = useParams();

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  });

  useEffect(() => {
    if (currentContent) {
      setCurrentContent(null);
    }
  }, [currentCard]);


  return (
    <Container>
      <ContentTabCard
        setCurrentCard={setCurrentCard}
        setCurrentContent={setCurrentContent}
        setContentChange={setContentChange}
      />
      <TextEditor routineId={id} contentId={currentContent?._id} setContentChange={setContentChange} />
      <RoutineCardPreview
        card={currentCard.card}
        tabColor={currentCard.tabColor}
        setContentChange={setContentChange}
        contentChange={contentChange}
      />
    </Container>
  );
}
