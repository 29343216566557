import styled from "styled-components";

export const HeaderContainer = styled.div`
    background-color: #141414;
    position: fixed;
    z-index: 20;
    height: 50px;
    width: 100%;
    box-shadow: 5px 0px 10px 0px black;

    .header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1px 10px;
        font-size: 1.5em;
    }

    .header__logo {
        margin-top: 5px;
        height: 35px;
        &:hover{
            cursor: pointer;
        }
    }

    .header__nav {
        display: flex;
        justify-content: space-between;
        margin-right: 1rem;

    }

    .nav__item {
        margin-top: 10px;
        margin-left: 1rem;

    }

    .nav__link {
        text-decoration: none;
        color: white;
        transition: linear .2s;
        &:hover{
            color: white;
        }
    }
`;