import styled from 'styled-components';

export const BackButtonComponent = styled.button`
  align-self: flex-start;
  border: none;
  border-radius: 5px;
  background-color: var(--content);
  color: white;
  padding: 0.6rem;
  margin-bottom: 15px;
  &:hover {
    background-color: var(--secondary);
  }
`;
