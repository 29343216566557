import { Container } from "./style";
// import { Link } from 'react-router-dom';

export function RoutineNavigation() {

  function handleNavigate(e) {
    document.getElementById(e)?.scrollIntoView({ block: "center", behavior: "smooth" });
  }

  return (
    <Container>
      <div className="routineNav">
        <ul className="routineNav__list">
          <li className="routineNav__item">
            <p
              className="routineNav__link"
              onClick={() => handleNavigate("basicInfo")}
            >
              Informações básicas
            </p>
          </li>
          <li className="routineNav__item">
            <p
              className="routineNav__link"
              onClick={() => handleNavigate("specialties")}
            >
              Especialidades
            </p>
          </li>
          <li className="routineNav__item">
            <p
              className="routineNav__link"
              onClick={() => handleNavigate("keywords")}
            >
              Keywords
            </p>
          </li>
          <li className="routineNav__item">
            <p
              className="routineNav__link"
              onClick={() => handleNavigate("tabs")}
            >
              Abas
            </p>
          </li>
          <li className="routineNav__item">
            <p
              className="routineNav__link"
              onClick={() => handleNavigate("cards")}
            >
              Cards
            </p>
          </li>
          <li className="routineNav__item">
            <p
              className="routineNav__link"
              onClick={() => handleNavigate("content_navigation")}
            >
              Conteúdo
            </p>
          </li>
          <li className="routineNav__item">
            <p
              className="routineNav__link"
              onClick={() => handleNavigate("doneLinks")}
            >
              Links feitos
            </p>
          </li>
          <li className="routineNav__item">
            <p
              className="routineNav__link"
              onClick={() => handleNavigate("pendingLinks")}
            >
              Links pendentes
            </p>
          </li>
          <li className="routineNav__item">
            <p
              className="routineNav__link"
              onClick={() => handleNavigate("pointingLinks")}
            >
              Links que apontam
            </p>
          </li>
        </ul>
      </div>
    </Container>
  );
}
