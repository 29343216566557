import styled from "styled-components";

export const AccordionWithTitleContainer = styled.div`
    background-color: var(--dark-color);
    padding: 5px;
    border-radius: 5px;

  section.accordion-header {
    display: flex;
    align-items: center;
    min-height: 48px;
    background-color: var(--dark-color);
    z-index: 2;
    margin-bottom: 5px;
    color: white;
    justify-content: space-between;
  }
  
  .accordion-type-icon {
    width: 48px;
    height: 48px;
  }
  
  .accordion-title {
    width: 100%;
    padding-left: 10px;
    margin: 5px 0;
  }
  
  .accordion-open-close-indicator {
    font-size: 25px;
    margin-right: 10px;
    &:hover {
      cursor: pointer;
    }
  }
  
  
  .accordion{
    background-color: var(--hard-color) !important;
    border-radius: 0 !important;
    margin-bottom: 3px !important;
    div.accordion-header{
      background-color: var(--hard-color) !important;
      .accordion-title{
        color: black;
      }

      .accordion-type-icon{
        display: none !important;
      }
  
      .accordion-open-close-indicator {
        color: black;
      }
    }
  }
`;
