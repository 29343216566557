import {FaItalic, FaBold, FaSubscript, FaSuperscript, FaFont, FaListOl, FaListUl} from 'react-icons/fa'

export const blockStylesTypes = [
  { label: <FaListUl />, style: 'unordered-list-item' },
  { label: <FaListOl />, style: 'ordered-list-item' },
];

export const inlineStylesTypes = [
  { label: <FaItalic />, style: 'ITALIC' },
  { label: <FaBold />, style: 'BOLD' },
  { label: <FaSubscript />, style: 'SUBSCRIPT' },
  { label: <FaSuperscript />, style: 'SUPERSCRIPT' },
];