import styled from 'styled-components';

export const ListLinkContainer = styled.div`

.linksTables__table {
    table-layout: fixed;
    width: 100%;
  }

  .input__container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .search__input {
    color: white;
    background-color: unset;
    border: 1px solid var(--secondary);
    border-radius: 5px;
    padding: 0.5rem;
    margin-top: 10px;
    margin-bottom: 10px;
    &:focus {
      outline: none;
    }
  }
  
  .showLink__button {
    border: none;
    background-color: unset;
    font-size: 1.2rem;
    color: var(--soft-color);
    &:hover {
      filter: brightness(0.8);
    }
  }

  tr {
    text-align: left;
  }

  th, td {
    padding: 0.5rem 0;
    vertical-align: middle;
    border-bottom: 1px solid var(--background);
    &:last-child {
      text-align: right;
    }
  }
`