import styled from 'styled-components';

export const DefaultTabsListContainer = styled.ul`
  background-color: var(--content);
  padding: 1rem;
  margin-top: 0.5rem;

  .tab__container {
    display: flex;
    justify-content: space-between;

    padding: 0.5rem 0;

    border-bottom: 1px solid var(--secondary);

    button {
      border: none;
      background-color: unset;
      font-size: 20px;
      color: var(--green);
      &:hover {
        filter: brightness(0.8);
      }
    }
  }
`;
