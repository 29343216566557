import styled from 'styled-components';

export const Container = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  height: 100vh;
  width: 100%;
  inset: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    background-color: var(--background);
    padding: 1rem;

    .addContent__header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h3 {
        span {
          font-size: 1.1rem;
          font-weight: bold;
        }
      }

      .close__button {
        margin-left: 1rem;
        border: none;
        background-color: transparent;
        font-size: 1.3rem;
        color: white;
      }
    }

    .addContent__body {
      display: flex;
      flex-direction: column;

      input {
        background-color: unset;
        border-bottom: 1px solid var(--secondary);
        color: white;
        padding: 0.5rem 0;

        border: none;
        border-bottom: 1px solid var(--secondary);

        background-color: unset;

        width: 100%;
        margin-bottom: 1rem;
        &:focus {
          outline: none;
        }
      }

      h3 {
        display: flex;
      }

      label {
        font-size: 1rem;

        .title__input {
          color: white;
          padding: 0.5rem 0;

          border: none;
          border-bottom: 1px solid var(--secondary);

          background-color: unset;

          width: 100%;
          margin-bottom: 1rem;
          &:focus {
            outline: none;
          }
        }

        select {
          padding: 0.5rem 0;

          border: none;
          border-bottom: 1px solid var(--secondary);

          background-color: transparent;
          color: white;

          width: 100%;
          margin-bottom: 1rem;
          &:focus {
            outline: none;
          }

          option {
            color: black;
          }
        }
      }

      button {
        color: white;
        background-color: var(--tertiary);
        padding: 0.5rem;
        align-self: flex-end;
        border: none;
        border-radius: 5px;
        transition: filter 0.2s;

        &:hover {
          filter: brightness(0.9);
        }
      }
    }
  }
`;
