import axios from 'axios';
import { getToken } from './auth';

const drugApi = axios.create({
  baseURL: process.env.REACT_APP_DRUG_API || "https://drugs-api-dev.blackbook.com.br"
});

drugApi.interceptors.request.use(async (config) => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default drugApi;
