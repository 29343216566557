import styled from 'styled-components';

export const PaginationComponent = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  font-size: 0.7rem;
  align-items: flex-end;

  strong {
    padding: 0 3px;
    margin: 3px 0 3px 0;
  }
`