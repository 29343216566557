import { useHistory } from "react-router-dom";
import { RoutineNavigation } from "../../molecules/RoutineNavigation";
import { SidebarContainer } from "./style";

const sidebarOptions = [
  {
    title: "Rotinas",
    path: "/routines",
  },
  {
    title: "Especialidades",
    path: "/specialties",
  },
  {
    title: "Links pendentes",
    path: "/pendinglinks",
  },
  {
    title: "Gerenciador de Imagens",
    path: "/imagesdashboard",
  },
];

export function Sidebar() {
  const history = useHistory();
  let isUpdate = false;
  let isContent = true;
  if (window.location.href.includes("/routines/update")) {
    isUpdate = true;
  }
  if (window.location.href.includes("/routines/updateContent")) {
    isContent = false;
  }

  return (
    <>
      {isContent && (
        <SidebarContainer>
          <ul className="sidebarNav">
            {sidebarOptions &&
              sidebarOptions.map((option) => (
                <li
                  onClick={() => history.push(option.path)}
                  className="sidebarNav__option"
                >
                  <h2 className="option__content">{option.title}</h2>
                </li>
              ))}
          </ul>
          {isUpdate && <RoutineNavigation />}
        </SidebarContainer>
      )}
    </>
  );
}
