import { BackButton } from '../../../components/atoms/BackButton';
import { RoutineBasicInformationForm } from '../../../components/molecules/RoutineBasicInformationForm';
import { RoutinesCreateContainer } from './style';

export function RoutinesCreate() {
  return (
    <RoutinesCreateContainer>
      <BackButton route="routines" />
      <RoutineBasicInformationForm/>
    </RoutinesCreateContainer>
  );
}
