import { Container } from './style';

export function SwitchButton({ checked, onChange, leftText = 'Fechado', rightText = 'Aberto'}) {
  return (
    <Container>
      <h3 className="label">{leftText}</h3>
      <label className="switch">
        <input type="checkbox" checked={checked} onChange={onChange} />
        <span class="slider round"></span>
      </label>
      <h3 className="label">{rightText}</h3>
    </Container>
  );
}
