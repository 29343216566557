import { useState, useEffect } from 'react';
import _ from 'lodash';
import {
  useFirebaseApp,
  StorageProvider,
  useStorage,
  useStorageDownloadURL,
} from 'reactfire';
import { getStorage, ref } from 'firebase/storage';
import { RiCloseFill } from 'react-icons/ri';

import api from '../../../services/axios';
import UploadImage from '../../atoms/UploadImage';
import { Pagination } from '../../atoms/Pagination';
import { ModalImageContentContainer } from './style';


export default function ModalImageComponent({
  closeModal,
  onAddImage,
  isCarousel = false,
  initialImages = null,
  imageModalInfos,
}) {
  const appFirebase = useFirebaseApp();

  const [images, setImages] = useState(null);
  const [uploadImageIsOpen, setUploadImageIsOpen] = useState(false);
  const [dataImageArray, setDataImageArray] = useState(null);
  const [filteredImages, setFilteredImages] = useState(null);
  const [selectedImages, setSelectedImages] = useState(
    isCarousel && initialImages !== ''
      ? initialImages.split(',')
      : initialImages
      ? initialImages
      : []
  );

  const [page, setPage] = useState(1);
  const [imagesHasUpdate, setImagesHasUpdate] = useState(true);

  async function handleGetImages() {
    try {
      setImages(null);
      const response = await api.get(`/image/all/`);
      let aux = [];
      response.data.forEach((image) => {
        aux.push(image);
      });
      setImages(aux);
      setFilteredImages(aux);
      setPage(1);
      setImagesHasUpdate(false);
    } catch (error) {
      console.log(error);
    }
  }

  async function handleFilterImages(input) {
    if (input !== '') {
      const result = await images?.filter((image) =>
        image.toLowerCase().includes(input.toLowerCase())
      );
      setFilteredImages(result);
      return;
    }
    setFilteredImages(images);
  }

  function handleSelectedImages(key) {
    let aux = [...selectedImages];

    if (aux.find((selectedImage) => selectedImage === key)) {
      aux = _.remove(aux, (img) => img !== key);
    } else {
      aux.push(key);
    }
    setSelectedImages([...aux]);
  }

  function handleDataImageArray() {
    setDataImageArray(filteredImages?.slice((page - 1) * 10, page * 10));
  }

  function handleAddSelectedImages() {
    closeModal();
    if (isCarousel) {
      const aux = selectedImages.toString();

      onAddImage(aux);
    } else {
      onAddImage(selectedImages, imageModalInfos?.type);
    }
  }

  function HandleShowImage({ image, className }) {
    const storage = useStorage();

    const imageRef = ref(storage, image);
    
    const { status, data: imageURL } = useStorageDownloadURL(imageRef);

    return (
      <img
        loading="lazy"
        onClick={() => handleSelectedImages(image)}
        className={`${className} ${
          selectedImages?.find((img) => img === image) ? 'selected' : ''
        }`}
        src={imageURL?.replaceAll("svg+xml", "svg%2Bxml")}
        alt={image}
      />
    );
  }


  useEffect(() => {
    if (imagesHasUpdate) {
      handleGetImages();
    }
  }, [, imagesHasUpdate]);

  useEffect(() => {
    handleDataImageArray();
  }, [filteredImages, page]);

  return (
    <StorageProvider sdk={getStorage(appFirebase)}>
      <ModalImageContentContainer>
        <div className="content">
          <div className="header">
            <h3>Selecione uma imagem</h3>
            <button className="close__button" onClick={closeModal}>
              <RiCloseFill />
            </button>
          </div>

          <div className="search__box">
            <label>
              Digite sua busca:
              <input
                type="text"
                className="input__search"
                onChange={(e) => handleFilterImages(e.target.value)}
              />
            </label>
          </div>

          {images ? (
            <>
              <div className="image__box">
                {dataImageArray &&
                  dataImageArray.map((image) => (
                    <>
                      {image !== 'images/' && (
                        <HandleShowImage image={image} className='image' />
                      )}
                    </>
                  ))}
              </div>
            </>
          ) : (
            <div className="loading__div">
              <lottie-player
                src="https://assets3.lottiefiles.com/packages/lf20_ocoxrlkm.json"
                background="transparent"
                speed="1"
                style={{ width: '200px', height: '200px' }}
                loop
                autoplay
              />
              <h3>Carregando...</h3>
            </div>
          )}

          <div className="buttonUpload__box">
            <button
              className="upload__button"
              onClick={() => setUploadImageIsOpen(true)}
            >
              Upload
            </button>

            {uploadImageIsOpen && (
              <UploadImage
                closeModal={() => {
                  setUploadImageIsOpen(false);
                  setImagesHasUpdate(true);
                }}
              />
            )}
            {filteredImages?.length > 10 && (
              <Pagination
                currentPage={page}
                totalCountOfRegisters={filteredImages.length}
                onPageChange={(e) => setPage(e)}
              />
            )}
          </div>

          <h3>Imagem(s) selecionada(s)</h3>
          <div className="imageSelected__box">
            {/* MAP RESPOSÁVEL POR EXIBIR IMAGENS SELECIONADAS */}
            {selectedImages &&
              selectedImages.map((image) => (
                <HandleShowImage image={image} className="selected__image" />
              ))}
          </div>

          <div className="buttonSave__box">
            <button
              onClick={() => handleAddSelectedImages()}
              className="save__button"
            >
              Adicionar
            </button>
          </div>
        </div>
      </ModalImageContentContainer>
    </StorageProvider>
  );
}
