import styled from 'styled-components';

export const Container = styled.div`
  
  position: absolute;
    display: block;
    z-index: 95;
    padding: 2px;
    border-radius: 5px;
    background-color: white;
    box-shadow: 0px 4px 15px 10px rgba(0,0,0,0.4);

  .defaultColors__container {
    height: 108px;
    width: 108px;

    button {
      height: 25px;
      width: 25px;
      margin: 1px;
      border: 1px solid black;
      border-radius: 5px;
    }
  }

  .customColor__container {
    hr {
      border: none;
      height: 1px;
      background-color: var(--primary);
      margin-bottom: 2px;
    }

    div {
      display: flex;
      justify-content: space-around;
      align-items: center;

      h3 {
        color: #000;
      }

      input {
        -webkit-appearance: none;
        border: none;
        width: auto;
        height: auto;
        cursor: pointer;
        background: none;
        &::-webkit-color-swatch-wrapper {
          padding: 0;
          width: 25px;
          height: 25px;
        }
        &::-webkit-color-swatch {
          border: 1px solid #bfc9d9;
          border-radius: 4px;
          padding: 0;
        }
      }
    }
}

`;
