import styled from "styled-components";

export const CaroseulContainer = styled.div`
    .caroseul{
        overflow-x: hidden;
        position: relative;
    }
    
    .caroseul-items{
        overflow-x: scroll;
        scroll-snap-type: x mandatory;
        touch-action: pan-y;
    }
    
    .caroseul-items p{
        display: flex;
    }

    .caroseul-items p > * {
        scroll-snap-align: center;
    }
`