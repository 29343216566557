import { AccordionContainer } from "./style";
import { FiChevronDown } from "react-icons/fi";
import { useState } from "react";

export function AccordionHTML({ title, content, type, isOpen }) {
  const [isAccordionOpen, setIsAccordionOpen] = useState(isOpen);
  function toggleAccordion() {
    setIsAccordionOpen(!isAccordionOpen);
  }
  return (
    <AccordionContainer>
      <div className="accordion">
        <div className="accordion-header">
          <div
            className={`accordion-type-icon ${type}`}
          />
          <div className="accordion-title">{title}</div>
          <div className="accordion-open-close-indicator" onClick={()=> toggleAccordion()}>
            <FiChevronDown />
          </div>
        </div>
        <div className={`accordion-body ${isAccordionOpen ? "" : "closed"}`}>{content}</div>
      </div>
    </AccordionContainer>
  );
}
