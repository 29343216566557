import styled from "styled-components";

export const KeywordsContainer = styled.div`
  .accordion__header {
    margin-top: 1rem;
    border-radius: 5px 5px 0 0;
    background-color: var(--primary);
    padding: 1rem;

    h2 {
      font-size: 1.3rem;
      margin-bottom: 10px;
      font-weight: 500;
    }

    h3 {
      font-weight: 200;
    }
  }

  .accordion__body {
    background-color: var(--content);
    margin-bottom: 1rem;
    border-radius: 0 0 5px 5px;

    .input__container {
      display: flex;
      flex-wrap: nowrap;

      input {
        width: 100%;
        padding: 0.5rem;
        border: none;
        &:focus {
          outline: none;
        }
      }

      .input__addKeywordButton {
        background-color: var(--green);
        border: none;
        color: black;
        font-size: 1.5rem;
        padding: 0 1rem;
        cursor: pointer;
        &:hover {
          filter: brightness(0.8);
        }
      }
    }
  }

  .optionList__container {
    width: 100%;
    max-height: 10rem;
    max-width: 100%;
    border-top: 1px solid var(--secondary);
    background-color: white;
    color: black;
    list-style: none;
    cursor: pointer;
    overflow-y: auto;
    z-index: 200;
    font-size: 1rem;
    font-weight: 500;

    .optionList__item {
      padding: 0.5rem;
      background-color: white;
      border-bottom: 1px solid var(--secondary);
      transition: all 0.2s;
      &:hover {
        filter: brightness(0.8);
      }
    }
  }

  .selectorButtons__container {
    display: flex;
    justify-content: space-around;
    align-items: center;

    button {
      color: white;
      width: 100%;
      padding: 1rem;
      border: none;
      background-color: var(--secondary);
      cursor: pointer;
      transition: all 0.2s;
      &.active {
        background-color: var(--primary);
      }
      &:hover {
        filter: brightness(0.8);
      }
    }
  }

  .emptyKeyword__list {
    display: flex;
    justify-content: center;
    padding: 2rem;
    color: white;
    font-weight: 300;
  }

  .keywordsList {
    max-height: 20rem;
    padding: 1.5rem;
    color: white;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 8px;
    };
    &::-webkit-scrollbar-thumb {
      background-color: var(--primary);
      border-radius: 4px;
    };
    &::-webkit-scrollbar-track {
      background-color: var(--content);
    };

    ul {
      list-style: none;

      li {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0.5rem;
        border-bottom: 1px solid var(--secondary);
        &.newKeyword {
          color: var(--green);
        }
      }
    }

    .removeKeyword__button {
      background-color: unset;
      border: none;
      color: var(--red);
      cursor: pointer;
      font-size: 1.2rem;
      &:hover {
        filter: brightness(0.4);
      }
    }
  }
`;