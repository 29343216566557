import { useEffect, useState } from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';

import api from '../../../services/axios';

import { Container, SelectComponentStyles } from './style';

export function RoutineFilter({ setFilter, setIsLoadingRoutines }) {
  const [specialties, setSpecialties] = useState(null);
  const [selectedSpecialty, setSelectedSpecialty] = useState(null)
  const [isCheckedRoutineWithoutSpecialty, setIsCheckedRoutineWithoutSpecialty] = useState(false)

  async function handleGetSpecialties() {
    try {
      const response = await api.get('/specialty');

      const parse = response.data.map((specialty) => {
        return { value: specialty._id, label: specialty.title };
      });
      setSpecialties(parse);
    } catch (error) {
      toast.error('Ocorreu um erro ao buscar as especialidades!');
    }
  }

  async function handleGetRoutinesWithoutSpecial(isChecked) {
    setIsLoadingRoutines(true)
    setSelectedSpecialty(null);
    setIsCheckedRoutineWithoutSpecialty(isChecked);
    try {
      if(isChecked) {
        const response = await api.get('routines/without-specialty');
        setFilter(response.data);
      } else {
        const response = await api.get('routines');
        setFilter(response.data);
      }
    } catch (error) {
      
    }
    setIsLoadingRoutines(false)
  }

  async function handleGetRoutinesBySpecialty(e) {
    setIsLoadingRoutines(true)
    setSelectedSpecialty(e)
    setIsCheckedRoutineWithoutSpecialty(false)
    try {
      if (!e) {
        const response = await api.get('routines');
        setFilter(response.data);
      } else {
        const response = await api.get(`/specialty/${e.value}`);

        setFilter(response.data?.routines_id);
      }
    } catch (error) {}
    setIsLoadingRoutines(false)
  }

  useEffect(() => {
    handleGetSpecialties();
  }, []);

  return (
    <Container>
      <Select
        placeholder="Filtre por especialidades"
        name="routine"
        value={selectedSpecialty}
        styles={SelectComponentStyles}
        options={specialties}
        onChange={(e) => handleGetRoutinesBySpecialty(e)}
        isClearable={true}
      />
      <label>
        <input type="checkbox" checked={isCheckedRoutineWithoutSpecialty} onChange={(e) => handleGetRoutinesWithoutSpecial(e.target.checked)} />
        <h3>Buscar rotinas sem especialidades</h3>
      </label>
    </Container>
  );
}
