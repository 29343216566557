import styled from "styled-components";

export const LinksTableContainer = styled.div`
  .input__container {
    display: flex;
    flex-direction: column;
    width: 100%;

    .search__input {
      color: white;
      background-color: unset;
      border: 1px solid var(--secondary);
      border-radius: 5px;
      padding: 0.5rem;
      margin-top: 10px;
      margin-bottom: 10px;
      &:focus {
        outline: none;
      }
    }
  }
  
  .editButton__link {
    border: none;
    border-radius: 5px;
    padding: 0.4rem;
    background-color: unset;
    color: var(--tertiary);
    font-size: 20px;
  }
`