import styled from 'styled-components';

export const ModalImageContentContainer = styled.div`
  height: 100vh;
  width: 100%;
  position: fixed;
  inset: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);

  .content {
    width: calc(600px + 2rem);
    height: fit-content;
    max-height: calc(100% - 2rem);
    overflow-y: scroll;
    
    padding: 1rem;
    white-space: normal;
    
    background-color: var(--background);
    color: white;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .image__box {
    height: calc(200px + 2rem);
    padding: 1rem;
    background-color: var(--content);
    margin-bottom: 15px;
    border-radius: 5px;

    .image {
      width: 100px;
      height: 100px;
      padding: 0.5rem;
      max-width: 100px;
      max-height: 100px;
      overflow: hidden;

      &.selected {
        opacity: 0.3;
      }
    }
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .close__button {
      background-color: unset;
      border: none;
      color: white;
      font-size: 1.5rem;
      &:hover {
        filter: brightness(0.8);
      }
    }
  }

  .buttonUpload__box {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;

    margin-bottom: 1.5rem;

      .upload__button {
      color: white;
      background-color: var(--tertiary);
      border: none;
      padding: 0.5rem;
      border-radius: 5px;
      &:hover {
        filter: brightness(0.9);
      }
    }
  }

  .buttonSave__box {
    display: flex;
    justify-content: flex-end;

    .save__button {
      color: white;
      align-self: flex-end;
      background-color: var(--tertiary);
      border: none;
      padding: 0.5rem;
      border-radius: 5px;
      &:hover {
        filter: brightness(0.9);
      }
    }
  }

  .search__box {
    margin-top: 5px;
    margin-bottom: 10px;

    label {
      display: flex;
      flex-direction: column;
      font-size: 14px;
    }

    .input__search {
      margin-top: 5px;
      color: white;
      border: 1px solid var(--content);
      background-color: unset;
      border-radius: 5px;
      padding: 0.5rem 0;
      &:hover {
        border: 1px solid var(--content);
      }
      &:focus {
        outline: none;
        border: 1px solid var(--content);
      }
    }
  }

  .imageSelected__box {
    min-height: calc(100px + 1rem);
    margin-top: 10px;
    margin-bottom: 10px;

    .selected__image {
      width: 100px;
      height: 100px;
      padding: 0.5rem;
      max-width: 100px;
      max-height: 100px;
      overflow: hidden;
    }
  }

  .loading__div {
    margin-top: 0;
  }
`