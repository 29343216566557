import styled from 'styled-components';

export const PaginationItemComponent = styled.button`
  height: 20px;
  background-color: ${({isCurrent}) => isCurrent ? 'var(--background)' : 'var(--content)'};
  border-radius: 5px;
  border: none;
  font-size: 0.8rem;
  color: white;
  margin: 0 2px;
  &:hover {
    background-color: var(--secondary);
  }
`