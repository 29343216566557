import styled from 'styled-components';

export const RoutinesUpdateContainer = styled.div`
  padding: 2rem;

  .routine__header {
    display: flex;
    gap: 2rem;

    .review__section {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }
  }

  .title {
    font-size: 1.5rem;
  }

  .content__button {
    border: none;
    background-color: var(--soft-color);
    padding: 0.5rem;
    border-radius: 5px;
    margin-top: 1rem;
    font-weight: bold;
    &:hover {
      filter: brightness(0.9);
    }
  }
`;
