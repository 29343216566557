import styled from "styled-components";

const softColorSelector = {
  black: "var(--resumo-soft)",
  blue: "var(--oquee-soft)",
  cyan: "var(--quandosuspeitar-soft)",
  brown: "var(--comoconfirmar-soft)",
  green: "var(--tratamento1-soft)",
  orange: "var(--tratamento2-soft)",
  red: "var(--tratamento3-soft)",
  indigo: "var(--mais-soft)"
};

const hardColorSelector = {
  black: "var(--resumo-hard)",
  blue: "var(--oquee-hard)",
  cyan: "var(--quandosuspeitar-hard)",
  brown: "var(--comoconfirmar-hard)",
  green: "var(--tratamento1-hard)",
  orange: "var(--tratamento2-hard)",
  red: "var(--tratamento3-hard)",
  indigo: "var(--mais-hard)",
};

const darkColorSelector = {
  black: "var(--resumo-dark)",
  blue: "var(--oquee-dark)",
  cyan: "var(--quandosuspeitar-dark)",
  brown: "var(--comoconfirmar-dark)",
  green: "var(--tratamento1-dark)",
  orange: "var(--tratamento2-dark)",
  red: "var(--tratamento3-dark)",
  indigo: "var(--mais-dark)",
};

export const Container = styled.div`
  --soft-color: ${({ tabColor }) => softColorSelector[tabColor?.class_css]};
  --hard-color: ${({ tabColor }) => hardColorSelector[tabColor?.class_css]};
  --dark-color: ${({ tabColor }) => darkColorSelector[tabColor?.class_css]};

  /* Classes default */
  a {
    pointer-events: none;
  }

  strong {
    font-weight: bold;
  }

  ul {
    list-style-type: disc;
    padding-left: 15px;
  }

  ul>ul {
    list-style-type: circle;
  }

  ul>ul>ul {
    list-style-type: square;
  }
  
  ol {
    list-style: auto;
    padding-left: 15px;
  }

  ol>ul{
    list-style-type: circle;
  }

  li {
    margin-bottom: 5px;
  }

  em {
    font-style: italic;
  }

  sub {
    vertical-align: sub;
    font-size: smaller;
  }

  sup{
    vertical-align: super;
    font-size: smaller;
  }

  .image-full-width > img {
    width: 100%;
    border-radius: 5px;
  }

  .image-full-width {
    width: 100%;
  }

  .scrollable-image {
    overflow-x: scroll;
    width: auto;
    position: relative;
    z-index: 0;
  }

  div.scrollable-image > img,
  div.scrollable-image > p > img {
    width: auto;
    height: 239px;
    background-size: cover;
  }

  .carousel {
    overflow-x: hidden;
    position: relative;
  }

  .carousel-items {
    touch-action: pan-y;
    display: flex;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
  }

  .carousel-items > img {
    scroll-snap-align: center;
  }

  /* The device with borders */

  position: relative;
  min-width: 360px;
  max-width: 360px;
  height: 640px;
  margin: 4rem 2rem 2rem;
  border: 16px black solid;
  border-top-width: 60px;
  border-bottom-width: 60px;
  border-radius: 36px;

  /* The horizontal line on the top of the device */
  &:before {
    content: "";
    display: block;
    width: 60px;
    height: 5px;
    position: absolute;
    top: -30px;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #333;
    border-radius: 10px;
  }

  /* The circle on the bottom of the device */
  &:after {
    content: "";
    display: block;
    width: 35px;
    height: 35px;
    position: absolute;
    left: 50%;
    bottom: -65px;
    transform: translate(-50%, -50%);
    background: #333;
    border-radius: 50%;
  }

  /* The screen (or content) of the device */
  .content {
    width: 360px;
    height: 640px;
    overflow-y: scroll;
    color: black;
    background-color: white;
  }
  .content__card {
    padding-bottom: 10px;
  }

  .headerContainer {
    position: sticky;
    top: 0;
    padding: 0 20px 0 20px;
    border-top: 10px solid white;
    background-color: var(--soft-color);
    z-index: 5;
  }

  .cardMenu {
    display: flex;
    justify-content: flex-end;
    margin-right: -15px;
    margin-top: -10px;

    .cardMenu__like {
      background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDgiIGhlaWdodD0iNDgiIHZpZXdCb3g9IjAgMCA0OCA0OCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgZmlsdGVyPSJ1cmwoI2ZpbHRlcjBfZCkiPgo8cGF0aCBkPSJNMTUgOUMxNSA3Ljg5NTQzIDE1Ljg5NTQgNyAxNyA3SDMxVjI1QzMxIDI2LjEwNDYgMzAuMTA0NiAyNyAyOSAyN0gxN0MxNS44OTU0IDI3IDE1IDI2LjEwNDYgMTUgMjVWOVoiIGZpbGw9InVybCgjcGFpbnQwX2xpbmVhcikiLz4KPC9nPgo8cGF0aCBkPSJNMzEgN0MzMS4wMDkgOCAzMSA4LjM1IDMxIDEwSDMzQzMzIDguMzUgMzIuMSA3IDMxIDdaIiBmaWxsPSIjNUY1MUIzIi8+CjxjaXJjbGUgY3g9IjE5LjUiIGN5PSIxNC41IiByPSIxLjUiIGZpbGw9IndoaXRlIi8+CjxjaXJjbGUgY3g9IjI2LjUiIGN5PSIxNC41IiByPSIxLjUiIGZpbGw9IndoaXRlIi8+CjxyZWN0IHg9IjIxIiB5PSIxOSIgd2lkdGg9IjQiIGhlaWdodD0iMiIgZmlsbD0id2hpdGUiLz4KPGRlZnM+CjxmaWx0ZXIgaWQ9ImZpbHRlcjBfZCIgeD0iMTIiIHk9IjQiIHdpZHRoPSIyMiIgaGVpZ2h0PSIyNiIgZmlsdGVyVW5pdHM9InVzZXJTcGFjZU9uVXNlIiBjb2xvci1pbnRlcnBvbGF0aW9uLWZpbHRlcnM9InNSR0IiPgo8ZmVGbG9vZCBmbG9vZC1vcGFjaXR5PSIwIiByZXN1bHQ9IkJhY2tncm91bmRJbWFnZUZpeCIvPgo8ZmVDb2xvck1hdHJpeCBpbj0iU291cmNlQWxwaGEiIHR5cGU9Im1hdHJpeCIgdmFsdWVzPSIwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAxMjcgMCIvPgo8ZmVPZmZzZXQvPgo8ZmVHYXVzc2lhbkJsdXIgc3RkRGV2aWF0aW9uPSIxLjUiLz4KPGZlQ29sb3JNYXRyaXggdHlwZT0ibWF0cml4IiB2YWx1ZXM9IjAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAuMiAwIi8+CjxmZUJsZW5kIG1vZGU9Im5vcm1hbCIgaW4yPSJCYWNrZ3JvdW5kSW1hZ2VGaXgiIHJlc3VsdD0iZWZmZWN0MV9kcm9wU2hhZG93Ii8+CjxmZUJsZW5kIG1vZGU9Im5vcm1hbCIgaW49IlNvdXJjZUdyYXBoaWMiIGluMj0iZWZmZWN0MV9kcm9wU2hhZG93IiByZXN1bHQ9InNoYXBlIi8+CjwvZmlsdGVyPgo8bGluZWFyR3JhZGllbnQgaWQ9InBhaW50MF9saW5lYXIiIHgxPSIyMyIgeTE9IjciIHgyPSIyMyIgeTI9IjI3IiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSI+CjxzdG9wIHN0b3AtY29sb3I9IiM4Nzc0RkYiLz4KPHN0b3Agb2Zmc2V0PSIwLjUiIHN0b3AtY29sb3I9IiM4Nzc0RkYiLz4KPHN0b3Agb2Zmc2V0PSIxIiBzdG9wLWNvbG9yPSIjNUY1MUIzIi8+CjwvbGluZWFyR3JhZGllbnQ+CjwvZGVmcz4KPC9zdmc+Cg==)
        center no-repeat;
      width: 48px;
      height: 48px;
    }

    .cardMenu__bookmark {
      background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDgiIGhlaWdodD0iNDgiIHZpZXdCb3g9IjAgMCA0OCA0OCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTMxIDdDMzEuMDA5IDggMzEgOC4zNSAzMSAxMEgzM0MzMyA4LjM1IDMyLjEgNyAzMSA3WiIgZmlsbD0iIzVGNTFCMyIvPgo8ZyBmaWx0ZXI9InVybCgjZmlsdGVyMF9kKSI+CjxwYXRoIGQ9Ik0zMSA3TDE3LjAwMDEgN0MxNS44OTU2IDcgMTUgNy44OTU0MyAxNSA5VjI2LjAzODVDMTUgMjYuODU1MyAxNS45MjY0IDI3LjMyNzYgMTYuNTg3NCAyNi44NDc3TDIzIDIzTDI5LjQxMjYgMjYuODQ3N0MzMC4wNzM2IDI3LjMyNzYgMzEgMjYuODU1MyAzMSAyNi4wMzg0VjdaIiBmaWxsPSJ1cmwoI3BhaW50MF9saW5lYXIpIi8+CjwvZz4KPGRlZnM+CjxmaWx0ZXIgaWQ9ImZpbHRlcjBfZCIgeD0iMTIiIHk9IjQiIHdpZHRoPSIyMiIgaGVpZ2h0PSIyNi4wNDAyIiBmaWx0ZXJVbml0cz0idXNlclNwYWNlT25Vc2UiIGNvbG9yLWludGVycG9sYXRpb24tZmlsdGVycz0ic1JHQiI+CjxmZUZsb29kIGZsb29kLW9wYWNpdHk9IjAiIHJlc3VsdD0iQmFja2dyb3VuZEltYWdlRml4Ii8+CjxmZUNvbG9yTWF0cml4IGluPSJTb3VyY2VBbHBoYSIgdHlwZT0ibWF0cml4IiB2YWx1ZXM9IjAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDEyNyAwIi8+CjxmZU9mZnNldC8+CjxmZUdhdXNzaWFuQmx1ciBzdGREZXZpYXRpb249IjEuNSIvPgo8ZmVDb2xvck1hdHJpeCB0eXBlPSJtYXRyaXgiIHZhbHVlcz0iMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMC4yIDAiLz4KPGZlQmxlbmQgbW9kZT0ibm9ybWFsIiBpbjI9IkJhY2tncm91bmRJbWFnZUZpeCIgcmVzdWx0PSJlZmZlY3QxX2Ryb3BTaGFkb3ciLz4KPGZlQmxlbmQgbW9kZT0ibm9ybWFsIiBpbj0iU291cmNlR3JhcGhpYyIgaW4yPSJlZmZlY3QxX2Ryb3BTaGFkb3ciIHJlc3VsdD0ic2hhcGUiLz4KPC9maWx0ZXI+CjxsaW5lYXJHcmFkaWVudCBpZD0icGFpbnQwX2xpbmVhciIgeDE9IjIzIiB5MT0iNyIgeDI9IjIzIiB5Mj0iMjcuMDQwMiIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiPgo8c3RvcCBzdG9wLWNvbG9yPSIjODc3NEZGIi8+CjxzdG9wIG9mZnNldD0iMC41IiBzdG9wLWNvbG9yPSIjODc3NEZGIi8+CjxzdG9wIG9mZnNldD0iMSIgc3RvcC1jb2xvcj0iIzVGNTFCMyIvPgo8L2xpbmVhckdyYWRpZW50Pgo8L2RlZnM+Cjwvc3ZnPgo=)
        center no-repeat;
      width: 48px;
      height: 48px;
    }

    .cardMenu__options {
      background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDgiIGhlaWdodD0iNDgiIHZpZXdCb3g9IjAgMCA0OCA0OCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgZmlsdGVyPSJ1cmwoI2ZpbHRlcjBfZCkiPgo8cGF0aCBkPSJNMTUgOUMxNSA3Ljg5NTQzIDE1Ljg5NTQgNyAxNyA3SDMxVjI1QzMxIDI2LjEwNDYgMzAuMTA0NiAyNyAyOSAyN0gxN0MxNS44OTU0IDI3IDE1IDI2LjEwNDYgMTUgMjVWOVoiIGZpbGw9InVybCgjcGFpbnQwX2xpbmVhcikiLz4KPC9nPgo8cGF0aCBkPSJNMzEgN0MzMS4wMDkgOCAzMSA4LjM1IDMxIDEwSDMzQzMzIDguMzUgMzIuMSA3IDMxIDdaIiBmaWxsPSIjNUY1MUIzIi8+CjxjaXJjbGUgY3g9IjE5IiBjeT0iMTcuNSIgcj0iMS41IiBmaWxsPSJ3aGl0ZSIvPgo8Y2lyY2xlIGN4PSIyMyIgY3k9IjE3LjUiIHI9IjEuNSIgZmlsbD0id2hpdGUiLz4KPGNpcmNsZSBjeD0iMjciIGN5PSIxNy41IiByPSIxLjUiIGZpbGw9IndoaXRlIi8+CjxkZWZzPgo8ZmlsdGVyIGlkPSJmaWx0ZXIwX2QiIHg9IjEyIiB5PSI0IiB3aWR0aD0iMjIiIGhlaWdodD0iMjYiIGZpbHRlclVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgY29sb3ItaW50ZXJwb2xhdGlvbi1maWx0ZXJzPSJzUkdCIj4KPGZlRmxvb2QgZmxvb2Qtb3BhY2l0eT0iMCIgcmVzdWx0PSJCYWNrZ3JvdW5kSW1hZ2VGaXgiLz4KPGZlQ29sb3JNYXRyaXggaW49IlNvdXJjZUFscGhhIiB0eXBlPSJtYXRyaXgiIHZhbHVlcz0iMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMTI3IDAiLz4KPGZlT2Zmc2V0Lz4KPGZlR2F1c3NpYW5CbHVyIHN0ZERldmlhdGlvbj0iMS41Ii8+CjxmZUNvbG9yTWF0cml4IHR5cGU9Im1hdHJpeCIgdmFsdWVzPSIwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwIDAgMCAwLjIgMCIvPgo8ZmVCbGVuZCBtb2RlPSJub3JtYWwiIGluMj0iQmFja2dyb3VuZEltYWdlRml4IiByZXN1bHQ9ImVmZmVjdDFfZHJvcFNoYWRvdyIvPgo8ZmVCbGVuZCBtb2RlPSJub3JtYWwiIGluPSJTb3VyY2VHcmFwaGljIiBpbjI9ImVmZmVjdDFfZHJvcFNoYWRvdyIgcmVzdWx0PSJzaGFwZSIvPgo8L2ZpbHRlcj4KPGxpbmVhckdyYWRpZW50IGlkPSJwYWludDBfbGluZWFyIiB4MT0iMjMiIHkxPSI3IiB4Mj0iMjMiIHkyPSIyNyIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiPgo8c3RvcCBzdG9wLWNvbG9yPSIjODc3NEZGIi8+CjxzdG9wIG9mZnNldD0iMC41IiBzdG9wLWNvbG9yPSIjODc3NEZGIi8+CjxzdG9wIG9mZnNldD0iMSIgc3RvcC1jb2xvcj0iIzVGNTFCMyIvPgo8L2xpbmVhckdyYWRpZW50Pgo8L2RlZnM+Cjwvc3ZnPgo=)
        center no-repeat;
      width: 48px;
      height: 48px;
    }
  }

  .routineCard__header {
    width: 100%;
    margin-top: -12px;
  }

  .routineTitle {
    font-size: 18px;
    line-height: 23px;
    margin: 0;
  }

  .routineTitle__separator {
    margin-bottom: 0;
    border: none;
    border-bottom: 1px solid var(--dark-color);
    width: 100%;
  }

  .routineCard {
    padding: 10px 20px;
    line-height: 19px;
    background: var(--soft-color);
  }

  .routineCard__content {
    margin-bottom: 10px;
    word-break: break-word;
  }

  .routineCard__content > p, .routineCard__content > div > p {
    margin: 10px 0;
  }
`;
