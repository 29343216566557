import { useEffect, useState } from 'react';

import api from '../../services/axios';
import { PageHeader } from '../../components/molecules/PageHeader';
import { DataTable } from '../../components/molecules/DataTable';

import { RoutinesContainer } from './style';
import { RoutineFilter } from '../../components/molecules/RoutineFilter';
import { toast } from 'react-toastify';

export function Routines() {
  const [routines, setRoutines] = useState(null);
  const [filter, setFilter] = useState(null);
  const [filteredRoutines, setFilteredRoutines] = useState(null);
  const [advancedFilter, setAdvancedFilter] = useState(false);
  const [isLoadingRoutines, setIsLoadingRoutines] = useState(true);

  async function handleGetRoutines() {
    setIsLoadingRoutines(true)
    try {
      
      const response = await api.get('routines');
      
      setRoutines(response.data);
      setFilteredRoutines(response.data);
    } catch (error) {
      toast.error('Ocorreu um erro!')
    }
    setIsLoadingRoutines(false)
  }

  useEffect(() => {
    handleGetRoutines();
  }, []);

  useEffect(() => {
    filterRoutines();
  }, [filter, routines]);

  const filterRoutines = (input) => {
    if (filter !== '' && filter?.length >= 3 && routines) {
      const result = routines.filter(({ title }) =>
        title
          .toLowerCase()
          .normalize('NFD')
          .replace(/([\u0300-\u036f]|[^0-9a-zA-Z ])/g, '')
          .includes(
            filter
              ?.toLowerCase()
              .normalize('NFD')
              .replace(/([\u0300-\u036f]|[^0-9a-zA-Z ])/g, '')
          )
      );
      setFilteredRoutines(result);
      return;
    }
    setFilteredRoutines(routines);
  };

  return (
    <RoutinesContainer>
      <PageHeader
        filter={filter}
        setFilter={setFilter}
        title="Rotinas"
        route="routines"
      >
      <button className={`advancedFilter__button ${advancedFilter ? 'active' : ''}`} onClick={() => setAdvancedFilter(!advancedFilter)}>Busca Avançada</button>
      {advancedFilter && <RoutineFilter setFilter={setRoutines} setIsLoadingRoutines={setIsLoadingRoutines}/>}
      </PageHeader>
      <div className="table__container">
        <DataTable data={filteredRoutines} route="routines" isLoadingData={isLoadingRoutines}/>
      </div>
    </RoutinesContainer>
  );
}
