import styled from 'styled-components';

export const Button = styled.button`
  border: 1px solid var(--tertiary);
  border-radius: 5px;
  background-color: var(--tertiary);
  color: white;
  padding: 0.5rem;
  color: #fff;
  
  &:hover {
    filter: brightness(0.9);

  }

`;

export const ScrollableImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: fit-content;
  padding: 1rem;

  border-radius: 5px;
  background-color: var(--primary);
  color: white;
  
  img {
    height: 200px; 
  }
`
