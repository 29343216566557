import styled from 'styled-components';

export const EditorContainer = styled.div`
  background-color: var(--secondary);
  border-radius: 5px;
  padding: 0.5rem;

  .buttons__container {
    .editor__button {
      background-color: transparent;
      border: none;
      border-radius: 5px;
      padding: 0.5rem;
      margin: 0.1rem;
      color: white;
      transition: background-color 0.2s;
      &.active {
        background-color: var(--content);
      }
      &:hover {
        filter: brightness(0.8);
      }
      &:disabled {
        filter: brightness(0.6);
      }
      &.fontColor {
        /* &:after {
          content: '';
          display: block;
          position: absolute;
          width: 0.7rem;
          height: 0.2rem;
          border: 1px solid var(--secondary);
          
          background: linear-gradient(90deg, rgba(255,0,0,1) 0%, rgba(0,255,0,1) 50%, rgba(0,0,255,1) 100%);
        } */
      }
      
      
    }
  }
  
  .fontColor__div {
    display: inline-block;
    .fontColor__container {
      height: 100px;
      width: 100px;
      position: absolute;
      display: block;
      background-color: white;
      z-index: 95;
  
      &:hover {
        filter: none !important;
      }
    }

  }

  

  .editor__container {
    background-color: white;
    color: black;
    line-height: 1.5rem;
    margin: 0 0.2rem 0.2rem;
    padding: 0.5rem;
    border-radius: 5px;
    max-height: 430px;
    overflow-y: auto;
    ::-webkit-scrollbar {
      width: 10px;
    }
    ::-webkit-scrollbar-track {
      background-color: var(--primary);
    }
    ::-webkit-scrollbar-thumb {
      background-color: var(--content);
      border-radius: 5px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background-color: var(--tertiary);
    }
  }

  .DraftEditor-editorContainer {
    z-index: 90;
  }
`;
