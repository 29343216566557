import { convertFromHTML } from "draft-convert";
import { ContentState } from "draft-js";
  
function removeEmptyAndUnstyledBlocks(contentState) {
  const blockMap = contentState.getBlockMap()

  let blocksArray = []
  let hasEmptyBlocks = false;

  blockMap?.forEach(block => {
    if(block?.getText().trim().length) {
      blocksArray.push(block)
    } else {
      hasEmptyBlocks = true;
    }
  })
  
  if(!hasEmptyBlocks) {
    return contentState
  }

  const response = ContentState.createFromBlockArray(blocksArray)

  return response;
}

export function convertContentFromHTML(contentText) {
  let arrayOfImagesBeforeEditing = []
  let arrayOfLinksBeforeEditing = []

  const contentState = convertFromHTML({
    htmlToStyle: (nodeName, node, currentStyle) => {
      if (nodeName === 'sup') {
        return currentStyle.add('SUPERSCRIPT');
      }
      if (nodeName === 'sub') {
        return currentStyle.add('SUBSCRIPT');
      }

      return currentStyle;
    },
    htmlToEntity: (nodeName, node, createEntity) => {

      // LINK
      if (nodeName === 'a') {

        const isInternalLink = node.attributes.href.value.includes('link=')
        const isCalculator = node.attributes.href.value.includes('/app/')
        if(isInternalLink) {
          const aux = node.attributes.href.value.replace(/%7B|link=|%22|%7D/gm, '')
          arrayOfLinksBeforeEditing.push({link_id: aux, link_origin_text: node.innerText});
          return createEntity('LINK', 'MUTABLE', {
            href: node.attributes.href.value,
            isPendingLink: node.attributes['data-pending']?.value
          });
        } else if (isCalculator) {
          return createEntity("LINK", "MUTABLE", {
            href: node.attributes.href.value,
            isExternalLink: false,
            isCalculator: true,
          });
        }
        return createEntity('LINK', 'MUTABLE', {
          href: node.attributes.href.value,
          isExternalLink: true
        })
      }
      // CAROUSEL
      if (nodeName === 'div' && node.classList?.contains('carousel-items')) {

        let arrayAux = [];

        node.childNodes[0].childNodes.forEach(
          async (element) =>
            element.nodeName === 'IMG' &&
            await arrayAux.push(element.attributes.src.value)
        );
        const aux = arrayAux.toString();
        
        arrayAux.forEach((e) => {
          arrayOfImagesBeforeEditing.push(e.replace('images/', ''))
        });

        return createEntity('carousel', 'IMMUTABLE', { imgarray: aux });
      }
      // SCROLLABLE IMAGE
      if (nodeName === 'div' && node.classList?.contains('scrollable-image')) {
        let arrayAux = [];

        node.childNodes.forEach(
          (element) =>{
            if(element.nodeName === 'IMG'){
            arrayAux.push(element.attributes.src.value)}}
        );
        
        arrayAux.forEach((e) => {
          arrayOfImagesBeforeEditing.push(e.replace('images/', ''))
        });

        if(arrayAux.length) {
          return createEntity('scrollable-image', 'IMMUTABLE', { src: arrayAux[0] });
        }
        return null;
      }
      // IMAGE
      if (
        nodeName === 'img' &&
        !node.parentElement.parentElement.classList.contains('carousel-items') &&
        !node.parentElement.classList.contains('scrollable-image')
      ) {
        arrayOfImagesBeforeEditing.push(node.attributes.src.value.replace('images/', ''))
        return createEntity('image', 'MUTABLE', {
          src: node.attributes.src.value,
        });
      }

      // font-color
      if (nodeName === 'span' && node?.attributes?.style?.value.includes('color')) {
        return createEntity('font-color', 'MUTABLE', {
          color: node.attributes.style.value.replace(/color:|;/g , ''),
        });
      }
    },
    htmlToBlock: (nodeName, node, lastList, inBlock) => {

      if (
        nodeName === 'img' &&
        inBlock !== 'atomic' &&
        !node.classList?.contains('carousel-items') &&
        !node.classList?.contains('scrollable-image') &&
        !node.parentElement.classList?.contains('scrollable-image') 
      ) {
        return 'atomic';
      }
      if (
        nodeName === 'div' &&
        node.classList?.contains('carousel-items') &&
        inBlock !== 'atomic'
      ) {

        return 'atomic';
      }
      if (
        nodeName === 'div' &&
        node.classList?.contains('scrollable-image') &&
        inBlock !== 'atomic'
      ) {
        return 'atomic';
      }
    },
  })(contentText);

  const contentStateAux = removeEmptyAndUnstyledBlocks(contentState)

  return { contentState: contentStateAux, arrayOfImages: arrayOfImagesBeforeEditing, arrayOfLinksBeforeEditing: arrayOfLinksBeforeEditing };
}