import styled from "styled-components";

export const Container = styled.div`
  width: 420px;
  margin: 1rem 2rem;
  .contentTabCard {
    background-color: var(--secondary);
  }

  .contentTabCard__header {
    background-color: var(--content);
    width: 100%;
  }

  .title {
    padding: 5px;
    font-size: 1.5rem;
    text-align: center;
  }

  .contentTabCard__content {
    padding: 20px;
  }

  .content__list {
  }

  .content__listItem {
    margin: 5px 0;
    display: flex;
    flex-direction: column;
  }

  .listItem__label {
    display: flex;
    flex-direction: column;
    font-size: 0.9rem;
  }

  .listItem__select {
    font-size: 1rem;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid var(--primary);
    padding: 0.5rem;
    margin: 5px 0;
    color: white;
    &:focus {
      outline: none;
    }
    &:hover {
      cursor: pointer;
    }

    option {
      background-color: white;
      color: black;
    }
  }

  .select__option{
    background-color: var(--background);
  }

  .listItem__button {
    margin-top: 1rem;
    padding: 0.5rem;
    background-color: var(--tertiary);
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 1.2rem;
    transition: all linear 0.3s;
    &:hover {
      filter: brightness(0.9);
    }
  }
`;