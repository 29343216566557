import { useContext, useState, useEffect } from 'react';
import { MdEdit } from 'react-icons/md';
import { useHistory, Link } from 'react-router-dom';
import { ContextAplication } from '../../../context';
import { Pagination } from '../../atoms/Pagination';
import { LinksTableContainer } from './style';

export default function LinksTable({ data, routineId }) {
  const history = useHistory()

  const [filteredLinks, setFilteredLinks] = useState(null);
  const [paginatedLinks, setPaginatedLinks] = useState(null);

  const [page, setPage] = useState(1);

  const { handleGetCurrentContent } = useContext(ContextAplication)

  function handleLinksArray() {
    setPaginatedLinks(filteredLinks?.slice((page - 1) * 10, page * 10));
  }

  useEffect(() => {
    filterLinks('');
  }, [data])

  useEffect(() => {
    handleLinksArray();
  }, [filteredLinks, page]);

  function handleClickToEditContent(link) {
    handleGetCurrentContent(link.link_origin_id)
  }

  const filterLinks = (value) => {
    if (value !== '' && data) {
      const result = data.filter(({ link_origin_text }) =>
        link_origin_text
          ?.toLowerCase()
          .normalize("NFD")
          .replace(/([\u0300-\u036f]|[^0-9a-zA-Z ])/g, "")
          .includes(
            value
              ?.toLowerCase()
              .normalize("NFD")
              .replace(/([\u0300-\u036f]|[^0-9a-zA-Z ])/g, "")
          )
      );
      setFilteredLinks(result);
      setPage(1);
      return
    }
    setFilteredLinks([...data]);
  }

  if (data.length === 0) {
    return <h3>Nenhum link encontrado</h3>;
  }

  return (
    <LinksTableContainer>
      <div className="input__container">
        <label htmlFor="header__input">Digite sua busca:</label>
        <input
          className="search__input"
          type="text"
          onChange={(e) => filterLinks(e.target.value)}
        />
      </div>
      <table className="linksTables__table">
        <thead className="table__header">
          <tr className="table__headerRow">
            <th className="table__headerItem">Palavra</th>
            <th className="table__headerItem">Destino</th>
            <th className="table__headerItem">Ações</th>
          </tr>
        </thead>
        <tbody className="table__body">
          {paginatedLinks &&
            paginatedLinks.map((link) => (
              <tr className="table__bodyRow">
                <td className="table__item">{link?.link_origin_text}</td>
                <td className="table__item">
                  {link?.type === 'R'
                    ? link.routine_id
                      ? `${link.routine_id?.title}/ ${link.tab_id?.title || '- '}/ ${link.card_id?.title || '- '}`
                      : 'Link sem destino'
                    : link.therapeutic_group_id
                      ? `${link.therapeutic_group_id?.title}/ ${link.active_principle_id?.title || '- '}/ ${link.dosage_id?.recommendation || '- '}`
                      : 'Link sem destino'}
                </td>
                <td className="table__item">
                  {link?.link_origin_id ?
                    <Link
                      to={{
                        pathname: `/pendinglinks/update/${link._id}`,
                        search: '',
                        hash: '',
                        state: {
                          routineId: routineId
                        }
                      }}
                      onClick={() => handleClickToEditContent(link)}
                      className="editButton__link"
                    >
                      <MdEdit />
                    </Link>
                    : <h3>Link sem origem</h3>}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      {filteredLinks?.length > 10 && (
        <Pagination
          currentPage={page}
          totalCountOfRegisters={filteredLinks.length}
          onPageChange={(e) => setPage(e)}
        />
      )}
    </LinksTableContainer>
  );
}
