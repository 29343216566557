import styled from 'styled-components';

export const PendingLinksUpdateContainer = styled.div`
  padding: 4rem;

  .pendingLink__title {
    font-size: 1.5rem;
    margin-bottom: 4rem;
  }

  .pendingLink__routineTitle {
    margin-bottom: 5px;
  }

  .pendingLink__tabTitle {
    margin-bottom: 5px;
  }

  .backButton__pendingLinks {
    border: none;
    border-radius: 5px;
    background-color: var(--content);
    color: white;
    padding: 0.6rem;
    margin-bottom: 15px;
    &:hover {
      background-color: var(--secondary);
    }
  }
`