import { useState, useEffect } from 'react';
import { Container } from './style';
import api from '../../services/axios';
import { getStorage, ref, uploadBytesResumable } from 'firebase/storage';
import { StorageProvider, useFirebaseApp, useStorage } from 'reactfire';
import { toast } from 'react-toastify';
import { ImageDashboardUploadComponent } from '../../components/atoms/ImageDashboardUploadComponent';

export function Temp() {
  const storage = useStorage();

  const [images, setImages] = useState(null);

  const [pagination, setPagination] = useState(1);
  const [searchHasHD, setSearchHasHD] = useState(false);
  const [term, setTerm] = useState('');

  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [successModalData, setSuccessModalData] = useState(null);
  const [loading, setLoading] = useState(false);

  async function handleGetImages(resetPagination = true) {
    setLoading(true);
    setImages(null);
    const response = await api.get(`/image/galery`, {
      params: {
        'filter[has_hd]': searchHasHD
          ? true
          : searchHasHD === false
          ? false
          : undefined,
        'filter[term]': term,
      },
    });
    setImages(response.data);
    if (resetPagination) {
      setPagination(0);
    }
    setLoading(false);
  }
  useEffect(() => {
    handleGetImages();
  }, []);

  async function uploadImage(img, name, is_hd = false) {
    setLoading(true);
    try {
      const newRef = ref(
        storage,
        is_hd ? `imagesHD/${name}` : `images/${name}`
      );

      await uploadBytesResumable(newRef, img);

      if (is_hd) {
        await api.put('/image/update-by-name', {
          imageName: name,
          params: { has_hd: is_hd },
          isUpdate: false,
        });
      }
      // handleGetImages(false);
      setSuccessModalOpen(true);
      setTimeout(() => {
        setSuccessModalData({ name });
      }, 1000);
      toast.success(`Imagem ${is_hd ? 'HD' : 'normal'} salva com sucesso!`);
    } catch (error) {
      toast.error('Ocorreu um erro!', {
        theme: 'colored',
        closeOnClick: true,
      });
      console.log(error);
    }
    setLoading(false);
  }

  return (
    <Container>
      <h1>Gerenciador de Imagens</h1>

      <div className="input__container">
        <label className="by__title" htmlFor="">
          <span>Nome: </span>
          <input type="text" onChange={(e) => setTerm(e.target.value)} />
        </label>

        <div className="filters">
          <h2>Filtros:</h2>
          <div className="radio__filter">
            <input
              type="radio"
              checked={searchHasHD === false}
              id="sem_hd"
              name="filter__radio"
              onChange={() => setSearchHasHD(false)}
            />
            <label for="sem_hd">sem HD</label>
            <input
              type="radio"
              id="com_hd"
              checked={searchHasHD === true}
              name="filter__radio"
              onChange={() => setSearchHasHD(true)}
            />
            <label for="com_hd">com HD</label>
            <input
              type="radio"
              id="todas"
              checked={searchHasHD === null}
              name="filter__radio"
              onChange={() => setSearchHasHD(null)}
            />
            <label for="todas">Todas</label>
          </div>
        </div>

        <button onClick={handleGetImages}>BUSCAR</button>
      </div>

      {!!images && (
        <div className="pagination">
          <span className="pagination__count">
            {pagination * 50 + 1} - {pagination * 50 + 50} de {images.length}
          </span>
          <div>
            {Array.from(Array(Math.ceil(images?.length / 50)).keys())
              .slice(
                pagination <= 2 ? 1 : pagination + 1 - 3,
                pagination + 1 + 3
              )
              .map((e) => (
                <button
                  className={e === pagination + 1 && 'active'}
                  onClick={() => {
                    setPagination(e - 1);
                    window.scrollTo({
                      top: 0,
                      behavior: 'smooth',
                    });
                  }}
                >
                  {e}
                </button>
              ))}
          </div>
        </div>
      )}
      <div className="table_container">
        {images?.slice(pagination * 50, pagination * 50 + 49)?.map((image) => (
          <div key={image.name} className="row">
            <p className="title">{image.name}</p>
            <div className="content">
              <div className="origin__container">
                <h2 className="origin__title">Imagem presente em:</h2>
                {image.origins?.map((origin) => {
                  if (origin.routine_id) {
                    return (
                      <div className="origin__content">
                        <h3>
                          <span>Rotina:</span> {origin.routine_id?.title} -{' '}
                          {origin.routine_id.legend}
                        </h3>
                        {origin?.tab_id && (
                          <h3>
                            <span>Aba:</span> {origin?.tab_id?.title}
                          </h3>
                        )}
                        {origin?.card_id && (
                          <h3>
                            <span>Card:</span> {origin?.card_id?.title}
                          </h3>
                        )}
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={`https://app.blackbook.com.br/app/routines/${
                            origin.routine_id._id
                          }${origin?.tab_id ? '/' + origin.tab_id._id : ''}${
                            origin?.card_id ? '/' + origin.card_id._id : ''
                          }`}
                        >
                          ver
                        </a>
                      </div>
                    );
                  }
                })}
              </div>
              <div className="img_container">
                <div className="image_box">
                  <p>padrão</p>
                  <ImageDashboardUploadComponent image={image} is_hd={false}/>
                </div>
                <div className="image_box">
                  <p>Alta</p>
                  <ImageDashboardUploadComponent image={image} is_hd={true}/>
                  
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      {!!images && (
        <div className="pagination">
          <span className="pagination__count">
            {pagination * 50 + 1} - {pagination * 50 + 50} de {images.length}
          </span>
          <div>
            {Array.from(Array(Math.ceil(images?.length / 50)).keys())
              .slice(
                pagination <= 2 ? 1 : pagination + 1 - 3,
                pagination + 1 + 3
              )
              .map((e) => (
                <button
                  className={e === pagination + 1 && 'active'}
                  onClick={() => {
                    setPagination(e - 1);
                    window.scrollTo({
                      top: 0,
                      behavior: 'auto',
                    });
                  }}
                >
                  {e}
                </button>
              ))}
          </div>
        </div>
      )}
      {successModalOpen && (
        <div className="modal__overlay">
          <div className="modal__container">
            <h1>{successModalData?.name} alterada com sucesso</h1>
            <button
              onClick={() => {
                setSuccessModalData(null);
                setSuccessModalOpen(false);
              }}
            >
              X
            </button>
            <div className="img__container">
              <div>
                <img
                  src={`https://firebasestorage.googleapis.com/v0/b/${
                    process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
                  }/o/images%2F${
                    successModalData?.name
                  }?alt=media&${new Date().getTime()}`}
                />
              </div>
              <div>
                <img
                  src={`https://firebasestorage.googleapis.com/v0/b/${
                    process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
                  }/o/imagesHD%2F${
                    successModalData?.name
                  }?alt=media&${new Date().getTime()}`}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {loading && <div className="modal__overlay">Carregando...</div>}
    </Container>
  );
}

export function ImagesDashboard() {
  const appFirebase = useFirebaseApp();
  return (
    <StorageProvider sdk={getStorage(appFirebase)}>
      <Temp />
    </StorageProvider>
  );
}
