import { Container } from "./style";
import { FiMinusCircle } from "react-icons/fi";
import {
  IoIosArrowDropupCircle,
  IoIosArrowDropdownCircle,
} from "react-icons/io";
import { toast } from "react-toastify";
import { useContext, useState } from "react";
import { ContextAplication } from "../../../context";
import api from "../../../services/axios";

export function ContentChildrenList({ contents, parentId, setIsLoad, setContentChange }) {
  const { currentContent, handleGetCurrentContent } =
    useContext(ContextAplication);

  const [contentArray, setContentArray] = useState(contents);

  function changePosition(from, to) {
    const newArr = [...contentArray];

    newArr[from - 1] = { ...newArr[from - 1], position: to };
    newArr[to - 1] = { ...newArr[to - 1], position: from };
    newArr.sort((a, b) => a.position - b.position);
    for (const children of newArr) {
      if (children.position === from || children.position === to) {
        handlePositionChange(children);
      }
    }
    setContentArray([...newArr]);
  }

  async function handlePositionChange(aux) {
    try {
      await api.put(`/content/update/${aux._id}`, { position: aux.position });
      toast.success("Alteração realizada com sucesso!", {
        theme: "colored",
        closeOnClick: true,
      })
    } catch (error) {
      toast.error("Ocorreu um erro!", {
        theme: "colored",closeOnClick: true,
      })
      console.log(error);
    }
  }

  async function handleChildrenDelete(e) {
    try {
      if (window.confirm(`Deseja deletar o conteúdo ${e.title}?`)) {
        await api.delete(`/content/delete-children/${e._id}/${parentId}`);
        setIsLoad(false);
        setContentChange(true);
        toast.success("Conteúdo deletado com sucesso!", {
          theme: "colored",
          closeOnClick: true,
        });
      } else {
        return;
      }
    } catch (error) {
      toast.error("Ocorreu um erro!", {
        theme: "colored",
        closeOnClick: true,
      })
      console.log(error);
    }
  }

  async function handleShowInIndex(content) {
    content.show_in_index = !content.show_in_index;
    try {
      await api.put(`/content/update/${content._id}`, {
        show_in_index: content.show_in_index,
      });
    } catch (error) {
      toast.error("Ocorreu um erro!", {
        theme: "colored",
        closeOnClick: true,
      })
      console.log(error);
    }
  }

  return (
    <Container>
      <section className="contentList">
        {contentArray &&
          contentArray
            .sort((a, b) => a.position - b.position)
            .map((content) => (
              <div className="contentList__contentAndButton">
                <div className="upAndDown__container">
                  <button
                    disabled={content.position <= 1}
                    className="upAndDown__button"
                    onClick={() =>
                      changePosition(content.position, content.position - 1)
                    }
                  >
                    <IoIosArrowDropupCircle />
                  </button>
                  <button
                    disabled={content.position >= contentArray.length}
                    className="upAndDown__button"
                    onClick={() =>
                      changePosition(content.position, content.position + 1)
                    }
                  >
                    <IoIosArrowDropdownCircle />
                  </button>
                </div>
                <button
                  onClick={() => handleGetCurrentContent(content?._id)}
                  className={`contentList__content ${content._id === currentContent?._id ? "active" : ""
                    }`}
                >
                  {content?.content_type_id?.title} - {content?.title}
                  <div className="contentList__options">
                    <button className="contentList__button remove">
                      <FiMinusCircle
                        onClick={() => handleChildrenDelete(content)}
                      />
                    </button>
                    <input
                      className="contentList__check"
                      type="checkbox"
                      name="showOnIndex"
                      checked={content.show_in_index ? true : false}
                      id=""
                      onClick={() => handleShowInIndex(content)}
                    />
                  </div>
                </button>
              </div>
            ))}
      </section>
    </Container>
  );
}
