import { Container } from "./style";
import api from '../../../services/axios';
import { useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { ContentList } from "../ContentList";
import { BackButton } from "../../atoms/BackButton";
import { ContextAplication } from "../../../context";

export function ContentTabCard({ setCurrentCard, setCurrentContent, setContentChange }) {
  const { handleGetCurrentContent } = useContext(ContextAplication)

  const { id } = useParams();

  const [tabs, setTabs] = useState(null);
  const [selectedTab, setSelectedTab] = useState(null);
  const [selectedCard, setSelectedCard] = useState(null);
  
  const [isAddContent, setIsAddContent] = useState(false);
  
  async function handleGetTabs() {
    const response = await api.get(`/routine-populate/${id}`);
    setTabs(response.data.routine_tabs_id);
  }
  

  function handleSelectTab(id) {
    const aux = tabs.find((tab) => tab._id === id);
    setSelectedTab(aux);
    setSelectedCard(null);
    setCurrentCard({card: null, tabColor: null})
    handleGetCurrentContent(null)
  }

  function handleSelectCard(id) {
    const aux = selectedTab.routine_tabs_cards_id.find(card => card._id === id)
    setSelectedCard(aux)
    setCurrentCard({card: aux, tabColor: selectedTab.tab_id})
    handleGetCurrentContent(null)
  }

  useEffect(() => {
    handleGetTabs();
  }, []);

  return (
    <Container>
      <BackButton route={`routines/update/${id}`} />
      <div className="contentTabCard">
        <section className="contentTabCard__content">
          <ul className="content__list">
            <li className="content__listItem">
              <label htmlFor="" className="listItem__label">
                Selecione a aba
                <select
                  onChange={(e) => handleSelectTab(e.target.value)}
                  name="tabs"
                  id="tabs"
                  className="listItem__select"
                >
                  <option value="" className="select__option">
                    Selecione a aba
                  </option>
                  {tabs &&
                    tabs.map((tab) => (
                      <option value={tab._id} className="select__option">
                        {tab.tab_id.title}
                      </option>
                    ))}
                </select>
              </label>
            </li>

            {selectedTab && (
              <li className="content__listItem">
                <label htmlFor="" className="listItem__label">
                  Selecione o card
                  <select
                    onChange={(e) => handleSelectCard(e.target.value)}
                    name="cards"
                    id="cards"
                    className="listItem__select"
                    value={selectedCard?._id || ""}
                  >
                    <option value="" className="select__option">
                      Selecione o card
                    </option>
                    {selectedTab &&
                      selectedTab.routine_tabs_cards_id.map((card) => (
                        <option value={card._id} className="select__option">
                          {card.card_id.title}
                        </option>
                      ))}
                  </select>
                </label>
              </li>
            )}

            {selectedCard && !isAddContent && (
              <ContentList
                card={selectedCard}
                setContentChange={setContentChange}
              />
            )}
          </ul>
        </section>
      </div>
    </Container>
  );
}