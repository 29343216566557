import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import Select from "react-select";
import getFragmentFromSelection from "draft-js/lib/getFragmentFromSelection";
import { RiCloseFill } from "react-icons/ri";

import drugApi from "../../../services/axiosDrugApi";
import { ContextAplication } from "../../../context";

import { Container, SelectComponentStyles } from "./style";
import api from "../../../services/axios";

const parseLink = (link) => {
  const aux = link.replace(/%7B%7Blink=%22|%22%7D%7D/gm, "");
  return aux;
};

export function ModalLinkComponent({
  editorState,
  callbackFunction,
  routineId,
}) {
  const {
    currentContent,
    setEntityNewData,
    setIsModalLinkOpen,
    linkModalInfos,
    setLinkModalInfos,
  } = useContext(ContextAplication);

  const link_id = linkModalInfos?.link_id ? linkModalInfos.link_id : null;

  const [isForRoutine, setIsForRoutine] = useState(true);
  const [isExternalLink, setIsExternalLink] = useState(false);
  const [externalLinkHref, setExternalLinkHref] = useState(null);
  const [isCalculator, setIsCalculator] = useState(false);
  const [calculatorRef, setCalculatorRef] = useState("");
  const linkText = !linkModalInfos?.link_id
    ? getLinkText()
    : linkModalInfos.textSelected;

  const [linkManagerObject, setLinkManagerObject] = useState(null);

  const [routines, setRoutines] = useState([]);
  const [tabs, setTabs] = useState([]);
  const [cards, setCards] = useState([]);
  const [therapeuticGroup, setTherapeuticGroup] = useState([]);
  const [activePrinciples, setActivePrinciples] = useState([]);
  const [dosages, setDosages] = useState([]);

  const calculators = [
    {
      value: "calc_PERC",
      label: "Critérios PERC de embolia pulmonar",
    },
    {
      value: "calc_CKD",
      label: "Clearence de creatinina (CKD-EPI, 2021)",
    },
    {
      value: "calc_CrCl",
      label: "Clearance de creatinina Cockcroft-Gault",
    },
    {
      value: "calc_Schwartz",
      label: "Clearance de creatinina pediátrico (Schwartz)",
    },
    {
      value: "calc_CURB",
      label: "CURB-65 - Escore de gravidade de pneumonia comunitária",
    },
    {
      value: "calc_BirthDate",
      label: "Data do parto e idade gestacional",
    },
    {
      value: "calc_Apgar",
      label: "Escala de Apgar",
    },
    {
      value: "calc_Glasgow",
      label: "Escala de Glasgow",
    },
    {
      value: "calc_Geneve",
      label: "Escore Genebra de embolia pulmonar (revisado) ",
    },
    {
      value: "calc_GRACE",
      label: "Escore GRACE",
    },
    {
      value: "calc_HEART",
      label: "Escore HEART",
    },
    {
      value: "calc_Killip",
      label: "Escore Killip",
    },
    {
      value: "calc_SOFA",
      label: "Escore SOFA",
    },
    {
      value: "calc_WellsTEP",
      label: "Escore de Wells para embolia pulmonar",
    },
    {
      value: "calc_WellsTVP",
      label: "Escore de Wells para trombose venosa profunda",
    },
    {
      value: "calc_CFExercise",
      label: "Frequência cardíaca ideal nos exercícios",
    },
    {
      value: "calc_IMC",
      label: "Índice de massa corporal (IMC) e análise do peso corporal",
    },
    {
      value: "calc_MEEM",
      label: "MEEM- Mini-Exame do Estado Mental (Mini Mental)",
    },
    {
      value: "calc_MEWS",
      label:
        "Escore MEWS (Modified Early Warning Score) de deterioração clínica",
    },
    {
      value: "calc_MOCA",
      label: "MoCA - Montreal Cognitive Assessment",
    },
    {
      value: "calc_NEWS",
      label: "Escore NEWS e NEWS2 (National Early Warning Score)",
    },
    {
      value: "calc_Oakland",
      label:
        "Escore de Oakland (para definir alta segura em caso de hemorragia digestiva baixa)",
    },
    {
      value: "calc_PAAS",
      label: "PASS - Pediatric Asthma Severity Score",
    },
    {
      value: "calc_PESI",
      label: "PESI (Pulmonary Embolism Severity Index)",
    },
    {
      value: "calc_PEWS",
      label: "Pediatric Early Warning Score (PEWS)",
    },
    {
      value: "calc_PSI",
      label: "PSI ou IGP- Indice de gravidade de pneumonia comunitária",
    },
    {
      value: "calc_BSA",
      label: "Superfície corporal",
    },
    {
      value: "calc_Tc6m",
      label: "Teste da caminhada de 6 minutos",
    },
    {
      value: "calc_TIMINStemi",
      label:
        "TIMI NSTEMI: Escore de risco TIMI para infarto sem elevação de ST ou angina instável",
    },
    {
      value: "calc_TIMIStemi",
      label: "TIMI STEMI: Escore de risco TIMI para infarto com elevação de ST",
    },
    {
      value: "calc_TIMIIndex",
      label: "TIMI TRI: Índice de risco TIMI",
    },
    {
      value: "calc_WAT",
      label:
        "Escala WAT-1 (Withdrawas Assesment Tool 1) de abstinência em pediatria",
    },
    {
      value: "calc_WoodDownes",
      label: "Escore de Wood-Downes-Ferres (gravidade da bronquiolite)",
    },
  ];

  const handleByQuery = async (url, setState) => {
    try {
      const response = await drugApi.get(url);

      const newArray = await response.data.map((obj) => {
        return {
          label: obj?.legend ? `${obj.title} - ${obj.legend}` : obj.title,
          value: obj._id,
          object: obj,
        };
      });
      if (setState) {
        setState(newArray);
      }
      return response;
    } catch (error) {
      console.error(error);
    }
  };

  async function handleGetLink() {
    try {
      if (linkModalInfos?.isExternalLink) {
        setExternalLinkHref(linkModalInfos.link_id);
        setIsExternalLink(true);
        return;
      } else if (linkModalInfos?.isCalculator) {
        const aux = link_id.replace("/app/", "");
        const tempRef = calculators.find((calc) => calc.value === aux);
        setCalculatorRef(tempRef);
        setIsCalculator(true);
        return;
      }
      const aux = parseLink(link_id);
      const { data } = await api.get(`/link-manager/${aux}/link_id`);

      const parsedLinkObject = {
        therapeutic_group_id: data[0].therapeutic_group_id
          ? {
              value: data[0].therapeutic_group_id._id,
              label: data[0].therapeutic_group_id.title,
            }
          : null,
        active_principle_id: data[0].active_principle_id
          ? {
              value: data[0].active_principle_id._id,
              label: data[0].active_principle_id.title,
            }
          : null,
        dosage_id: data[0].dosage_id
          ? {
              value: data[0].dosage_id._id,
              label: `${data[0].dosage_id.category_type_id.title} - ${data[0].dosage_id.recommendation}`,
            }
          : null,
        routine_id: data[0].routine_id
          ? {
              value: data[0].routine_id._id,
              label: `${data[0].routine_id.title} - ${data[0].routine_id.legend}`,
              object: data[0].routine_id,
            }
          : null,
        tab_id: data[0].tab_id
          ? {
              value: data[0].tab_id._id,
              label: data[0].tab_id.title,
              object: data[0].tab_id,
            }
          : null,
        card_id: data[0].card_id
          ? {
              value: data[0].card_id._id,
              label: data[0].card_id.title,
            }
          : null,
        field_origin: data[0].field_origin,
        link_id: data[0].link_id,
        link_origin_id: data[0].link_origin_id,
        link_origin_text: data[0].link_origin_text,
        type: data[0].type,
        _id: data[0]._id,
      };

      setIsForRoutine(parsedLinkObject.type === "R" ? true : false);
      setLinkManagerObject(parsedLinkObject);
    } catch (error) {
      console.log(error);
    }
  }

  function getLinkText() {
    const selected = getFragmentFromSelection(editorState);
    return selected?.map((x) => x.getText()).join("\n");
  }

  useEffect(() => {
    handleByQuery(
      `therapeuticgroup/query-filter?select[]=title`,
      setTherapeuticGroup
    );
    handleByQuery(
      `routine/collection-and-query/r_routine/?select[]=title&select[]=routine_tabs_id&select[]=legend`,
      setRoutines
    );
    if (link_id) {
      handleGetLink();
    }
  }, []);

  const handleFormChange = async (field, value) => {
    const fieldOptions = {
      therapeutic_group_id: async function () {
        let newArray = [];
        if (value) {
          const response = await drugApi.get(
            `activeprinciple/query-filter/?select[]=title&&query[therapeutic_group_id]=${value.value}&query[hidden]=false`
          );

          newArray = await response.data.map((obj) => {
            return {
              label: obj.title,
              value: obj._id,
            };
          });
        }

        setActivePrinciples(newArray);
        setDosages([]);

        setLinkManagerObject({
          _id: linkManagerObject?._id || undefined,
          text: linkManagerObject?.text,
          therapeutic_group_id: value,
          active_principle_id: null,
          dosage_id: null,
        });
      },
      active_principle_id: async function () {
        let newArray = [];
        if (value) {
          const response = await drugApi.get(
            `dosage/by-active-principle/${value.value}`
          );

          newArray = await response.data.map((obj) => {
            return {
              label: `${obj.category_type_id.title} - ${obj.recommendation}`,
              value: obj._id,
            };
          });
        }

        setDosages(newArray);

        setLinkManagerObject({
          ...linkManagerObject,
          active_principle_id: value,
          dosage_id: null,
        });
      },
      dosage_id: function () {
        setLinkManagerObject({ ...linkManagerObject, dosage_id: value });
      },
      routine_id: async function () {
        let newArray = [];
        const query = value.object.routine_tabs_id
          .map((id) => `query[_id][]=${id}`)
          .join("&");
        if (value?.object.routine_tabs_id.length) {
          const response = await drugApi.get(
            `routine/collection-and-query/r_routine_tabs?select[]=tab_id&select[]=routine_tabs_cards_id&${query}`
          );
          newArray = await response.data.map((obj) => {
            return {
              label: obj.tab_id.title,
              value: obj.tab_id._id,
              object: obj,
            };
          });
        }
        setTabs(newArray);
        setCards([]);
        setLinkManagerObject({
          _id: linkManagerObject?._id || undefined,
          text: linkManagerObject?.text,
          routine_id: value,
          tab_id: null,
          card_id: null,
        });
      },
      tab_id: async function () {
        let newArray = [];
        const query = value.object.routine_tabs_cards_id
          .map((id) => `query[_id][]=${id}`)
          .join("&");
        if (value?.object.routine_tabs_cards_id?.length) {
          const response = await drugApi.get(
            `routine/collection-and-query/r_routine_tab_cards?select[]=card_id&${query}`
          );
          newArray = await response.data.map((obj) => {
            return {
              label: obj.card_id.title,
              value: obj.card_id._id,
            };
          });
        }
        setCards(newArray);
        setLinkManagerObject({
          ...linkManagerObject,
          tab_id: value,
          card_id: null,
        });
      },
      card_id: function () {
        setLinkManagerObject({ ...linkManagerObject, card_id: value });
      },
    };

    await fieldOptions[field]();
  };

  async function handleSubmitLink() {
    if (isCalculator) {
      if (linkModalInfos?.link_id) {
        setEntityNewData({
          entityKey: linkModalInfos.linkEntityKey,
          entityType: "update-calculator-link",
          entityData: `/app/${calculatorRef.value}`,
        });
        setIsModalLinkOpen(false);
        setLinkModalInfos(null);
        return;
      }
      callbackFunction({
        href: calculatorRef.value,
        isExternalLink: false,
        isCalculator: true,
      });
      return;
    }
    if (isExternalLink) {
      if (linkModalInfos?.link_id) {
        setEntityNewData({
          entityKey: linkModalInfos.linkEntityKey,
          entityType: "update-external-link",
          entityData: externalLinkHref,
        });
        setIsModalLinkOpen(false);
        setLinkModalInfos(null);
        return;
      }
      callbackFunction({ href: externalLinkHref, isExternalLink: true });
      return;
    }

    const parsedLinkManager = {
      type: isForRoutine ? "R" : "D",
      field_origin: "content",
      routine_id:
        isForRoutine && linkManagerObject?.routine_id
          ? linkManagerObject.routine_id.value
          : null,
      tab_id:
        isForRoutine && linkManagerObject?.tab_id
          ? linkManagerObject.tab_id.value
          : null,
      card_id:
        isForRoutine && linkManagerObject?.card_id
          ? linkManagerObject.card_id.value
          : null,
      therapeutic_group_id:
        !isForRoutine && linkManagerObject?.therapeutic_group_id
          ? linkManagerObject.therapeutic_group_id.value
          : null,
      active_principle_id:
        !isForRoutine && linkManagerObject?.active_principle_id
          ? linkManagerObject.active_principle_id.value
          : null,
      dosage_id:
        !isForRoutine && linkManagerObject?.dosage_id
          ? linkManagerObject.dosage_id.value
          : null,
      link_origin_id: currentContent?._id,
      link_origin_text: linkText,
      link_id: link_id ? parseLink(link_id) : Date.now(),
    };

    try {
      if (link_id) {
        await api.put(
          `/link-manager/${linkManagerObject._id}`,
          parsedLinkManager
        );
        setEntityNewData({
          entityKey: linkModalInfos.linkEntityKey,
          entityType: "update-link",
          entityData: link_id,
          isPendingLink:
            parsedLinkManager.therapeutic_group_id === null &&
            parsedLinkManager.routine_id === null
              ? true
              : false,
        });
        setIsModalLinkOpen(false);
        setLinkModalInfos(null);
      } else {
        const response = await api.post(
          `link-manager/${routineId}`,
          parsedLinkManager
        );
        const isPendingLink =
          parsedLinkManager.therapeutic_group_id === null &&
          parsedLinkManager.routine_id === null
            ? true
            : false;
        callbackFunction(response.data.link_id, isPendingLink);
      }
    } catch (error) {
      toast.error("Ocorreu um erro!", {
        theme: "colored",
        closeOnClick: true,
      });
      console.log(error);
    }
  }

  async function handleRemoveLink() {
    try {
      if (!linkModalInfos?.isExternalLink) {
        await api.delete(`/remove-link-manager/${linkManagerObject._id}`);
      }
      setEntityNewData({
        entityKey: linkModalInfos.linkEntityKey,
        entityType: "delete-link",
        isExternalLink: linkModalInfos?.isExternalLink || false,
      });
      setIsModalLinkOpen(false);
      setLinkModalInfos(null);
      toast.success("Link removido com sucesso!", {
        theme: "colored",
        closeOnClick: true,
      });
    } catch (error) {
      toast.error("Ocorreu um erro!", {
        theme: "colored",
        closeOnClick: true,
      });
      console.log(error);
    }
  }

  function handleCloseModal() {
    setIsModalLinkOpen(false);
    setLinkModalInfos(null);
  }

  // valida se existe algum texto selecionado ao criar o editor
  if (!linkModalInfos?.link_id && !getLinkText()) {
    return (
      <>
        {
          (toast.error("É necessário selecionar um texto para criar um link!"),
          handleCloseModal())
        }
      </>
    );
  }

  return (
    <Container>
      <div className="link__modal">
        <div className="modal__header">
          <h3>
            {link_id ? "Editar Link" : "Criar Link"} no texto: <br />{" "}
            <span>{linkText}</span>
          </h3>
          <button onClick={() => handleCloseModal()}>
            <RiCloseFill />
          </button>
        </div>
        <div className="modal__body">
          <div className="modalType__selector">
            <button
              className={
                isForRoutine && !isExternalLink && !isCalculator && "active"
              }
              onClick={() => {
                setIsForRoutine(true);
                setIsExternalLink(false);
                setIsCalculator(false);
              }}
            >
              Rotina
            </button>
            <button
              className={
                !isForRoutine && !isExternalLink && !isCalculator && "active"
              }
              onClick={() => {
                setIsForRoutine(false);
                setIsExternalLink(false);
                setIsCalculator(false);
              }}
            >
              Droga
            </button>
            <button
              className={isExternalLink && !isCalculator && "active"}
              onClick={() => {
                setIsExternalLink(true);
                setIsCalculator(false);
              }}
            >
              Link externo
            </button>
            <button
              className={isCalculator && "active"}
              onClick={() => {
                setIsCalculator(true);
                setIsForRoutine(false);
                setIsExternalLink(false);
              }}
            >
              Calculadora
            </button>
          </div>
          {isExternalLink ? (
            <input
              className="externalLink__input"
              onChange={(e) => setExternalLinkHref(e.target.value)}
              value={externalLinkHref}
              type="text"
              placeholder="Digite o link externo"
            />
          ) : isCalculator ? (
            <Select
              placeholder={
                calculatorRef.label || "Selecione uma calculadora..."
              }
              value={calculatorRef?.label || null}
              name="calculator"
              styles={SelectComponentStyles}
              options={calculators}
              onChange={(e) => {
                setCalculatorRef(e);
              }}
              isClearable={true}
            />
          ) : isForRoutine ? (
            <div className="form__container">
              <Select
                placeholder="Selecione uma rotina..."
                value={linkManagerObject?.routine_id || null}
                name="routine"
                styles={SelectComponentStyles}
                options={routines}
                onChange={(e) => handleFormChange("routine_id", e)}
                isClearable={true}
              />
              <Select
                placeholder="Selecione uma aba..."
                value={linkManagerObject?.tab_id || null}
                name="tab"
                styles={SelectComponentStyles}
                options={tabs}
                onChange={(e) => handleFormChange("tab_id", e)}
                isClearable={true}
              />
              <Select
                placeholder="Selecione um card..."
                value={linkManagerObject?.card_id || null}
                name="card"
                styles={SelectComponentStyles}
                options={cards}
                onChange={(e) => handleFormChange("card_id", e)}
                isClearable={true}
              />
            </div>
          ) : (
            <div className="form__container">
              <Select
                placeholder="Selecione um grupo terapêutico..."
                value={linkManagerObject?.therapeutic_group_id || null}
                name="therapeuticGroup"
                styles={SelectComponentStyles}
                options={therapeuticGroup}
                onChange={(e) => handleFormChange("therapeutic_group_id", e)}
                isClearable={true}
              />
              <Select
                placeholder="Selecione um princípio ativo..."
                value={linkManagerObject?.active_principle_id || null}
                name="ActivePrinciple"
                styles={SelectComponentStyles}
                options={activePrinciples}
                onChange={(e) => handleFormChange("active_principle_id", e)}
                isClearable={true}
              />
              <Select
                placeholder="Selecione uma dose..."
                value={linkManagerObject?.dosage_id || null}
                name="dosage"
                styles={SelectComponentStyles}
                options={dosages}
                onChange={(e) => handleFormChange("dosage_id", e)}
                isClearable={true}
              />
            </div>
          )}
        </div>

        <div className="button__container">
          {link_id && (
            <button className="delete__button" onClick={handleRemoveLink}>
              Deletar
            </button>
          )}
          <button className="save__button" onClick={handleSubmitLink}>
            Salvar
          </button>
        </div>
      </div>
    </Container>
  );
}
