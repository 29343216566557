import styled from 'styled-components';

export const RoutinesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .advancedFilter__button {
    align-self: flex-end;
    margin-top: 0.5rem;
    padding: 0.5rem;

    border: 1px solid transparent;
    border-radius: 5px;
    background-color: transparent;
    color: white;

    transition: all 0.2s;

    &:hover {
      border: 1px solid var(--primary);
      color: var(--silver)
    }

    &.active {
      border: 1px solid var(--primary);
    }
  }
  
  .table__container {
    width: 60vw;
    padding: 1rem;
    min-height: 450px;
    background-color: var(--content);
  }
`;
