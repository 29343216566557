import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  label {
    display: flex;
    flex-direction: row !important;
    align-items: center;

    margin-left: 1rem;
    
    cursor: pointer;
    
    input {
      cursor: pointer;
    }
  }
`;


export const SelectComponentStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: 'unset',
    border: 'none',
    borderRadius: '0',
    borderBottom: '1px solid var(--green)',
    color: 'white',

    ':hover': {
      border: 'none',
      boxShadow: 'none',
      borderBottom: '1px solid var(--tertiary)',
    },

    ':focus': {
      border: 'none',
      borderBottom: '1px solid var(--tertiary)',
      boxShadow: 'none',
    },

    ':active': {
      border: 'none',
      borderBottom: '1px solid var(--tertiary)',
      boxShadow: 'none',
    },

    ':focus-visible': {
      border: 'none',
      borderBottom: '1px solid var(--tertiary)',
      boxShadow: 'none',
    },

    ':focus-within': {
      border: 'none',
      borderBottom: '1px solid var(--tertiary)',
      boxShadow: 'none',
    }
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: 'white',
      color: 'black',
      cursor: isDisabled ? 'not-allowed' : 'default',
      borderRadius: '0',

      ':active': {
        ...styles[':active'],
        backgroundColor: 'var(--silver)',
      },

      ':hover': {
        ...styles[':hover'],
        backgroundColor: 'var(--silver)',
      },
    };
  },
  placeholder: (styles) => ({ ...styles, color: '#fff' }),
  input: (styles) => ({ ...styles, color: '#fff', width: '100%' }),
  container: (styles) => ({ ...styles, color: '#fff', width: '100%' }),
  singleValue: (styles, { data }) => ({ ...styles, color: '#fff' }),
};