import { useEffect, useContext, useState } from 'react';
import { PendingLinksUpdateContainer } from './style';
import { ContextAplication } from '../../../context';
import { TextEditor } from '../../../components/organisms/TextEditor';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import api from '../../../services/axios';

export function PendingLinksUpdate() {
  const { handleGetCurrentContent } = useContext(ContextAplication);
  const { id } = useParams();
  const history = useHistory()
  const location = useLocation();

  const [linkObject, setLinkObject] = useState();
  const [linkOrigin, setLinkOrigin] = useState();

  async function handleGetOnePendingLink() {
    try {
      const response = await api.get(`/link-manager/${id}/_id`)
      setLinkObject(response.data[0]);
      handleGetCurrentContent(response.data[0].link_origin_id);
      handleGetLinkOrigin();
    } catch (error) {
      console.log(error)
    }
  }

  async function handleGetLinkOrigin() {
    try {
      const result = await api.get(`/link-manager/origin/${id}`)
      setLinkOrigin(result.data);
    } catch (error) {
      console.log(error)
    }
  }

  function handleBack() {
    if (location.state && location.state['routineId'] ) {
      history.push(`/routines/update/${location.state['routineId']}`)
    } else {
      history.push('/pendinglinks')
    }
    handleGetCurrentContent(null);
  }

  useEffect(() => {
    handleGetOnePendingLink();
  }, [])

  return (
    <PendingLinksUpdateContainer>
      <button
        className="backButton__pendingLinks"
        onClick={handleBack}
      >
        Voltar
      </button>
      <h3 className="pendingLink__title">Link: {linkObject?.link_origin_text}</h3>
      <h3 className="pendingLink__routineTitle">Rotina: {linkOrigin?.routine?.title} - {linkOrigin?.routine?.legend} </h3>
      <h3 className="pendingLink__tabTitle">Aba: {linkOrigin?.tab?.title}</h3>
      <h3 className="pendingLink__cardTitle">Card: {linkOrigin?.card?.title}</h3>
      <TextEditor routineId={linkOrigin?.routine?._id} setContentChange={handleBack} />
    </PendingLinksUpdateContainer>
  )
}
