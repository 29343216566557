import { useContext, useState } from "react";
import { FiMinusCircle } from "react-icons/fi";
import {
  IoIosArrowDropdownCircle,
  IoIosArrowDropupCircle,
} from "react-icons/io";
import { MdAddCircleOutline } from "react-icons/md";
import { toast } from "react-toastify";
import { ContextAplication } from "../../../context";
import api from "../../../services/axios";
import { AddContentOnCard } from "../AddContentOnCard";
import { ContentChildrenList } from "../ContentChildrenList";

export default function ContentListItem({
  setIsLoad,
  deleteCallback,
  cardId,
  content,
  changePosition,
  contentArray_length,
  setContentChange
}) {
  const { handleGetCurrentContent, currentContent } =
    useContext(ContextAplication);

  const [addChildren, setAddChildren] = useState(false);

  async function handleDeleteContentByCard() {
    try {
      if (window.confirm('Deseja deletar esse conteúdo?')) {
        await api.delete(`/content/delete/${content._id}/${cardId}`)
        setContentChange(true)
        toast.success('Conteúdo deletado com sucesso!', {
          theme: 'colored',
          closeOnClick: true,
        })
        deleteCallback(content)
      }
    } catch (error) {
      toast.error("Ocorreu um erro!", {
        theme: "colored",
        closeOnClick: true,
      })
      console.log(error)
    }
  }

  async function handleShowInIndex(content) {
    content.show_in_index = !content.show_in_index;
    try {
      await api.put(`/content/update/${content._id}`, {
        show_in_index: content.show_in_index,
      });
    } catch (error) {
      toast.error("Ocorreu um erro!", {
        theme: "colored",
        closeOnClick: true,
      })
      console.log(error);
    }
  }

  return (
    <>
      <div className="contentList__contentAndButton">
        <div className="upAndDown__container">
          <button
            disabled={content.position <= 1}
            className="upAndDown__button"
            onClick={() =>
              changePosition(content.position, content.position - 1)
            }
          >
            <IoIosArrowDropupCircle />
          </button>
          <button
            disabled={content.position >= contentArray_length}
            className="upAndDown__button"
            onClick={() =>
              changePosition(content.position, content.position + 1)
            }
          >
            <IoIosArrowDropdownCircle />
          </button>
        </div>
        <button
          className={`contentList__content ${content?._id === currentContent?._id ? "active" : ""
            }`}
          onClick={() => handleGetCurrentContent(content?._id)}
        >
          {content.content_type_id?.title} {content?.title ? "-" : ""}{" "}
          {content?.title}
          <div className="contentList__options">
            {(content.content_type_id?.code === "container_accordion_title" || content.content_type_id?.code === "container_accordion") && (
              <button className="contentList__button add" onClick={() => {
                setAddChildren(true);
              }}>
                <MdAddCircleOutline />
              </button>
            )}
            <button
              onClick={() => handleDeleteContentByCard()}
              className="contentList__button remove">
              <FiMinusCircle />
            </button>
            <input
              className="contentList__check"
              type="checkbox"
              name="showOnIndex"
              checked={content.show_in_index ? true : false}
              id=""
              onClick={() => handleShowInIndex(content)}
            />
          </div>
          {addChildren && <AddContentOnCard closeModal={() => { setAddChildren(false); setIsLoad(false) }} parent={content} />}
        </button>
      </div>
      {
        content.children_id.length > 0 && (
          <ContentChildrenList contents={content.children_id} parentId={content._id} setIsLoad={setIsLoad} setContentChange={setContentChange} />
        )
      }
    </>
  );
}
