import { Container } from './style';

const defaultColors = [
  '#FFFFFF',
  '#C0C0C0',
  '#808080',
  '#000000',
  '#FF0000',
  '#800000',
  '#FFFF00',
  '#808000',
  '#00FF00',
  '#008000',
  '#00FFFF',
  '#008080',
  '#0000FF',
  '#000080',
  '#FF00FF',
  '#800080',
];

export function ColorPicker({ callbackFunction, setIsFontColorOptionsOpen }) {

  return (
    <Container >
      <div className="defaultColors__container">
        {defaultColors?.map((color) => (
          <button
            onClick={() => callbackFunction(color)}
            style={{ backgroundColor: color }}
          ></button>
        ))}
      </div>
      <div className="customColor__container">
        <hr />
        <div>
          <h3>Outras</h3>
          <input
            type="color"
            onChange={(e) => callbackFunction(e.target.value)}
          />
        </div>
      </div>
    </Container>
  );
}
