import { ref } from "@firebase/storage";
import { useStorage, useStorageDownloadURL } from "reactfire";

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
};

export function HandleShowImage({ image, className, style }) {
  return (
    <img
      loading="lazy"
      style={style}
      className={className}
      src={`https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/o/${image.replace('images/', 'images%2F')}?alt=media`}
      alt={image}
    />
  );
}