import styled from "styled-components";

export const AccordionDiv = styled.div`

  .accordion{
    background-color: var(--hard-color) !important;
    margin-bottom: 3px !important;
    div.accordion-header{
      background-color: var(--hard-color) !important;
      .accordion-title{
        color: black;
      }

      .accordion-type-icon{
        display: none !important;
      }
  
      .accordion-open-close-indicator {
        color: black;
      }
    }
  }

.accordion-container {
  margin-bottom: 3px;
  .accordion{
    .accordion-header{
      .accordion-title{
        color: black;
      }
      .accordion-open-close-indicator{
        color: black;
      }
    }
  }
}

.accordion-section{
  .accordion-container{
    .accordion{
      border-radius: 0;
      .accordion-type-icon{
        display: none !important;
      }
    }
    &:first-child{
      .accordion{
        border-radius: 5px 5px 0 0;
      }
    }
    &:last-child{
      .accordion{
        border-radius: 0 0 5px 5px;
      }
  }
  }
}
`;
