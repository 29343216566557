import { useParams } from "react-router-dom";
import { BackButton } from "../../../components/atoms/BackButton";
import { SpecialtiesBasicInformation } from "../../../components/molecules/SpecialtiesBasicInformation";
import { SpecialtiesUpdateContainer } from "./style";
import api from "../../../services/axios"
import { useEffect, useState } from "react";

export function SpecialtiesUpdate() {
    const { id } = useParams();

    const [hasResponse, setHasResponse] = useState(null);

    useEffect(()=>{
      handleGetSpecialty();
    }, [])

    async function handleGetSpecialty() {
      const response = await api.get(`specialty/${id}`);
      setHasResponse(response.data);
    }

  return (
    <SpecialtiesUpdateContainer>
      <BackButton route="specialties" />
      {hasResponse && <SpecialtiesBasicInformation hasResponse={hasResponse} />}
    </SpecialtiesUpdateContainer>
  );
}
