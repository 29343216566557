import { useContext } from "react";
import {
	RichUtils,
	KeyBindingUtil,
	EditorState,
	CompositeDecorator
} from "draft-js";
import { ContextAplication } from '../../../../../context';

export const fontColorStrategy = (contentBlock, callback, contentState) => {
	contentBlock.findEntityRanges(character => {
		const entityKey = character.getEntity();
		return (
			entityKey !== null &&
			contentState.getEntity(entityKey).getType() === "font-color"
		);
	}, callback);
};

export const FontColor = props => {
	const { contentState, entityKey } = props;
	const data = contentState.getEntity(entityKey).getData();

	return (
		<span
			className="link"
      style={{color: data?.color}}
		>
			{props.children}
		</span>
	);
};


export const addFontColorPlugin = {
	decorators: [
		{
			strategy: fontColorStrategy,
			component: FontColor
		}
	]
};

