import { useFormik } from 'formik';
import { toast } from 'react-toastify';

import api from '../../../services/axios';
import { tabsStyles } from '../../../services/utils/tabsStyles';

import { TabsFormContainer } from './style';

export function TabsForm({ tab = null, afterSubmitFunction, closeModal }) {

  const formik = useFormik({
    initialValues: {
      class_css: tab?.class_css || "",
      is_treatment: tab?.is_treatment || false,
      title: tab?.title || "",
      title_html: tab?.title_html || ""
    },

    onSubmit: (values) => {
      try {
        const parseObject = {
          ...values, color: tabsStyles[values.class_css].hexdecimal_color
        }
        handleCreateOrUpdateTab(parseObject);
      } catch (error) {
        toast.error("Ocorreu um erro!", {
          theme: "colored",
          closeOnClick: true,
        })
        console.log(error)
      }
    }
  })

  async function handleCreateOrUpdateTab(object) {
    try {
      let response = null
      if (tab) {
        await api.put(`/tabs/${tab._id}`, object)
        closeModal();
        toast.success("Aba atualizada com sucesso!", {
          theme: "colored",
          closeOnClick: true,
        })
      } else {
        afterSubmitFunction(object)
      }

    } catch (error) {
      toast.error('Ocorreu um erro!', {
        theme: 'colored',
        closeOnClick: true,
      });
    }
  }

  return (
    <TabsFormContainer>
      <div className="tabs__header">
        <h3>Informações básicas</h3>
      </div>

      <form onSubmit={formik.handleSubmit} className="tabs__body">
        <div className="tabs__inputs">
          <label>
            <h3>Título:</h3>
            <input
              className="title__input"
              type="text"
              name="title"
              value={formik.values.title}
              onChange={formik.handleChange}
            />
          </label>

          <label>
            <h3>Título HTML:</h3>
            <input
              className="html__input"
              type="text"
              name="title_html"
              value={formik.values.title_html}
              onChange={formik.handleChange}
            />
          </label>

          <label>
            <h3>Estilo:</h3>
            <select
              className="style__select"
              name="class_css"
              value={formik.values.class_css}
              onChange={formik.handleChange}
            >
              <option value="">Selecione um estilo</option>
              {tabsStyles && Object.keys(tabsStyles).map(tabStyle =>
                <option value={tabStyle}>{tabsStyles[tabStyle].title}</option>
              )
              }
            </select>
          </label>
        </div>

        <div className="button__container">
          <button type="submit" className="save__button">Salvar</button>
        </div>
      </form>
    </TabsFormContainer>
  )
}