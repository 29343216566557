import React, { useState } from 'react';
import { useContext } from 'react';

import ModalImageComponent from '../../../../molecules/ModalImageComponent';
import { ContextAplication } from '../../../../../context';
import { HandleShowImage } from '../../../../../services/firebase/config';

import { Button, ScrollableImageContainer } from './style';

export const mediaBlockRenderer = (block) => {
  if (block.getType() === 'atomic') {
    return {
      component: Media,
      editable: true,
    };
  }

  return null;
};

const Image = (props) => {
  if (!!props.src) {
    return (
      <HandleShowImage image={props.src} style={{ height: '100px' }}/>
    );
  }
  return null;
};

const ScrollableImage = (props) => {

  if (!!props.src) {
    return (
      <ScrollableImageContainer>
        <h3>Imagem escrolável</h3>
        <HandleShowImage image={props.src} style={{ height: '100px' }}/>
      </ScrollableImageContainer>
    );
  }
  return null;
};

const Carousel = (props) => {
  const { handleSetNewEntity } = useContext(ContextAplication);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button
        className="carousel__button"
        onClick={() => setIsOpen(true)}
        imgarray={props.imgarray}
      >
        Carrossel de imagens
      </Button>
      {isOpen && (
        <ModalImageComponent
          closeModal={() => setIsOpen(false)}
          onAddImage={async (e) =>
            await handleSetNewEntity(e, props.entityKey, props.entityType)
          }
          isCarousel={true}
          initialImages={props.imgarray}
        />
      )}
    </>
  );
};

const Media = (props) => {
  const key = props.block.getEntityAt(0);
  if (!key) return null;
  const entity = props?.contentState?.getEntity(key);
  const data = entity.getData();
  const type = entity.getType();

  let media;

  if (type === 'image') {
    media = <Image src={data.src} />;
  }
  if (type === 'carousel') {
    media = (
      <Carousel
        imgarray={data.imgarray}
        entityKey={props.block.getEntityAt(0)}
        entityType={type}
      />
    );
  }
  if (type === 'scrollable-image') {
    media = (
      <ScrollableImage
        src={data.src}
      />
    );
  }

  return media;
};
