import { useEffect, useState } from 'react';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  IoIosArrowDropdownCircle,
  IoIosArrowDropupCircle,
} from 'react-icons/io';

import api from '../../../services/axios';

import { CardActionsSection } from '../CardActionsSection';
import { RoutineTabCardsHeader } from '../RoutineTabCardsHeader';

export function CardList({ selectedTab, setSelectedTab, routine_keywords = null, routine_title = '' }) {
  const [isCardsLoad, setIsCardsLoad] = useState(false);
  const [cards, setCards] = useState(null);
  const [changedPosition, setChangedPosition] = useState(false);

  const { id } = useParams();

  async function handleGetCards() {
    try {
      const response = await api.get(`routine-tabs/${selectedTab._id}`);

      setCards(response.data.routine_tabs_cards_id);
      setIsCardsLoad(true);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    handleGetCards();
  }, [selectedTab]);

  useEffect(() => {
    if (!isCardsLoad) {
      handleGetCards();
    }
  }, [isCardsLoad]);

  function handleValidateShowInIndex(cardPosition, state) {
    const newArr = [...cards];

    newArr[cardPosition - 1] = {
      ...newArr[cardPosition - 1],
      show_in_index: state,
    };

    setCards([...newArr]);
    setChangedPosition(true);
  }

  function changePosition(from, to) {
    const newArr = [...cards];

    newArr[from - 1] = { ...newArr[from - 1], position: to };
    newArr[to - 1] = { ...newArr[to - 1], position: from };
    newArr.sort((a, b) => a.position - b.position);

    setCards([...newArr]);
    setChangedPosition(true);
  }

  async function handlePositionChangeByDelete(deletedCard) {
    let aux = [...cards];
    _.remove(aux, deletedCard);

    const aux2 = aux.map((card, idx) => {
      if (card.position !== idx) {
        return { ...card, position: idx + 1 };
      }
    });

    await handleSubmitTabCards(aux2);

    setIsCardsLoad(false);
  }

  async function handleSubmitTabCards(cardsArray) {
    try {
      let parsedCards = [];
      if (cardsArray) {
        parsedCards = cardsArray.map((card) => {
          return {
            _id: card._id,
            position: card.position,
            show_in_index: card.show_in_index,
          };
        });
      } else {
        parsedCards = cards.map((card) => {
          return {
            _id: card._id,
            position: card.position,
            show_in_index: card.show_in_index,
          };
        });
      }
      await api.put('/routine-tab-cards', { routineTabCards: parsedCards });
      setChangedPosition(false);
      toast.success('Alteração realizada com sucesso!', {
        theme: 'colored',
      });
    } catch (error) {
      toast.error('Ocorreu um erro!', {
        theme: 'colored',
        closeOnClick: true,
      });
      console.log(error);
    }
  }

  return (
    <div className="selectedTab__container">
      {cards && isCardsLoad ? (
        <>
          <RoutineTabCardsHeader 
            tab={selectedTab} 
            setIsLoad={setIsCardsLoad} 
            routine_keywords={routine_keywords}
            routine_title={routine_title}
          />

          {cards.map((card) => (
            <div className="card">
              <section className="cardPosition">
                <button
                  disabled={card.position <= 1}
                  onClick={() =>
                    changePosition(card.position, card.position - 1)
                  }
                >
                  <IoIosArrowDropupCircle />
                </button>
                <button
                  disabled={card.position >= cards.length}
                  onClick={() =>
                    changePosition(card.position, card.position + 1)
                  }
                >
                  <IoIosArrowDropdownCircle />
                </button>
                <h1>
                  {card.position} - {card.card_id.title}
                </h1>
              </section>

              <CardActionsSection
                card={card}
                tabId={selectedTab}
                deleteCallback={handlePositionChangeByDelete}
                handleValidateShowInIndex={handleValidateShowInIndex}
                setIsLoad={setIsCardsLoad}
              />
            </div>
          ))}

          {changedPosition && (
            <button
              className="submit__button"
              type="button"
              onClick={() => handleSubmitTabCards()}
            >
              Salvar
            </button>
          )}
        </>
      ) : (
        <h1>Carregando...</h1>
      )}
    </div>
  );
}
