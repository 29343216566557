import styled from 'styled-components';

export const Container = styled.div `
max-height: 500px;
overflow-y: scroll;
padding-right: 10px;
::-webkit-scrollbar{
    width: 10px;
}
::-webkit-scrollbar-track{
    background-color: var(--primary);
    border-radius: 10px;
    
}
::-webkit-scrollbar-thumb{
    background-color: var(--content);
    border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover{
    background-color: var(--tertiary);
}

    .contentList{
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .contentList__contentAndButton {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
    }

    .upAndDown__container{
        display: flex;
        align-items: center;
        margin-right: 5px;
    }

    .upAndDown__button{
        background-color: transparent;
        border: none;
        font-size: 1.4rem;
        color: var(--soft-color);
        padding: 0;
        padding-right: 3px;
        &:hover{
            filter: brightness(0.8);
        }
        &:disabled{
            filter: brightness(0.5);
        }
    }

    .contentList__content {
        color: white;
        background-color: var(--secondary);
        width: 100%;
        margin: .5rem 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.5rem;
        transition: all linear .2s;
        border: 1px solid var(--primary);
        border-radius: 5px;

        &.active {
            border: 1px solid var(--background);
            background-color: var(--secondary);
        }
    }

    .contentList__options {
        display: flex;
        flex-direction: row;
    }

    .contentList__button{
        background-color: transparent;
        border: none;
        font-size: 1rem;
        &.add{
            color: var(--green);
        }
        &.remove{	
            color: var(--red);
        }
    }

    .contentList__check{
        width: 1rem;
        height: 0.8rem;
        &:hover{
            cursor: pointer;
        }
    }

    .savePosition{
        width: 100%;
        margin-top: 1rem;
        border: none;
        border-radius: 5px;
        background-color: var(--tertiary);
        color: white;
        padding: .5rem;
        font-size: 1.1rem;
        transition: all linear .3s;
        &:hover{
            filter: brightness(0.9);
        }
    }
`