import { CaroseulContainer } from "./style";

let htmlToReact = require("html-to-react").Parser;

export function Caroseul({content}) {
  const conteudo = new htmlToReact();

  return (
    <CaroseulContainer>
      <div className="caroseul">
        <div className="caroseul-items">
          {conteudo.parse(
            content?.text
          )}
        </div>
      </div>
    </CaroseulContainer>
  );
}
