import styled from 'styled-components';

export const RoutineTabCardsHeaderContainer = styled.div`

  .addNewCard__button {
    border: none;
    background-color: var(--tertiary);
    color: white;
    padding: 0.5rem;
    border-radius: 5px;
    &:hover {
          filter: brightness(0.9);
        }
  }
`