import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;

  .label {
    margin-bottom: 0;
  }

  .switch {
    margin: 0 0.5rem;
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;

    transform: scale(0.8);

    input { 
      opacity: 0;
      width: 0;
      height: 0;

      &:checked + .slider {
        background-color: #2196F3;
      }

      &:focus + .slider {
        box-shadow: 0 0 1px #2196F3;
      }

      &:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
      }
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: .4s;
      transition: .4s;
      border-radius: 34px;


      &:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
        border-radius: 50%;

      }
    }
  }

`;
