import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';

import api from '../../services/axios';
import { handleLogin } from '../../services/auth';
import logo from '../../assets/images/logo-white.svg';

import { LoginContainer } from './style';
import { useState } from 'react';
export function Login() {
  const history = useHistory();

  const [showPassword, setShowPassword] = useState(false)

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },

    onSubmit: async (values) => {
      try {
        const response = await api.post('login', values);

        handleLogin(response.data);

        history.push('/');
      } catch (error) {
        toast.error("Ocorreu um erro!", {
          theme: "colored",
          closeOnClick: true,
        })
        console.log(error);
      }
    }
  })

  return (
    <LoginContainer>
      <div className="content">
        <div className="logo__box">
          <img src={logo} alt="Logo BlackBook" />
        </div>

        <form className="input__box" onSubmit={formik.handleSubmit}>
          <label>
            <h3>E-mail</h3>
            <input type="text" onChange={formik.handleChange} name="email" />
          </label>
          <label>
            <h3>Senha</h3>
            <input type={showPassword ? 'text' : 'password'} onChange={formik.handleChange} name="password" />
          </label>
          <label className="showPassword__input">
            <input type="checkbox" onChange={() => setShowPassword(!showPassword)} name="showPassword" checked={showPassword} />
            Mostrar senha
          </label>
          <button type='submit'>Login</button>
        </form>
      </div>
    </LoginContainer>
  )
}
