import { getStorage, ref, uploadBytesResumable } from 'firebase/storage';
import { StorageProvider, useFirebaseApp, useStorage } from 'reactfire';

import { toast } from 'react-toastify';
import api from '../../../services/axios';
import { useState } from 'react';

export function ImageDashboardUploadComponent({ image, is_hd = false }) {
  const storage = useStorage();
  const [loading, setLoading] = useState(false);

  async function uploadImage(img, name) {
    setLoading(true);
    try {
      const newRef = ref(
        storage,
        is_hd ? `imagesHD/${name}` : `images/${name}`
      );

      await uploadBytesResumable(newRef, img);

      if (is_hd) {
        await api.put('/image/update-by-name', {
          imageName: name,
          params: { has_hd: is_hd },
          isUpdate: false,
        });
      }
      // handleGetImages(false);
      // setSuccessModalOpen(true);
      // setTimeout(() => {
      //   setSuccessModalData({ name });
      // }, 1000);
      toast.success(`Imagem ${is_hd ? 'HD' : 'normal'} salva com sucesso!`);
    } catch (error) {
      toast.error('Ocorreu um erro!', {
        theme: 'colored',
        closeOnClick: true,
      });
      console.log(error);
    }
    setLoading(false);
  }

  return (
    <>
      <label for={`file-input${is_hd ? '-hd' : ''}-${image.name}`}>
        {loading ? (
          <div
            className='loading__container'
            >fazendo upload...</div>
        ) : (
          <img
            src={`https://firebasestorage.googleapis.com/v0/b/${
              process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
            }/o/images${is_hd ? 'HD' : ''}%2F${image.name}?alt=media&${new Date().getTime()}`}
          />
        )}
      </label>

      <input
        id={`file-input${is_hd ? '-hd' : ''}-${image.name}`}
        type="file"
        accept="image/*"
        onChange={(e) => uploadImage(e.target.files[0], image.name, is_hd)}
      />
    </>
  );
}
