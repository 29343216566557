import { Link, useHistory } from "react-router-dom"
import { HeaderContainer } from "./style"
import { FaSignOutAlt } from "react-icons/fa"
import logo from '../../../assets/images/logo.png'

export function Header () {
    const history = useHistory()

    return(
        <HeaderContainer>
            <div className="header">
                <img src={logo} alt="Logo BlackBook" className="header__logo" onClick={() => history.push('/')}/>
                <ul className="header__nav">
                    <li className="nav__item">
                        <Link className="nav__link"><FaSignOutAlt /></Link>
                    </li>
                </ul>
            </div>
        </HeaderContainer>
    )
}