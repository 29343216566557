import React, { useEffect } from "react";
import api from "../../../services/axios";
import { FiTrash } from "react-icons/fi";
import { updateResumeCardKeywords } from "../../../services/utils/updateResumeCardKeywords";



async function removeKeywordFromResumeCards(keyword_list, resumeTabCards) {
    resumeTabCards?.cards_ids.map(async (card) => {
      await updateResumeCardKeywords(
        card, 
        keyword_list, 
        resumeTabCards.routineTitle
      )
    });
} 

export function KeywordsList({ keywordsList, setKeywordsList, parentId, route, keywordArray, resumeTabCardsIds = null }) {

  async function handleRemoveKeyword(keywordId) {
    try {
      if (window.confirm('Deseja deletar essa palavra-chave?')) {
        const response = await api.put(`${route}/remove-keywords/${keywordId}/${parentId}`)
        const resumeTabCards = await resumeTabCardsIds?.();
        if(resumeTabCards?.cards_ids) {
          await removeKeywordFromResumeCards(response.data, resumeTabCards);
        }
        setKeywordsList(response.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  async function handleGetKeywords() {
    try {
      const response = await api.post('/keyword-by-array', { keywordIds: keywordArray })
      setKeywordsList(response.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    handleGetKeywords();
  }, [])

  return (
    <>
      {keywordsList.length ? (
        <div className={"keywordsList"}>
          <ul>
            {keywordsList && keywordsList.map((keyword) => (
              keyword.new ?
                <li className="newKeyword">{keyword.title}
                  <button
                    className="removeKeyword__button"
                    onClick={() => handleRemoveKeyword(keyword._id)}
                  >
                    <FiTrash />
                  </button>
                </li>
                :
                <li>{keyword.title}
                  <button
                    className="removeKeyword__button"
                    onClick={() => handleRemoveKeyword(keyword._id)}
                  >
                    <FiTrash />
                  </button>
                </li>
            ))}
          </ul>
        </div>
      ) : (
        <h3 className="emptyKeyword__list">Não há palavras-chave selecionadas.</h3>
      )}
    </>
  );
}