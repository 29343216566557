import styled from 'styled-components';

export const UploadImageContainer = styled.div`
  height: 100vh;
  width: 100%;
  position: fixed;
  inset: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);

  .content {
    min-width: 680px;
    background-color: var(--background);
    max-height: 95vh;
    padding: 1rem;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .close__button {
        background-color: unset;
        border: none;
        font-size: 20px;
        color: white;
        &:hover {
          filter: brightness(0.8);
        }
      }
    }

    .input__box {
      display: flex;
      flex-direction: column;

      .input__title {
        width: auto;
        margin-top: 10px;
        margin-bottom: 10px;
        border: 1px solid var(--content);
        border-radius: 5px;
        padding: 0.5rem;
        background-color: var(--background);
        color: white;
        &:hover {
          border: 1px solid var(--content);
        }
        &:focus {
          outline: none;
          border: 1px solid var(--content);
        }
      }
    }

    .save__button {
      color: white;
      border: none;
      border-radius: 5px;
      background-color: var(--tertiary);
      padding: 0.5rem;
      margin-top: 10px;
      &:hover {
        filter: brightness(0.9);
      }
    }
  }
`