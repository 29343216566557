import Lottie from 'react-lottie';

import loadingCircle from '../../../assets/lotties/loading-circle.json';


export function LoadingComponent() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingCircle,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
      <Lottie options={defaultOptions} height={'100%'} width={'100%'} />
  );
}
