import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  inset: 0;
  z-index: 1000;

  height: 100vh;
  width: 100%;

  background-color: rgba(0, 0, 0, 0.5);

  display: flex;
  justify-content: center;
  align-items: center;

  .link__modal {
    background-color: var(--content);
    padding: 2rem;
    min-width: 380px;

    .modal__header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;

      h3 {
        line-height: 1.5rem;
        span {
          font-size: 1.3rem;
          font-weight: bold;
        }
      }

      button {
        background-color: unset;
        border: none;
        color: white;
        font-size: 1.5rem;
        &:hover {
          filter: brightness(0.8);
        }
      }
    }

    .modal__body {

      .modalType__selector {
        display: flex;
        justify-content: center;

        button {
          border: none;
          background: transparent;
          color: white;
          font-size: 1.2rem;
          margin-bottom: 2px;

          display: flex;
          justify-content: center;
          align-items: flex-end;

          &.active {
            ::after {
              content: '';
              display: block;
              position: absolute;
              width: 2rem;
              height: 0.1rem;
              background-color: var(--tertiary);
            }
          }

          &:hover {
            ::after {
              content: '';
              display: block;
              position: absolute;
              width: 2rem;
              height: 0.1rem;
              background-color: var(--secondary);
            }
          }
        }
      }
    }

    .externalLink__input {
      color: white;
      background-color: unset;
      border: none;
      border-bottom: 1px solid var(--green);
      width: 100%;
      margin-top: 20px;
      &:hover {
        border-bottom: 1px solid var(--secondary);
      }
      &:focus {
        outline: none;
      }
    }

    .button__container {
      display: flex;
      justify-content: space-between;
      margin-top: 2rem;

      button {
        color: white;
        border: none;
        border-radius: 5px;
        background-color: var(--tertiary);
        padding: 0.5rem;
        &:hover {
          filter: brightness(0.9);
        }

        &.save__button {
          justify-self: flex-end;
        }

        &.delete__button {
          background-color: transparent;
          border: 1px solid var(--red);

          &:hover {
            filter:none;
            background-color: var(--red);
          }
        }
      }
    }
  }
`;

export const SelectComponentStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: 'unset',
    color: 'white',
    border: 'none',
    borderRadius: '0',
    borderBottom: '1px solid var(--green)',

    ':hover': {
      border: 'none',
      boxShadow: 'none',
      backgroundColor: 'var(--primary)'
    },

    ':focus': {
      border: 'none',
      boxShadow: 'none',
    },

    ':active': {
      border: 'none',
      boxShadow: 'none',
    },

    ':focus-visible': {
      border: 'none',
      boxShadow: 'none',
    },

    ':focus-within': {
      border: 'none',
      boxShadow: 'none',
    }
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: 'white',
      color: 'black',
      cursor: isDisabled ? 'not-allowed' : 'default',
      borderRadius: '0',

      ':active': {
        ...styles[':active'],
        backgroundColor: 'var(--silver)',
      },

      ':hover': {
        ...styles[':hover'],
        backgroundColor: 'var(--silver)',
      },
    };
  },
  placeholder: (styles) => ({ ...styles, color: '#fff' }),
  input: (styles) => ({ ...styles, color: '#fff' }),
  singleValue: (styles, { data }) => ({ ...styles, color: '#fff' }),
};
