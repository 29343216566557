import styled from 'styled-components';

export const TabsFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .tabs__header {
    margin-top: 15px;
    background-color: var(--content);
    padding: 1rem;
  }

  .tabs__body {
    .tabs__inputs {
      display: flex;
      flex-direction: column;

      h3 {
        margin-bottom: 5px;
      }

      .title__input {
        color: white;
        width: 100%;
        height: 25px;
        margin-bottom: 10px;
        border-radius: 5px;
        border: 1px solid var(--background);
        background-color: var(--secondary);
        &:hover {
          border: 1px solid var(--background)
        }
        &:focus {
          outline: none;
          border: 1px solid var(--background);
        }
      }

      .html__input {
        color: white;
        width: 100%;
        height: 25px;
        margin-bottom: 10px;
        border-radius: 5px;
        border: 1px solid var(--background);
        background-color: var(--secondary);
        &:hover {
          border: 1px solid var(--background)
        }
        &:focus {
          outline: none;
          border: 1px solid var(--background);
        }
      }

      .style__select {
        width: 100%;
        height: 25px;
        margin-bottom: 10px;
        border-radius: 5px;
        color: white;
        border: 1px solid var(--background);
        background-color: var(--secondary);
        &:hover {
          border: 1px solid var(--background)
        }
        &:focus {
          outline: none;
          border: 1px solid var(--background);
        }

        option {
          background-color: white;
          color: black;
        }
      }
    }

    display: flex;
    flex-direction: column;
    background-color: var(--secondary);
    padding: 1rem;

    .button__container {
      display: flex;
      width: 100%;
      margin-top: 5px;

      .save__button {
        background-color: var(--tertiary);
        color: white;
        border: none;
        border-radius: 5px;
        padding: 0.6rem;
        margin-right: 5px;
        &:hover {
          filter: brightness(0.9);
        }
      }
    }
  }
`