import { useState } from 'react';
import { FiTrash } from 'react-icons/fi';
import { MdModeEdit } from 'react-icons/md';
import { CardFormComponent } from '../CardFormComponent';
import api from '../../../services/axios';
import { useParams } from 'react-router-dom';
import { CardActionsSectionContainer } from './style';
import { toast } from 'react-toastify';

export function CardActionsSection({ card, tab, handleValidateShowInIndex, setIsLoad, deleteCallback }) {
  const [editCardIsOpen, setEditCardIsOpen] = useState(false);
  const { id } = useParams();


  async function handleRemoveCard(card) {
    try {
      if (window.confirm('Deseja deletar?')) {
        await api.delete(`card/${card._id}/${id}`);
        deleteCallback(card)
        toast.success("Card deletado com sucesso!", {
          theme: "colored",
          closeOnClick: true,
        })
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <CardActionsSectionContainer>
      <section className="card__actions">
        <input
          type="checkbox"
          checked={card.show_in_index}
          onChange={(e) =>
            handleValidateShowInIndex(card.position, e.target.checked)
          }
        />
        <button
          className="edit__button"
          onClick={() => setEditCardIsOpen(true)}>
          <MdModeEdit />
        </button>
        <button
          className="delete__button"
          onClick={() => handleRemoveCard(card)}>
          <FiTrash />
        </button>

      </section>
      {editCardIsOpen && (
        <CardFormComponent
          creation={false}
          closeModal={() => { setEditCardIsOpen(false); setIsLoad(false) }}
          card={card}
          tab={tab}
        />
      )}
    </CardActionsSectionContainer>
  );
}
