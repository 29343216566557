import { useEffect, useState } from 'react';

import LinksTable from '../../atoms/LinksTable';
import ListLinkComponent from '../ListLinkComponent';

import { LinksDiv } from './style';

export function RoutineLinks({ links, routineId }) {
  const [parsedLinks, setParsedLinks] = useState({
    pendingLinks: [],
    doneLinks: [],
  });

  function handleParseLinks() {
    let done = [];
    let pending = [];
    links.map((link) => {
      if (link.type === 'R') {
        if (link.routine_id) {
          done.push(link);
        } else {
          pending.push(link);
        }
      } else {
        if (link.therapeutic_group_id) {
          done.push(link);
        } else {
          pending.push(link);
        }
      }
    });

    setParsedLinks({ pendingLinks: pending, doneLinks: done });
  }

  useEffect(() => {
    if (links) {
      handleParseLinks();
    }
  }, []);

  return (
    <LinksDiv>
      <div className="linksHeader">
        <h1 className="linksHeader__title">Links</h1>
      </div>
      {links &&
        <div className="linksTables">
          <div id="doneLinks">
            <h3 className="linksTables__title">Links feitos: </h3>
            <LinksTable data={parsedLinks.doneLinks} routineId={routineId} />
          </div>
          <div id="pendingLinks">
            <h3 className="linksTables__title">Links pendentes: </h3>
            <LinksTable data={parsedLinks.pendingLinks} routineId={routineId} />
          </div>
          <div id="pointingLinks">
            <h3 className="linksTables__title">Links que apontam para essa rotina: </h3>
            <ListLinkComponent />
          </div>
        </div>
      }
    </LinksDiv>
  );
}
