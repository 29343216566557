import { useState } from 'react';
import { KeywordInputComponent } from './KeywordInputComponent';
import { KeywordsList } from './KeywordsList';
import { KeywordsContainer } from './style';

export function Keywords({ route, parentId, keywordArray, resumeTabCardsIds = null, callbackFunction = () => {}}) {
  const [keywordsList, setKeywordsList] = useState([]);

  return (
    <KeywordsContainer id="keywords">
      <div className="accordion__header">
        <h2>Palavras-chave</h2>
      </div>
      <div className="accordion__body">
        <KeywordInputComponent
          route={route}
          parentId={parentId}
          setKeywordsList={setKeywordsList}
          resumeTabCardsIds={resumeTabCardsIds}
          callbackFunction={callbackFunction}
        />

        <KeywordsList
          keywordArray={keywordArray}
          keywordsList={keywordsList}
          setKeywordsList={setKeywordsList}
          parentId={parentId}
          route={route}

          resumeTabCardsIds={resumeTabCardsIds}
        />
      </div>
    </KeywordsContainer>
  );
}