import { useEffect, useState } from 'react';
import { DataTable } from '../../components/molecules/DataTable';
import { PageHeader } from '../../components/molecules/PageHeader';
import api from '../../services/axios';
import { SpecialtiesContainer } from './style';
import { toast } from 'react-toastify';

export function Specialties() {
  const [specialties, setSpecialties] = useState(null);
  const [filter, setfilter] = useState(null);
  const [filteredSpecialties, setFilteredSpecialty] = useState(null);

  async function handleGetSpecialties() {
    const response = await api.get('specialty');
    setSpecialties(response.data);
    setFilteredSpecialty(response.data);
  }

  async function handleDeleteSpecialty(id) {
    try {
      if (window.confirm("Deseja deletar essa especialidade?")) {
        await api.delete(`/specialty/${id}`)
        handleGetSpecialties()
        toast.success("Especialidade deletada com sucesso!", {
          theme: "colored",
          closeOnClick: true,
        })
      }
    } catch (error) {
      toast.error("Ocorreu um erro!", {
        theme: "colored",
        closeOnClick: true,
      })
      console.log(error)
    }
  }

  useEffect(() => {
    handleGetSpecialties();
  }, []);

  useEffect(() => {
    filterSpecialties();
  }, [filter])

  const filterSpecialties = () => {
    if (filter !== '' && specialties) {
      const result = specialties.filter(({ title }) =>
        title.toLowerCase().includes(filter?.toLowerCase())
      );
      if (filter?.length > 3 && result !== specialties) {
        setFilteredSpecialty(result);
        return
      }
    }
    setFilteredSpecialty(specialties);
  }

  return (
    <SpecialtiesContainer>
      <PageHeader
        filter={filter}
        setFilter={setfilter}
        title="Especialidades"
        route="specialties"
      />

      <div className="table__container">
        <DataTable data={filteredSpecialties} route="specialties" deleteFunction={handleDeleteSpecialty} />
      </div>
    </SpecialtiesContainer>
  );
}
