import styled from 'styled-components';

export const RoutineTabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--content);
  border-radius: 5px;
  margin-top: 1rem;

  .header {
    border-radius: 5px 5px 0 0;
    background-color: var(--secondary);
    padding: 1rem;

    .header__title {
      font-size: 1.3rem;
    }
  }

  .content {
    .buttons__content {
      .addTab__button {
        border: none;
        border-radius: 5px;
        margin: 5px;
        padding: 0.5rem;
        color: white;
        background-color: var(--tertiary);
        &:hover {
          filter: brightness(0.9);
        }
      }
    }
  }

  .tabs__container {
    padding: 1rem;
    ul {
      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid var(--secondary);
        padding: 0 0.5rem;

        .content__li {
          display: flex;
          align-items: center;

          button {
            height: 2rem;
            border: none;
            background-color: unset;
            color: var(--soft-color);
            font-size: 1.3rem;
            cursor: pointer;
            svg {
              vertical-align: middle;
            }
            &:hover {
              filter: brightness(0.8);
            }
            &:disabled {
              color: transparent;
              cursor: default;
            }
          }

          h1 {
            margin-left: 0.5rem;
          }
        }

        .editTab__button {
          border: none;
          border-radius: 5px;
          padding: 0.4rem;
          background-color: unset;
          color: var(--tertiary);
          font-size: 20px;

          &.resume__tab {
            display: none;
          }

          &:hover {
          filter: brightness(0.8);
        }
      }

        .removeTab__button {
            border: none;
            border-radius: 5px;
            padding: 0.4rem;
            background-color: unset;
            color: var(--red);
            font-size: 20px;

            &.resume__tab {
              display: none;
            }

            &:hover {
            filter: brightness(0.8);
          }
        }
      }
    }
  }

  .submit__button {
    background-color: var(--tertiary);
    color: white;
    border: none;
    border-radius: 5px;
    padding: 0.6rem;
    margin: 1rem;
    align-self: flex-end;
    &:hover {
      filter: brightness(0.9);
    }
  }
`;
