import { useContext } from 'react';
import {
  RichUtils,
  KeyBindingUtil,
  EditorState,
  CompositeDecorator,
} from 'draft-js';
import { ContextAplication } from '../../../../../context';

export const linkStrategy = (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === 'LINK'
    );
  }, callback);
};

export const Link = (props) => {
  const { setIsModalLinkOpen, setLinkModalInfos } =
    useContext(ContextAplication);
  const { contentState, entityKey } = props;
  const data = contentState.getEntity(entityKey).getData();

  function handleOpenModal() {
    setLinkModalInfos({
      link_id: data.href,
      linkEntityKey: entityKey,
      closeModal: setIsModalLinkOpen,
      textSelected: props.decoratedText,
      isExternalLink: data?.isExternalLink,
      isCalculator: data?.isCalculator,
    });
    setIsModalLinkOpen(true);
  }
  return (
    <a
      onClick={() => handleOpenModal()}
      className="link"
      href={data.href}
      rel="noopener noreferrer"
      target="_blank"
    >
      {props.children}
    </a>
  );
};

export const addLinkPlugin = {
  decorators: [
    {
      strategy: linkStrategy,
      component: Link,
    },
  ],
};
