import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1rem;

  .specialties__header {
    border-radius: 5px 5px 0 0;
    margin-top: 15px;
    background-color: var(--content);
    padding: 1rem;
  }

  .specialties__body {
    border-radius: 0 0 5px 5px;
    .specialties__inputs {
      display: flex;
      flex-direction: column;

      h3 {
        margin-bottom: 5px;
      }

      .title__input {
        color: white;
        width: 100%;
        height: 25px;
        margin-bottom: 10px;
        border-radius: 5px;
        border: 1px solid var(--content);
        background-color: var(--secondary);
        &:hover {
          border: 1px solid var(--content);
        }
        &:focus {
          outline: none;
          border: 1px solid var(--content);
        }
      }
    }

    display: flex;
    flex-direction: column;
    background-color: var(--secondary);
    padding: 1rem;

    .button__container {
      display: flex;
      width: 100%;
      margin-top: 5px;

      .save__button {
        color: white;
        background-color: var(--tertiary);
        border: none;
        border-radius: 5px;
        padding: 0.6rem;
        margin-right: 5px;
        &:hover {
          filter: brightness(0.9);
        }
      }
    }
  }
`;
