import { useHistory } from 'react-router-dom';
import { PageHeaderContainer } from './style';

export function PageHeader({ title, route, filter, setFilter, children }) {
  const history = useHistory();

  return (
    <PageHeaderContainer>
      <h2>{title}</h2>
      {route !== 'pendinglinks' && (
        <button
          className="header__button"
          onClick={() => history.push(`${route}/create`)}
        >
          Criar
        </button>
      )}
      <div className="input__container">
        <label>
          Digite sua busca:
          <input
            name="header__input"
            type="text"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          />
        </label>
        {children}
      </div>
    </PageHeaderContainer>
  );
}
