import styled from 'styled-components';

export const TabsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  .table__container {
    width: 60vw;;
    padding: 1rem;
    background-color: var(--content);

  }
`;
