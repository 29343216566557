import { PaginationItemComponent } from "./style";

export function PaginationItem({
  isCurrent = false,
  number,
  onPageChange
}) {
  return (
    <PaginationItemComponent
      isCurrent={isCurrent}
      onClick={() => onPageChange(number)}
    >
      {number}
    </PaginationItemComponent>
  );
}