import styled from 'styled-components';

export const Table = styled.table`
  width: 100%;

  .update__button {
    color: var(--background);
    background-color: var(--tertiary);
    color: white;
    border: none;
    height: 1.5rem;
    border-radius: 30px;
    margin-right: 5px;
    &:hover {
      filter: brightness(0.9);
    }
  }

  .delete__button {
    color: var(--background);
    background-color: var(--red);
    color: white;
    border: none;
    height: 1.5rem;
    border-radius: 30px;
    margin-right: 5px;
    &:hover {
      filter: brightness(0.9);
    }
  }

  .showLink__button {
    border: none;
    background-color: unset;
    font-size: 1.2rem;
    color: var(--soft-color);
    &:hover {
      filter: brightness(0.8);
    }
  }

  thead {
    th {
      text-align: initial;
      padding: 1rem 0;
      &:last-child {
        text-align: end;
      }
    }
  }

  tr {
    height: 2.5rem;
    border-bottom: 1px solid var(--background);
  }
  
  td {
    vertical-align: middle;
    text-align: initial;
    &:nth-child(2) {
      text-align: end;
    }
    &:last-child {
      text-align: end;
    }
  }
`;
