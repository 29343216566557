import styled from 'styled-components';

export const RoutineSpecialtiesContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--content);
  border-radius: 5px;
  margin-top: 1rem;

  .header {
    border-radius: 5px 5px 0 0;
    background-color: var(--primary);
    padding: 1rem;

    .header__title {
      font-size: 1.3rem;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    
    .select__container {
      width: 100%;
      display: flex;

      label { 
        width: 100%;
        margin-right: 1rem;
      }

      button {
        font-size: 20px;
        border: none;
        background-color: unset;
        color: var(--green);
        align-self: flex-end;

        svg {
          border-radius: 25px;
          transition: all 0.2s;

          &:hover {
            background-color: var(--content);
            filter: brightness(0.8);
          }
        }
      }
    }

    .selectedSpecialties__container {
      margin-top: 1rem;
      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 3rem;
        border-bottom: 1px solid var(--primary);
        padding: 0 0.2rem;

        button {
          height: 3rem;

          border: none;
          font-size: 20px;
          background-color: unset;

          color: var(--red);

          &:hover {
            filter: brightness(0.8);
          }
        }
        
      }

    }
  }
`;

export const SelectComponentStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: 'var(--primary)',
    color: 'white',
    border: 'none',
    borderRadius: '3px',
    marginTop: '5px',

    ':hover': {
      border: 'none',
      boxShadow: 'none',
      backgroundColor: 'var(--secondary)'
    },

    ':focus': {
      border: 'none',
      boxShadow: 'none',
    },

    ':active': {
      border: 'none',
      boxShadow: 'none',
    },

    ':focus-visible': {
      border: 'none',
      boxShadow: 'none',
    },

    ':focus-within': {
      border: 'none',
      boxShadow: 'none',
    }
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: 'white',
      color: 'black',
      cursor: isDisabled ? 'not-allowed' : 'default',
      borderRadius: '0',

      ':active': {
        ...styles[':active'],
        backgroundColor: 'var(--silver)',
      },

      ':hover': {
        ...styles[':hover'],
        backgroundColor: 'var(--silver)',
      },
    };
  },
  placeholder: (styles) => ({ ...styles, color: 'var(--silver)' }),
  singleValue: (styles, { data }) => ({ ...styles, color: 'white' }),
};