import { useState } from 'react';
import { RoutineTabCardsContainer } from './style';
import { CardList } from '../../molecules/CardList';

export function RoutineTabCards({ tabs, routine_keywords, routine_title }) {
  const [selectedTab, setSelectedTab] = useState(null);

  function handleSelectTab(id) {
    const aux = tabs.find((t) => t._id === id);

    setSelectedTab(aux);
  }

  return (
    <>
      <RoutineTabCardsContainer id="cards">
        <div className="header">
          <h3 className="header__title">Cards</h3>
        </div>
        <div className="content">
          <select
            className="tab__select"
            onChange={(e) => handleSelectTab(e.target.value)}
          >
            <option value="">Selecione uma aba</option>
            {tabs?.map((tab) => (
              <option value={tab._id}>{tab.tab_id.title}</option>
            ))}
          </select>

          {selectedTab && (
            <CardList
              selectedTab={selectedTab}
              setSelectedTab={(e) => setSelectedTab(e)}
              routine_keywords={routine_keywords} 
              routine_title={routine_title}
            />
          )}
        </div>
      </RoutineTabCardsContainer>
    </>
  );
}
