import { useState } from 'react';

import { ref, uploadBytesResumable } from 'firebase/storage';
import { useStorage } from 'reactfire';
import { toast } from 'react-toastify';
import { RiCloseFill } from 'react-icons/ri';

import { UploadImageContainer } from './style';
import api from '../../../services/axios';

export default function UploadImage({ closeModal }) {
  const storage = useStorage();

  const [fileImage, setFileImage] = useState({
    name: '',
    file: null,
  });

  async function uploadImage() {
    try {
      const parsedImgName = fileImage.name
      .normalize('NFD')
      .replace(/([\u0300-\u036f]|[^0-9a-zA-Z.])/g, '')
      
      const newRef = ref(
        storage,
        `images/${parsedImgName}`
      );
      
      await api.put('/image/update-by-name', {imageName: parsedImgName})
      
      uploadBytesResumable(newRef, fileImage);

      toast.success('Imagem adicionada com sucesso!', {
        theme: 'colored',
        closeOnClick: true,
      });
    } catch (error) {
      toast.error('Ocorreu um erro!', {
        theme: 'colored',
        closeOnClick: true,
      });
      console.log(error);
    }
    closeModal();
  }

  return (
    <UploadImageContainer>
      <div className="content">
        <div className="header">
          <h3> Título:</h3>
          <button className="close__button" onClick={closeModal}>
            <RiCloseFill />
          </button>
        </div>

        <div className="input__box">
          <input
            type="file"
            accept="image/*"
            className="input__image"
            onChange={(e) => setFileImage(e.target.files[0])}
          />
        </div>
        <div>
          <button
            className="save__button"
            type="button"
            onClick={() => uploadImage()}
          >
            Salvar
          </button>
        </div>
      </div>
    </UploadImageContainer>
  );
}
