import { TabsForm } from "../TabsForm/Index"
import { ModalTabsFormContainer } from "./style"
import { RiCloseFill } from 'react-icons/ri';
import api from "../../../services/axios";
import { useState, useEffect } from 'react';

export function ModalTabsForm({ closeModal, tab, setIsTabsLoad}) {
  const [getTab, setGetTab] = useState(null);

  async function getOneTab() {
    const response = await api.get(`/tab/${tab?._id}`)
    setGetTab(response.data);
  }

  function handleCloseModal() {
    closeModal(false);
    setIsTabsLoad(false);
  }

  useEffect(() => {
    getOneTab();
  }, [])

  return (
    <ModalTabsFormContainer>
      <div className="content">
        <section>
          <div className="header">
            <h3>Adicionar Aba Customizada</h3>
            <button
              className="close__button"
              onClick={() => closeModal(false)}
            >
              <RiCloseFill />
            </button>
          </div>
          {getTab &&
            <TabsForm tab={getTab} closeModal={handleCloseModal}/>
          }
        </section>
      </div>
    </ModalTabsFormContainer>
  )
}
