import { SingleAccordion } from './style';
import { FiChevronDown } from 'react-icons/fi';
import { useState } from 'react';
let htmlToReact = require('html-to-react').Parser;

export function Accordion({ content, isOpen, type }) {
  const conteudo = new htmlToReact();
  const [isContentOpen, setIsContentOpen] = useState(isOpen);

  function openAccordion() {
    setIsContentOpen(!isContentOpen);
  }

  return (
    <SingleAccordion>
      <div className="accordion">
        <div className="accordion-header">
          <div className={`accordion-type-icon ${type}`} />
          <h1 className="accordion-title">{content.title}</h1>
          <div
            className="accordion-open-close-indicator"
            onClick={() => openAccordion()}
          >
            <FiChevronDown />
          </div>
        </div>
        <div className={`accordion-body ${isContentOpen ? '' : 'closed'}`}>
          {conteudo.parse(
            content?.text?.replaceAll(
              '<img src="',
              '<img class="image-full-width" src="'
            )
          )}
        </div>
      </div>
    </SingleAccordion>
  );
}
