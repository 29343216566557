import { Header } from "../../organisms/Header";
import { Sidebar } from "../../organisms/Sidebar";
import { Container } from "./style";

export function DefaultTemplate({children}) {
  return (
    <Container>
      <Header/>
      <Sidebar />
      <div style={{marginTop: "50px", marginLeft: "200px"}}>

      {children}
      </div>
    </Container>
  )
}
