import styled from 'styled-components';

export const RoutineBasicInformationFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--content);
  border-radius: 5px;
  margin-top: 20px;

  .header {
    border-radius: 5px 5px 0 0;
    background-color: var(--primary);
    padding: 1rem;

    .header__title {
      font-size: 1.3rem;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    
    .content {
      display: flex;
      
      @media screen and (max-width: 850px){
        flex-wrap: wrap;
        
      }

      img {
        margin-top: 15px;
        margin-right: 15px;
      }

      .inputs__box {
        margin-left: 10px;
        width: 100%;
        padding: 0.5rem;

        label {
          .input__title {
            margin-top: 5px;
            margin-bottom: 5px;
            height: 1.5rem;
            width: 100%;
            border: 1px solid var(--primary);
            border-radius: 5px;
            background-color: var(--content);
            color: white;
            &:hover {
              border: 1px solid var(--secondary);
            }
            &:focus {
              outline: none;
              border: 1px solid var(--secondary);
            }
          }
        }

        .subtitle__select {
          margin-top: 5px;
          margin-bottom: 10px;
          width: 100%;
          border: unset;
          border-bottom: 1px solid var(--secondary);
          background-color: var(--content);
          color: white;
          padding: 0.3rem;
          &:hover {
            border: unset;
            border-bottom: 1px solid var(--secondary);
          }
          &:focus {
            outline: none;
            border: unset;
            border-bottom: 1px solid var(--secondary);
          }

          option {
            background-color: white;
            color: black;
          }
        }
      }
    }
  }

    .image__box {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;

      .input__image::placeholder{
        display: none;
      }

      img {
        height: 250px;
        width: 250px;
      }
    }

  button {
    background-color: var(--tertiary);
    border: none;
    border-radius: 5px;
    padding: 0.6rem;
    margin-right: 5px;
    color: white;
    &:hover {
      filter: brightness(0.9);
    }
    align-self: flex-end;
  }
`