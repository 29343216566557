import React, { useState, useContext, useEffect } from 'react';

import { FaLink, FaImage, FaPalette } from 'react-icons/fa';

import { AtomicBlockUtils, EditorState, RichUtils, getDefaultKeyBinding } from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import { getEntityRange } from 'draftjs-utils';

import 'draft-js/dist/Draft.css';

import { ContextAplication } from '../../../context';

import { addLinkPlugin } from './Pluggins/Link/addLinkPlugin';
import { addFontColorPlugin } from './Pluggins/ColorPicker/addFontColorPluggin';
import { mediaBlockRenderer } from './Pluggins/Image/mediaBlockRenderer';
import { ColorPicker } from './Pluggins/ColorPicker';

import { inlineStylesTypes, blockStylesTypes } from './utils/toolbarOptions';
import { convertContentFromHTML } from './utils/convertContentFromHTML';
import { handleSubmitContent } from './utils/handleSubmitContent';

import ModalImageComponent from '../../molecules/ModalImageComponent';
import { ModalLinkComponent } from '../../molecules/ModalLinkComponent';

import { EditorContainer } from './style';

function EditorHtml({
  content,
  setArrayOfImagesBeforeEditing,
  setArrayOfLinksBeforeEditing,
  setContentTextState,
  routineId
}) {
  const {
    setEntityNewData,
    entityNewData,
    isModalLinkOpen,
    setIsModalLinkOpen,
  } = useContext(ContextAplication);

  const editorRef = React.useRef(null);

  const editorPlugins = [addLinkPlugin, addFontColorPlugin];

  const [imageModalInfos, setImageModalInfos] = useState({
    isOpen: false,
    type: null,
  });
  const [isFontColorOptionsOpen, setIsFontColorOptionsOpen] = useState(false);

  const [editorState, setEditorState] = useState(() =>
    content?.text ? createEdtitorWithContent() : EditorState.createEmpty()
  );

  function createEdtitorWithContent() {
    const { contentState, arrayOfImages, arrayOfLinksBeforeEditing } =
      convertContentFromHTML(content.text);

    setArrayOfImagesBeforeEditing(arrayOfImages);
    setArrayOfLinksBeforeEditing(arrayOfLinksBeforeEditing);

    return EditorState.createWithContent(contentState);
  }

  function onAddLink(link, isPendingLink) {
    const editorStateTemp = editorState;
    const selection = editorStateTemp.getSelection();

    let contentWithEntity = null;
    if (!link) {
      setEditorState(RichUtils.toggleLink(editorStateTemp, selection, null));
      return 'handled';
    }
    const currentContent = editorStateTemp.getCurrentContent();
    if (link?.isCalculator) {
      contentWithEntity = currentContent.createEntity("LINK", "MUTABLE", {
        href: `/app/${link.href}`,
        isExternalLink: false,
        isCalculator: true,
      });
    }
    else if (link?.isExternalLink || link?.isCalculator) {
      contentWithEntity = currentContent.createEntity('LINK', 'MUTABLE', {
        href: link.href,
        isExternalLink: true,
      });
    } else {
      contentWithEntity = currentContent.createEntity('LINK', 'MUTABLE', {
        href: `%7B%7Blink=%22${link}%22%7D%7D`,
        isPendingLink: isPendingLink
      });
    }
    const newEditorState = EditorState.push(
      editorStateTemp,
      contentWithEntity,
      'create-entity'
    );
    const entityKey = contentWithEntity.getLastCreatedEntityKey();
    const temp = RichUtils.toggleLink(newEditorState, selection, entityKey);
    setEditorState(temp);

    if (!link?.isExternalLink) {
      handleSubmitContent(content, temp, null, 'link');
    }
    setIsModalLinkOpen(false);
    setEntityNewData({ entityType: 'reload-editor' });
    return 'handled';
  }

  function onAddCarousel() {
    const editorStateTemp = editorState;
    const contentState = editorStateTemp.getCurrentContent();

    const contentStateWithEntity = contentState.createEntity(
      'carousel',
      'MUTABLE',
      { imgarray: '' }
    );

    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(
      editorState,
      {
        currentContent: contentStateWithEntity,
      },
      'create-entity'
    );

    setEditorState(
      AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' ')
    );
  }

  async function onAddImage(imgUrls, entityType) {
    if (!entityType && !imgUrls) {
      return null;
    }

    let editorTemp = editorState;
    imgUrls.map((urlValue) => {
      const contentState = editorTemp.getCurrentContent();

      let newEditorState;

      const contentStateWithEntity = contentState.createEntity(
        entityType,
        'MUTABLE',
        {
          src: urlValue,
        }
      );
      const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
      newEditorState = EditorState.push(
        editorTemp,
        contentStateWithEntity,
        'create-entity'
      );
      
      editorTemp = AtomicBlockUtils.insertAtomicBlock(
        newEditorState,
        entityKey,
        ' '
      );
    });
    setEditorState(editorTemp);
  }

  const blockStyleControl = (blockType) => {
    const newState = RichUtils.toggleBlockType(editorState, blockType.style);
    
    setEditorState(newState);
    setTimeout(() => {
      editorRef.current.focus();
    }, 100)
    
  };

  const onClickInlineStyle = (style) => {
    const aux = RichUtils.toggleInlineStyle(editorState, style);
    setEditorState(aux);
  };

  function onAddFontColor(color) {
    const editorStateTemp = editorState;
    const selection = editorStateTemp.getSelection();

    if (!color) {
      setEditorState(RichUtils.toggleLink(editorStateTemp, selection, null));
      return 'handled';
    }
    const currentContent = editorStateTemp.getCurrentContent();
    const contentWithEntity = currentContent.createEntity(
      'font-color',
      'MUTABLE',
      {
        color: color,
      }
    );
    const newEditorState = EditorState.push(
      editorStateTemp,
      contentWithEntity,
      'create-entity'
    );
    const entityKey = contentWithEntity.getLastCreatedEntityKey();
    const temp = RichUtils.toggleLink(newEditorState, selection, entityKey);
    setEditorState(temp);
    setIsFontColorOptionsOpen(false);

    setEntityNewData({ entityType: 'reload-editor' });
  }

  useEffect(() => {
    if (entityNewData) {
      handleUpdateEntities();
    }
  }, [entityNewData]);

  function handleUpdateEntities() {
    if (entityNewData?.entityType === 'carousel') {
      const contentState = editorState.getCurrentContent();
      const contentStateUpdated = contentState.replaceEntityData(
        entityNewData?.entityKey,
        { imgarray: entityNewData?.data }
      );
      const newEditorState = EditorState.push(editorState, contentStateUpdated);

      setEditorState(newEditorState);
      setEntityNewData(null);

    } else if (entityNewData?.entityType === 'delete-link') {
      let selection = editorState.getSelection();

      const entityRange = getEntityRange(editorState, entityNewData.entityKey);

      selection = selection.merge({
        anchorOffset: entityRange?.start,
        focusOffset: entityRange?.end,
      });

      const newEditorState = RichUtils.toggleLink(editorState, selection, null);
      setEditorState(newEditorState);

      setEntityNewData({ entityType: 'reload-editor' });
      if(!entityNewData?.isExternalLink) {
        handleSubmitContent(content, newEditorState, null, 'link');
      }
      
    } else if (entityNewData?.entityType === 'reload-editor') {
      editorRef.current.focus();
      setEntityNewData(null);

    } else if (entityNewData?.entityType === 'update-external-link') {
      const contentState = editorState.getCurrentContent();
      const contentStateUpdated = contentState.replaceEntityData(
        entityNewData?.entityKey,
        { href: entityNewData?.entityData,
          isExternalLink: true
        }
      );
      const newEditorState = EditorState.push(editorState, contentStateUpdated);

      setEditorState(newEditorState);

      setEntityNewData({ entityType: 'reload-editor' });
    
    } else if (entityNewData?.entityType === 'update-calculator-link') {
        const contentState = editorState.getCurrentContent();
        const contentStateUpdated = contentState.replaceEntityData(
          entityNewData?.entityKey,
          { href: entityNewData?.entityData, isExternalLink: false, isCalculator: true }
        );
        const newEditorState = EditorState.push(
          editorState,
          contentStateUpdated
        );

        setEditorState(newEditorState);

        setEntityNewData({ entityType: "reload-editor" });

    } else if (entityNewData?.entityType === 'update-link') {
      const contentState = editorState.getCurrentContent();
      const contentStateUpdated = contentState.replaceEntityData(
        entityNewData?.entityKey,
        { 
          href: entityNewData?.entityData,
          isPendingLink: entityNewData?.isPendingLink
        }
      );
      const newEditorState = EditorState.push(editorState, contentStateUpdated);
      
      setEditorState(newEditorState);

      handleSubmitContent(content, newEditorState, null, 'link');

      setEntityNewData({ entityType: 'reload-editor' });
    }
  }

  function handleRenderBlocks(block) {
    const temp = mediaBlockRenderer(block);

    handleUpdateEntities();

    return temp;
  }

  function handleKeyBindings(e) {
    if (e.keyCode === 9) {
      const newEditorState = RichUtils.onTab(e, editorState, 6 /* número maximo de tabs */)
      if (newEditorState !== editorState) {
        setEditorState(newEditorState)
      }
  
      return
    }
  
    return getDefaultKeyBinding(e)
  }

  return (
    <EditorContainer>
      <div className="buttons__container">
        {inlineStylesTypes &&
          inlineStylesTypes.map((type) => (
            <button
              className={`editor__button ${
                editorState.getCurrentInlineStyle().has(type.style) && 'active'
              }`}
              onMouseDown={(e) => {
                e.preventDefault();
                onClickInlineStyle(type.style);
              }}
            >
              {type.label}
            </button>
          ))}

        {blockStylesTypes &&
          blockStylesTypes.map((blockType) => (
            <button
              className={`editor__button ${
                editorState.getCurrentInlineStyle().has(blockType.style) &&
                'active'
              }`}
              onClick={() => blockStyleControl(blockType)}
            >
              {blockType.label}
            </button>
          ))}

        <div className="fontColor__div">
          <button
            className={`editor__button ${
              editorState.getCurrentInlineStyle().has('FONTCOLOR') && 'active'
            } fontColor`}
            onMouseDown={(e) => {
              e.preventDefault();
              setIsFontColorOptionsOpen(!isFontColorOptionsOpen);
            }}
            style={{ '--font-color': 'black' }}
          >
            <FaPalette />
          </button>
          {isFontColorOptionsOpen && (
            <ColorPicker
              isFontColorOptionsOpen={isFontColorOptionsOpen}
              callbackFunction={onAddFontColor}
              setIsFontColorOptionsOpen={setIsFontColorOptionsOpen}
            />
          )}
        </div>

        <button
          id="link_url"
          onClick={() => setIsModalLinkOpen(true)}
          className="add-link editor__button"
        >
          <i className="material-icons">
            <FaLink />
          </i>
        </button>
        {isModalLinkOpen && (
          <ModalLinkComponent
            link_id={null}
            // closeModal={setModalLinkIsOpen}
            editorState={editorState}
            callbackFunction={onAddLink}
            routineId={routineId}

          />
        )}

        <button
          id=""
          onClick={() => setImageModalInfos({ isOpen: true, type: 'image' })}
          className="add-image editor__button"
        >
          <i className="material-icons">
            <FaImage />
          </i>
        </button>
        {imageModalInfos.isOpen && (
          <ModalImageComponent
            imageModalInfos={imageModalInfos}
            onAddImage={onAddImage}
            closeModal={() => setImageModalInfos({ isOpen: false, type: null })}
          />
        )}

        <button className="editor__button" onClick={() => onAddCarousel()}>
          Carrossel
        </button>
        <button
          className="editor__button"
          onClick={() =>
            setImageModalInfos({ isOpen: true, type: 'scrollable-image' })
          }
        >
          Escrolável
        </button>
      </div>

      <div className="editor__container">
        <Editor
          blockRendererFn={handleRenderBlocks}
          ref={editorRef}
          editorState={editorState}
          handleKeyCommand={handleKeyBindings}
          onChange={(e) => {
            setEditorState(e);
            setContentTextState(e);
          }}
          plugins={editorPlugins}
          customStyleMap={{
            SUBSCRIPT: { fontSize: '0.6em', verticalAlign: 'sub' },
            SUPERSCRIPT: { fontSize: '0.6em', verticalAlign: 'super' },
          }}
          onTab={handleKeyBindings}
        />
      </div>
    </EditorContainer>
  );
}

export default EditorHtml;
