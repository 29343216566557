import styled from 'styled-components';

export const CardFormContainer = styled.div`
  position: fixed;
  height: 100vh;
  width: 100%;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  z-index: 100;

  .header {
    padding: 0;
    background-color: var(--content);
    display: flex;
    justify-content: space-between;

    .close__button {
      background-color: unset;
      border: none;
      color: white;
      font-size: 20px;
      &:hover {
        filter: brightness(0.9);
      }
    }
  }

  .content {
    border-radius: 3px;
    background-color: var(--background);
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;

    .content__input {
      display: flex;
      flex-direction: column;
    }
  }

  .title__input {
    color: white;
    padding: 0.5rem;
    border-radius: 5px;
    border: 1px solid var(--secondary);
    background-color: unset;
    margin-right: 5px;
    &:hover {
      border: 1px solid var(--secondary);
    }
    &:focus {
      outline: none;
      border: 1px solid var(--secondary);
    }
  }

  .save__button {
    color: white;
    background-color: var(--tertiary);

    margin-top: 1rem;
    padding: 0.5rem;
    align-self: flex-end;

    border: none;
    border-radius: 5px;

    &:hover {
      filter: brightness(0.9);
    }
  }

  .form__container {
    background-color: var(--content);
    border-radius: 3px;
    
    padding: 1rem;
    height: fit-content;
    max-height: 100vh;
    min-width: 500px;

    overflow-y: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;
