import React from "react";


import { AiOutlineClose } from "react-icons/ai";
import { PopupContentcontainer } from "./style";

export const PopupContentComponent = (props) => {
  return (
    <PopupContentcontainer>
      <div className={`popup ${props.isOpen ? "--show" : ""}`}>
        <div className="popup__content">
          {props.children}
        </div>
        {!props.popupTable &&
        <AiOutlineClose
          className="closeIcon"
          onClick={() => props.onClose()}
        />
        }
      </div>
    </PopupContentcontainer>
  );
}
