import styled from 'styled-components';

export const ModalTabsFormContainer = styled.div`
  height: 100vh;
  width: 100%;
  position: fixed;
  inset: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  
  .content {
    min-width: 680px;
    background-color: var(--background);
    max-height: 95vh;
    padding: 1rem;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }

    section {
      .header {
        background-color: unset;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0;
      }
    }

    .close__button {
      border: none;
      background-color: unset;
      font-size: 1.3rem;
      color: white;
    }
`