import { Container } from "./style";
import api from "../../../services/axios"
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

export function SpecialtiesBasicInformation({ hasResponse }) {
  const history = useHistory();

  const formik = useFormik({
    initialValues: {
      title: hasResponse?.title || ""
    },

    onSubmit: async (values) => {
      if (hasResponse) {
        try {
          await api.put(`specialty/${hasResponse._id}`, values);
          history.push("/specialties");
          toast.success("Especialidade atualizada com sucesso!", {
            theme: "colored",
            closeOnClick: true,
          })
        }
        catch (error) {
          toast.error("Ocorreu um erro!", {
            theme: "colored",
            closeOnClick: true,
          })
          console.log(error);
        }
      }
      else {
        try {
          await api.post('specialty/create', { ...values, free_access: true });
          history.push('/specialties')
          toast.success("Especialidade criada com sucesso!", {
            theme: "colored",
            closeOnClick: true,
          })
        } catch (error) {
          toast.error("Ocorreu um erro!", {
            theme: "colored",
            closeOnClick: true,
          })
          console.log(error);
        }
      }
    }
  })

  return (
    <Container>
      <div className="specialties__header">
        <h3>Informações básicas</h3>
      </div>

      <form onSubmit={formik.handleSubmit} className="specialties__body">
        <div className="specialties__inputs">
          <label>
            <h3>Título:</h3>
            <input name="title" onChange={formik.handleChange} className="title__input" type="text" value={formik.values.title} />
          </label>
        </div>

        <div className="button__container">
          <button type="submit" className="save__button">Salvar</button>
        </div>
      </form>
    </Container>
  );
}