import { BackButton } from '../../../components/atoms/BackButton';
import { SpecialtiesBasicInformation } from '../../../components/molecules/SpecialtiesBasicInformation';
import { SpecialtiesCreateContainer } from './style';

export function SpecialtiesCreate() {
  return (
    <SpecialtiesCreateContainer>
      <BackButton route="specialties" />
      <SpecialtiesBasicInformation/>
    </SpecialtiesCreateContainer>
  );
}
