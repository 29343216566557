import styled from 'styled-components';


export const PopupContentcontainer = styled.div`
  .popup {
    z-index: 99999999999999999;
    width: 100vw;
    height: 100vh;

    overflow: hidden;

    display: none;
    align-items: flex-start;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;

    margin: auto;

    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    &.--show {
      display: flex;
    }
  }

  .closeIcon {
    position: fixed;
    top: 25px;
    right: 25px;

    font-size: 1rem;

    cursor: pointer;
  }

  .popup__content {
    box-sizing: border-box;
    padding: 0 0 200px 0;
    margin: 100px;
    width: 100vw;
  }
`;