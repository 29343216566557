import { useEffect, useState } from "react";
import { DataTable } from "../../components/molecules/DataTable";
import { PageHeader } from "../../components/molecules/PageHeader";
import api from "../../services/axios";
import { TabsContainer } from "./style";

export function Tabs() {
  const [tabs, setTabs] = useState(null);
  const [filter, setFilter] = useState(null);
  const [filteredTabs, setFilteredTabs] = useState(null);

  async function handleGetTabs() {
    const response = await api.get("tabs");
    setTabs(response.data);
    setFilteredTabs(response.data);
  }

  useEffect(() => {
    handleGetTabs();
  }, []);

  useEffect(() => {
    filterTabs();
  }, [filter]);

  const filterTabs = () => {
    if (filter !== "" && tabs) {
      const result = tabs.filter(({ title }) =>
        title.toLowerCase().includes(filter?.toLowerCase())
      );
      if (filter?.length > 3 && result !== tabs) {
        setFilteredTabs(result);
        return;
      }
    }
    setFilteredTabs(tabs);
  };

  return (
    <TabsContainer>
      <PageHeader filter={filter} setFilter={setFilter} title="Abas" route="tabs" />

      <div className="table__container">
        <DataTable data={filteredTabs} route="tabs" />
      </div>
    </TabsContainer>
  );
}
