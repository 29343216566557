import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Content } from '../Pages/Content';
import { Home } from '../Pages/Home';
import { Login } from '../Pages/Login';
import { PendingLinks } from '../Pages/PendingLinks';
import { PendingLinksUpdate } from '../Pages/PendingLinks/PendingLinksUpdate';
import { Routines } from '../Pages/Routines';
import { RoutinesCreate } from '../Pages/Routines/RoutinesCreate';
import { RoutinesUpdate } from '../Pages/Routines/RoutinesUpdate';
import { Specialties } from '../Pages/Specialties';
import { SpecialtiesCreate } from '../Pages/Specialties/SpecialtiesCreate';
import { SpecialtiesUpdate } from '../Pages/Specialties/SpecialtiesUpdate';
import { Tabs } from '../Pages/Tabs';
import { TabsCreate } from '../Pages/Tabs/TabsCreate';
import { TabsUpdate } from '../Pages/Tabs/TabsUpdate';
import { PrivateRoute } from './privateRoutes';
import { ImagesDashboard } from '../Pages/ImagesDashboard';

const Routers = () => (
  <Switch>

    {/* Login */}
    <Route exact path='/login' component={Login} />

    {/* Home */}
    <PrivateRoute exact path='/' component={Home} />

    {/* Links */}
    <PrivateRoute exact path='/pendinglinks' component={PendingLinks} />
    <PrivateRoute exact path='/pendinglinks/update/:id' component={PendingLinksUpdate} />

    {/* Images Dashboard */}
    <PrivateRoute exact path='/imagesdashboard' component={ImagesDashboard} />

    {/* Routines */}
    <PrivateRoute exact path='/routines' component={Routines} />
    <PrivateRoute exact path='/routines/create' component={RoutinesCreate} />
    <PrivateRoute exact path='/routines/update/:id' component={RoutinesUpdate} />
    <PrivateRoute exact path='/routines/updateContent/:id' component={Content}/>

    {/* Specialties */}
    <PrivateRoute exact path='/specialties' component={Specialties} />
    <PrivateRoute exact path='/specialties/create' component={SpecialtiesCreate} />
    <PrivateRoute exact path='/specialties/update/:id' component={SpecialtiesUpdate} />

    {/* Tabs */}
    <PrivateRoute exact path='/tabs' component={Tabs} />
    <PrivateRoute exact path='/tabs/create' component={TabsCreate} />
    <PrivateRoute exact path='/tabs/update/:id' component={TabsUpdate} />

   {/* <Route exact path='/test' component={TempPageUpdate}/> */}

  </Switch>
);

export { Routers };
