import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    margin-top: 15px;
    
    .routineNav {
        background-color: var(--secondary);
        margin:10px;
        padding: 10px;
        border-radius: 5px;
    }
    
    .routineNav__list {
        line-height: 25px;
        list-style-type: disc;
        padding-left: 20px;
    }
    
    .routineNav__item {
        font-size: 0.9rem;
        &::marker{
            color:var(--content);
            font-size: 0.7rem;
        }
    }
    
    .routineNav__link{
        transition: all linear .2s;
        text-decoration: none;
        color: white;
        &:hover{
            cursor: pointer;
            color: var(--background);
            transform: scale(1.1);
        }
    }
`