import api from "../axios";

export async function parseRoutineTitle(title, card_id) {
  const titleArray = title
    .replace('-', ' ')
    .replace(' (', ' ')
    .replace(') ', ' ')
    .replace('(', ' ')
    .replace(')', ' ')
    .split(' ');

    for (const word of titleArray) {
    try {
      await api.put(`card/add-keywords/${card_id}`, { title: word });
    } catch (error) {}
  };
}

export async function updateResumeCardKeywords(card_id, keyword_list, routine_title) {
  try {
    const keyword_list_ids = keyword_list.map(
      (i) => i?._id || i
    );
    
    await api.put(`/cards/update-keywords/${card_id}`, {
      keywords_id: keyword_list_ids,
    });
    await parseRoutineTitle(
      routine_title,
      card_id
    );
  } catch (error) {
    console.log(error)
  }
}