import styled from 'styled-components';

export const PageHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background-color: var(--background);
  padding: 1.5rem;
  width: 60vw;

  h2 {
    align-self: flex-start;
    font-size: 30px;
  }

  .header__button {
    background-color: var(--tertiary);
    border: none;
    border-radius: 5px;
    width: 4rem;
    height: 2rem;
    margin-bottom: 10px;
    color: white;
    &:hover {
      filter: brightness(0.9);
    }
  }

  .input__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 10px;

    label {
      font-size: 15px;
      display: flex;
      flex-direction: column;
    }

    input {
      color: white;
      border-radius: 5px;
      border: 1px solid var(--content);
      background-color: var(--background);
      width: auto;
      height: 5vh;
      margin-top: 5px;
      &:hover {
        border: 1px solid var(--secondary);
      }
      &:focus {
        outline: none;
        border: 1px solid var(--secondary);
      }
    }
  }
`;
