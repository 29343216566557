import { BackButton } from '../../../components/atoms/BackButton';
import { TabsForm } from '../../../components/molecules/TabsForm/Index';
import { TabsUpdateContainer } from './style';
import { useHistory, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import api from '../../../services/axios';

export function TabsUpdate() {
  const history = useHistory();

  const [tab, setTab] = useState(null);

  const { id } = useParams();

  async function handleGetTab() {
      const response = await api.get(`/tab/${id}`)
      setTab(response.data);
  }

  useEffect( () => {
    handleGetTab();
  }, [])

  return (
    <TabsUpdateContainer>
      <BackButton route="tabs" />
      { tab && <TabsForm tab={tab} afterSubmitFunction={() => history.push('/tabs')}/> } 
    </TabsUpdateContainer>
  );
}