import { useState } from "react";

import { RiCloseFill } from "react-icons/ri";
import { toast } from "react-toastify";
import api from "../../../services/axios";
import { contentTypeList } from "../../../services/utils/contentTypeList";

import { Container } from "./style";

export function AddContentOnCard({ card, closeModal, parent = null, contentArrayLength }) {

  const [content, setContent] = useState({
    title: "",
    content_type_id: null,
    position: contentArrayLength + 1,
    show_in_index: false,
    component_open: false,
  });

  const [children, setChildren] = useState({
    title: "",
    content_type_id: null,
    position: parent?.children_id.length + 1,
    show_in_index: false,
    component_open: false,
  });


  async function handleSubmitContent() {
    try {
      const aux = handleTitleRequired(content.content_type_id)

      if (aux && content.title === "" ) {
        toast.error("Esse tipo de conteúdo necessita de um título obrigatório!")
        return
      }

      if(content.content_type_id === null) {
        toast.error("Selecione um tipo!")
        return
      }

      const response = await api.post(
        `content/create/${card.card_id._id}`,
        content
      );

      closeModal();
      toast.success("Conteúdo adicionado com sucesso!", {
        theme: 'colored',
        closeOnClick: true,
      });
    } catch (error) {
      toast.error("Ocorreu um erro!", {
        theme: "colored",
        closeOnClick: true,
      })
      console.log(error);
    }
  }

  function handleTitleRequired(typeId) {
    const result = contentTypeList.find((contentType) => {
      if (contentType._id === typeId) {
        return contentType
      }
    })
    return result?.is_title_required
  }

  async function handleSubmitChildren() {
    try {
      const aux = handleTitleRequired(children.content_type_id)

      if (aux && children.title === "" ) {
        toast.error("Esse tipo de conteúdo necessita de um título obrigatório!")
        return
      }

      if(children.content_type_id === null) {
        toast.error("Selecione um tipo!")
        return
      }

      const response = await api.post(
        `content/create-children/${parent._id}`,
        { ...children, is_children: true }
      );
      closeModal();
      toast.success("Conteúdo adicionado com sucesso!", {
        theme: "colored",
        closeOnClick: true,
      });
    }
    catch (error) {
      toast.error("Ocorreu um erro!", {
        theme: "colored",
        closeOnClick: true,
      })
      console.log(error);
    }
  }

  return (
    <Container>
      <div>
        <div className="addContent__header">
          {card && (
            <h3>
              Adicionar conteúdo no card: <br />{" "}
              <span>{card?.card_id?.title}</span>
            </h3>
          )}
          {parent && (
            <h3>
              Adicionar conteúdo no container: <br />{" "}
              <span>{parent?.title}</span>
            </h3>
          )}
          <button
            className="close__button"
            onClick={closeModal}>
            <RiCloseFill />
          </button>
        </div>

        <div className="addContent__body">
          <label>
            <h3>Título:</h3>
            {card && <input
              className="title__input"
              type="text"
              onChange={(e) =>
                setContent({ ...content, title: e.target.value })
              }
            />}

            {parent && <input
              type="text"
              onChange={(e) =>
                setChildren({ ...children, title: e.target.value })
              }
            />}
          </label>

          <label>
            <h3>Tipo:</h3>

            {card && <select
              id=""
              onChange={(e) =>
                setContent({ ...content, content_type_id: e.target.value })
              }
            >
              <option value="">Selecione o tipo do conteúdo</option>
              {contentTypeList?.map((contentType) => (
                <option value={contentType._id}>{contentType.title}</option>
              ))}
            </select>}

            {parent && <select
              id=""
              onChange={(e) =>
                setChildren({ ...children, content_type_id: e.target.value })
              }
            >
              <option value="">Selecione o tipo do conteúdo</option>
              {contentTypeList?.map((contentType) => (
                (contentType.code === "accordion" || contentType.code === "accordion_html") &&
                <option value={contentType._id}>{contentType.title}</option>
              ))}
            </select>}

          </label>
          {card && <button onClick={() => handleSubmitContent()}>Salvar</button>}
          {parent && <button onClick={() => handleSubmitChildren()}>Salvar</button>}
        </div>
      </div>
    </Container>
  );
}
