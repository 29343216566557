import styled from 'styled-components';

export const Container = styled.div `
    margin-left: calc(2.8rem + 11px);
    padding: 0.5rem;
    border: 1px solid var(--primary);
    border-top: none;
    margin-top: -1rem;

    .contentList{
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .contentList__contentAndButton {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
    }

    .upAndDown__container{
        display: flex;
        align-items: center;
        margin-right: 5px;
    }

    .upAndDown__button{
        background-color: transparent;
        border: none;
        font-size: 1.4rem;
        color: var(--soft-color);
        padding: 0;
        padding-right: 3px;
        &:hover{
            filter: brightness(0.8);
        }
        &:disabled{
            filter: brightness(0.5);
        }
    }

    .contentList__content {
        width: 100%;
        margin: .5rem 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem;
        transition: all linear .2s;
        border: 1px solid var(--background);

        &.active {
            border: 1px solid var(--secondary);
            background-color: var(--content);
        }
    }

    .contentList__options {
        display: flex;
        flex-direction: row;
    }

    .contentList__button{
        background-color: transparent;
        border: none;
        font-size: 1rem;
        &.add{
            color: var(--green);
        }
        &.remove{	
            color: var(--red);
        }
    }

    .contentList__check{
        width: 1.4rem;
        height: 0.8rem;
        &:hover{
            cursor: pointer;
        }
    }

    .savePosition{
        width: 100%;
        margin-top: 1rem;
        border:1px solid var(--secondary);
        border-radius: 5px;
        background-color: transparent;
        color: white;
        padding: .5rem;
        font-size: 1.1rem;
        transition: all linear .3s;
        &:hover{
            background-color: var(--green);
            color: var(--black);
            transform: scale(1.01);
        }
    }
`