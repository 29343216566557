import { useEffect, useState } from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';

import { MdAdd } from 'react-icons/md';
import { FiTrash } from 'react-icons/fi';

import api from '../../../services/axios';

import { RoutineSpecialtiesContainer, SelectComponentStyles } from './style';

export function RoutineSpecialtiesForm({ routine }) {
  const [isRoutineSpecialtiesLoad, setIsRoutineSpecialtiesLoad] = useState(false);


  const [specialties, setSpecialties] = useState(null);
  const [selectedSpecialty, setSelectedSpecialty] = useState(null);

  const [routineSpecialties, setRoutineSpecialties] = useState(null);

  async function handleGetSpecialties() {
    const response = await api.get('specialty');

    const newArray = await response.data.map((specialties) => {
      return { label: specialties.title, value: specialties._id };
    });

    setSpecialties(newArray);
  }

  async function handleGetRoutineSpecialties() {
    const response = await api.get(`/specialty/by-routine/${routine._id}`);

    const newArray = await response.data.map((specialties) => {
      return { label: specialties.title, value: specialties._id };
    });

    setRoutineSpecialties(newArray)
    setIsRoutineSpecialtiesLoad(true)
  }


  useEffect(() => {
    handleGetSpecialties();
  }, []);

  useEffect(() => {
    if (!isRoutineSpecialtiesLoad) {
      handleGetRoutineSpecialties();
    }
  }, [isRoutineSpecialtiesLoad])


  async function handleAddSpecialty() {
    try {
      const response = await api.put(`/specialty/add-routine/${routine._id}/${selectedSpecialty.value}`)
      setSelectedSpecialty(null);
      setIsRoutineSpecialtiesLoad(false);
      toast.success("Especialidade adicionada com sucesso!", {
        theme: "colored",
        closeOnClick: true,
      })
    } catch (error) {
      toast.error("Ocorreu um erro!", {
        theme: "colored",
        closeOnClick: true,
      })
      console.log(error)
    }
  }

  async function handleRemoveSpecialty(specialty_id) {
    try {
      if (window.confirm('Deseja deletar essa especialidade?')) {
        const response = await api.put(`/specialty/remove-routine/${routine._id}/${specialty_id}`)
        setIsRoutineSpecialtiesLoad(false);
        toast.success("Especialidade removida com sucesso!", {
          theme: "colored",
          closeOnClick: true,
        })
      }
    } catch (error) {
      toast.error("Ocorreu um erro!", {
        theme: "colored",
        closeOnClick: true,
      })
      console.log(error)
    }

  }


  return (
    <RoutineSpecialtiesContainer id="specialties">
      <div className="header">
        <h3 className="header__title">Especialidades</h3>
      </div>

      {/* <div className> */}
      <div className="content">
        <div className="select__container">
          <label>
            <h3>Adicionar especialidade</h3>
            {specialties && (
              <Select
                placeholder="Selecione uma especialidade..."
                value={selectedSpecialty}
                name="specialty"
                styles={SelectComponentStyles}
                options={specialties}
                onChange={(e) => setSelectedSpecialty(e)}
              />
            )}
          </label>
          <button
            disabled={!selectedSpecialty}
            onClick={() => handleAddSpecialty()}
          >
            <MdAdd />
          </button>
        </div>
        {routineSpecialties && isRoutineSpecialtiesLoad && (
          <div className="selectedSpecialties__container">
            <ul>
              {routineSpecialties.map((specialty) => (
                <li>
                  <h3>{specialty.label}</h3>
                  <button onClick={() => handleRemoveSpecialty(specialty.value)}>
                    <FiTrash />
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      {/* <button className="submit__button" type="submit">
          Salvar
        </button> */}
      {/* </div> */}
    </RoutineSpecialtiesContainer>
  );
}
