import React from 'react';
import logo from '../../assets/images/logo-white.svg';
import { HomeContainer } from './style';

export function Home() {

  return (
      <HomeContainer>
          <img src={logo} alt="Logo BlackBook" />
      </HomeContainer>
  )
}