import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  box-sizing: border-box;
  
  .pagination {
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    gap: 5px;
    
    .pagination__count {
      align-self: flex-end;
      font-size: 12px;
    }
    div {
      display: flex;
      gap: 5px;

      button {
        border: 2px solid transparent;
        background-color: #666;
        color: white;
        border-radius: 5px;
        box-sizing: border-box;
        
        &.active {
          border: 2px solid #8774ff;
        }
      }
    }
  }

  .input__container {
    display: grid;
    grid-template-columns: 1fr 0.2fr auto;
    gap: 1rem;
    width: 100%;
    padding: 2rem;

    .by__title {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      color: white;

      span {
        font-size: 12px;
      }

      input {
        background-color: transparent;
        border: 1px solid #666;
        border-radius: 5px;
        padding: 0.75rem;
        color: white;
      }
    }

    .filters {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      .radio__filter {
        width: fit-content;
        gap: 5px;
        display: grid;
        grid-template-columns: 20px auto;
        cursor: pointer;
        font-size: 14px;
        
        label {
          align-self: flex-end;
        }
      }

      h2 {
        font-size: 12px;
      }
    }

    button {
      height: fit-content;
      width: fit-content;
      padding: 0.75rem;
      align-self: flex-end;
      border-radius: 5px;
      background-color: #8774ff;
      border: none;
      color: white;
      font-weight: 700;
    }
  }

  .table_container {
    width: 100%;
    padding: 1rem;
    margin: 1rem;
    background-color: var(--content);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 1rem;



    .row {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
      border: 1px solid #666;
      /* background-color: #666; */
      padding: 1rem;
      .title {
        text-align: center;
        margin: 1rem 0;
      }
      .content {
        display: grid;
        grid-template-columns: 0.5fr 1fr;
        gap: 1rem;


        .origin__container {

          .origin__title {}

          .origin__content {
            margin: 1rem 0 ;
            display: flex;
            flex-direction: column;
            gap: 10px;
            border: 1px solid gray;
            padding: 0.5rem;
            box-sizing: border-box;
            h3 {
              
              span {

                font-size: 12px
              }
            }

            a {
              color: #8774ff;
              text-align: right;
            }
          }
        }

        .loading__container {
          display: flex;
          align-items: center;
          justify-content: center;
          flex: 1;
        }

        .img_container {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 10px;
          flex-direction: row;
          align-items: flex-start;
          /* justify-content: space-evenly; */
          .image_box {
            
            text-align: center;
            height: 100%;
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
                    
            /* p {
              margin: 1rem 0;
            } */
            
            input {
              display: none;
            }
            label {
              text-align: center;
              cursor: pointer;
              display: flex;
              height: 100%;

              &:hover {
                filter: brightness(0.9);
              }
            }
            img {
              /* margin: 1rem; */
              max-height: 239px;
              max-width: 320px;
              height: 100%;
              width: 100%;
              border-radius: 5px;
            }
          }
        }
      }
    }
  }

  .modal__overlay {
    position: fixed;
    background-color: #000000aa;
    inset: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .modal__container {
      padding: 1rem;
      border-radius: 5px;
      height: auto;
      width: auto;
      background-color: #666;
      position: relative;

      h1 {
        margin-bottom: 1rem;
      }
      button {
        position: absolute;
        top: 1rem;
        right: 1rem;
      }
      .img__container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
        
        img {
          max-height: 239px;
          max-width: 320px;
          height: 100%;
          width: 100%;
          border-radius: 5px;
        }
      }
    }
  }
`;