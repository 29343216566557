import { useEffect, useState } from 'react';
import _ from 'lodash';
import { FiTrash } from 'react-icons/fi';
import { toast } from 'react-toastify';

import {
  IoIosArrowDropupCircle,
  IoIosArrowDropdownCircle,
} from 'react-icons/io';
import { MdModeEdit } from 'react-icons/md';

import api from '../../../services/axios';
import AddTabOnRoutineForm from '../../molecules/AddTabOnRoutineForm';

import { RoutineTabsContainer } from './style';
import { ModalTabsForm } from '../../molecules/ModalTabsForm';

export function RoutineTabs({ routine, tabs, setTabs }) {
  const [addTabsIsOpen, setAddTabsIsOpen] = useState(false);
  const [changedPosition, setChangedPosition] = useState(false);
  const [editTabIsOpen, setEditTabIsOpen] = useState(false);

  // VALIDAR A BUSCA DE TABS ATRAVEZ DO _ID DA ROTINA

  const [isTabsLoad, setIsTabsLoad] = useState(false);

  async function handleGetTabs() {
    try {
      const response = await api.get(`/routine-populate-tabs/${routine._id}`);

      setTabs(response.data);
      setIsTabsLoad(true);
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (!isTabsLoad) {
      handleGetTabs();
    }
  }, [isTabsLoad]);

  function changePosition(from, to) {
    const newArr = [...tabs];

    newArr[from - 1] = { ...newArr[from - 1], position: to };
    newArr[to - 1] = { ...newArr[to - 1], position: from };
    newArr.sort((a, b) => a.position - b.position);

    setTabs(newArr);
    setChangedPosition(true);
  }

  async function handleValidateTabsPositionAfterDelete(array) {
    array.forEach((tab, idx) => tab.position = idx + 1);
    setTabs(array);
    await handleSubmitTabs(array, false)
  }

  async function handleRemoveTab(tab) {
    try {
        if (window.confirm(`Deseja deletar a aba ${tab.tab_id.title}?`)) {
          await api.delete(`tabs/${tab._id}/${routine._id}`);

          let aux = [...tabs]
          _.remove(aux, tab)
          handleValidateTabsPositionAfterDelete(aux)

          setIsTabsLoad(false);
          toast.success("Aba deletada com sucesso!", {
            theme: "colored",
            closeOnClick: true,
          })
        }
      } catch (error) {
        console.log(...error.response.data.data);
      }
  }

  async function handleSubmitTabs(tabsArray, isForPositionning = true) {
    try {
      const parsedTabs = tabsArray.map((tab) => {
        return { _id: tab._id, position: tab.position };
      });

      await api.put('routine-tabs-position', { routineTabs: parsedTabs });

      setIsTabsLoad(false);
      setChangedPosition(false);

      if(isForPositionning) {
        toast.success('Alteração realizada com sucesso!', {
          theme: 'colored',
          closeOnClick: true,
        })
      }
    } catch (error) {
      toast.error("Ocorreu um erro!", {
        theme: "colored",
        closeOnClick: true,
      })
      console.log(error);
    }
  }

  return (
    <>
      <RoutineTabsContainer id="tabs">
        <div className="header">
          <h3 className="header__title">Abas</h3>
        </div>

        <div className="content">
          <div className="buttons__content">
            <button className="addTab__button" onClick={() => setAddTabsIsOpen(true)}>
              Adicionar aba
            </button>
          </div>

          <div className="tabs__container">
            <ul>
              {tabs && isTabsLoad &&
                tabs.map((tab) => (
                  <li key={tab.id}>
                    <div className="content__li">
                      <button
                        disabled={tab.position <= 2 || tab.tab_id.is_default}
                        onClick={() =>
                          changePosition(tab.position, tab.position - 1)
                        }
                      >
                        <IoIosArrowDropupCircle />
                      </button>
                      <button
                        disabled={tab.position >= tabs.length || tab.tab_id.is_default}
                        onClick={() =>
                          changePosition(tab.position, tab.position + 1)
                        }
                      >
                        <IoIosArrowDropdownCircle />
                      </button>
                      <h1>
                        {tab.position} - {tab.tab_id.title}
                      </h1>
                    </div>

                    <div className="buttons__div">
                      {!tab.tab_id.is_default &&
                        <button
                          className="editTab__button"
                          onClick={() => setEditTabIsOpen(tab.tab_id)}
                        >
                          <MdModeEdit />
                        </button>
                      }

                      <button
                        className={`removeTab__button ${tab.position === 1 ? 'resume__tab' : ''}`}
                        onClick={() => handleRemoveTab(tab)}
                      >
                        <FiTrash />
                      </button>
                    </div>
                  </li>
                ))}
            </ul>
          </div>
        </div>
        {editTabIsOpen && <ModalTabsForm setIsTabsLoad={setIsTabsLoad} closeModal={setEditTabIsOpen} tab={editTabIsOpen} />}

        {changedPosition &&
          <button
            className="submit__button"
            type="submit"
            onClick={() => handleSubmitTabs(tabs)}
          >
            Salvar
          </button>
        }
      </RoutineTabsContainer>
      {addTabsIsOpen && (
        <AddTabOnRoutineForm tabs={tabs} closeModal={() => { setAddTabsIsOpen(false); setIsTabsLoad(false) }} routineId={routine._id} />
      )}
    </>
  );
}
