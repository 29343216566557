import styled from "styled-components";

export const AccordionContainer = styled.section`
  .closed{
    display: none;
  }

  /* Accordion html */
  .accordion {
    background-color: var(--dark-color);
    padding: 5px;
    color: white;
    border-radius: 5px;
  }

  .accordion-header {
    display: flex;
    align-items: center;
    min-height: 48px;
    position: sticky;
    top: var(--topValue);
    background-color: var(--dark-color);
  }

  .accordion-type-icon {
    width: 48px;
    height: 48px;
  }

  .accordion-title {
    width: 100%;
    padding-left: 10px;
    margin: 5px 0;
  }

  .accordion-open-close-indicator {
    font-size: 25px;
    &:hover{
      cursor: pointer;
    }
  }

  table td,
  th {
    padding: 5px;
    color: black;
    font-size: 0.8rem;
  }

  th.header {
    background-color: var(--hard-color);
    justify-content: center;
    text-align: center;
    font-weight: bold;
  }

  .justify-center {
    align-items: center;
    text-align: center;
    vertical-align: middle;
  }
`;
