import styled from "styled-components";

export const SidebarContainer = styled.div`
    background-color: var(--content);
    height: 100%;
    width: 200px;
    box-shadow: 5px 0px 10px 0px black;
    z-index: 1;
    position: fixed;
    margin-top: 40px;

    .sidebarNav{
        padding: 1rem 0;
    }

    .sidebarNav__option{
        padding: 1rem;
        margin-bottom: .5rem;
        background-color: var(--secondary);
        transition: linear .3s;
        &:hover{
            cursor: pointer;
            transform: scale(1.05);
            border-radius: 0 5px 5px 0;
        }
    }

    .option__content {
        color: white;
        text-decoration:none;
    }
`;