import { BackButton } from '../../../components/atoms/BackButton';
import { TabsCreateContainer } from './style';
import { TabsForm } from '../../../components/molecules/TabsForm/Index';
import { useHistory } from 'react-router-dom';

export function TabsCreate() {
  const history = useHistory();

  return (
    <TabsCreateContainer>
      <BackButton route="tabs" />
      <TabsForm afterSubmitFunction={() => history.push('/tabs')}/>
    </TabsCreateContainer>
  );
}