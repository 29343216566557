import { useState, useEffect } from 'react';
import { LinksContainer } from './style';
import { DataTable } from '../../components/molecules/DataTable';
import { PageHeader } from '../../components/molecules/PageHeader';
import api from '../../services/axios';

export function PendingLinks() {
  const [pendingLinks, setPendingLinks] = useState(null);
  const [filter, setfilter] = useState(null);
  const [filteredPendingLinks, setFilteredPendingLinks] = useState(null);

async function handleGetPendingLinks() {
  const response = await api.get('/link-manager/pending')
  setPendingLinks(response.data)
  setFilteredPendingLinks(response.data);
}

const filterPendingLinks = () => {
  if (filter !== '' && pendingLinks) {
    const result = pendingLinks.filter(({ link_origin_text }) =>
      link_origin_text?.toLowerCase().includes(filter?.toLowerCase())
    );
    if (filter?.length > 2 && result !== pendingLinks) {
      setFilteredPendingLinks(result);
      return
    }
  }
  setFilteredPendingLinks(pendingLinks);
}

useEffect(() => {
  handleGetPendingLinks();
}, []);

useEffect(() => {
  filterPendingLinks();
}, [filter])

  return (
    <LinksContainer>
      <PageHeader
      filter={filter}
      setFilter={setfilter}
      title="Links pendentes"
      route="pendinglinks"
      />

      <div className="table__container">
        <DataTable
          data={filteredPendingLinks}
          route="pendinglinks"
        />
      </div>
    </LinksContainer>
  )
}
