import { AccordionDiv } from "./style";
import { Accordion } from "../Accordion";

export function ContainerAccordion({content}) {
  function parseContent(item) {
    const temp = item.text?.match(/<img src="(.*?)">/gm);

    temp?.length &&
      temp.forEach((image) => {
        const aux = image
          .replaceAll(
            '<img src="images/',
            `<img src="https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/o/images%2F`
          )
          .replaceAll('">', '?alt=media">');

          item.text = item.text.replaceAll(image, aux);
      });

      return item
  }

  return (
    <AccordionDiv>
      <div className="accordion-section">
      {content?.map((item) => (
        <div className="accordion-container">
          <Accordion content={parseContent(item)} open={false}/>
        </div>
      ))}
      </div>
    </AccordionDiv>
  );
}
