import { useFormik } from 'formik';
import api from '../../../services/axios';
import { RiCloseFill } from 'react-icons/ri';
import { CardFormContainer } from './style';
import { toast } from 'react-toastify';
import { Keywords } from '../../organisms/Keywords';

export function CardFormComponent({
  creation,
  card,
  closeModal,
  tab,
  tabLength,
}) {
  const formik = useFormik({
    initialValues: {
      title: card?.card_id?.title || '',
      position: tabLength?.length + 1 || '',
    },
    onSubmit: async (values) => {
      try {
        if (creation) {
          const response = await api.post(`/cards/create/${tab._id}`, values);
          closeModal(tab.tab_id._id === '5fa0762c17835259eef7e238' ? response.data._id : null);
          toast.success('Card adicionado com sucesso!', {
            theme: 'colored',
            closeOnClick: true,
          });
        } else {
          const response = await api.put(`/cards/${card.card_id._id}`, {
            title: values.title,
          });
          closeModal();
          toast.success('Card atualizado com sucesso!', {
            theme: 'colored',
            closeOnClick: true,
          });
        }
      } catch (error) {
        toast.error('Ocorreu um erro!', {
          theme: 'colored',
          closeOnClick: true,
        });
        console.log(error);
      }
    },
  });

  return (
    <CardFormContainer>
      <div className="form__container">
        <div className="header">
          {card ? <h1>Card:</h1> : <h1>Criar card</h1>}
          <button className="close__button" type="button" onClick={closeModal}>
            <RiCloseFill />
          </button>
        </div>
        <div className="content">
          <label className="content__input">
            <h3>Título:</h3>
            <input
              className="title__input"
              type="text"
              onChange={formik.handleChange}
              name="title"
              value={formik.values.title}
            />
          </label>
          {!creation && (
            <Keywords
              keywordArray={card?.card_id?.keywords_id}
              route="card"
              parentId={card?.card_id?._id}
            />
          )}
          <button className="save__button" type="button" onClick={formik.handleSubmit}>
            Salvar
          </button>
        </div>
      </div>
    </CardFormContainer>
  );
}
