import styled from 'styled-components';

export const CardActionsSectionContainer = styled.div`

.edit__button {
    height: 1.5rem;
    border-radius: 30px;
    border: none;
    background-color: var(--tertiary);
    color: white;
    margin-right: 10px;
    margin-left: 10px;
  }

  .delete__button {
    height: 1.5rem;
    border-radius: 30px;
    border: none;
    background-color: var(--red);
    color: white;
  }

`