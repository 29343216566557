import { useState } from "react";
import { PopupContentComponent } from "../../../molecules/popup-content";
import { TableHTMLPreview } from "../../../molecules/TableHTMLPreview";
import { PopupContainer } from "./style";

export function Popup({title, content, type}) {
    const [ isOpen, setIsOpen ] = useState(false)

    let parsed = content?.replace(/src="/gi, `src="https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/o/`)
        ?.replace(/images\//gi, `images%2F`)
        ?.replace(/.jpeg"/gi, `.jpeg?alt=media"`)
        ?.replace(/.jpg"/gi, `.jpg?alt=media"`)
        ?.replace(/.png"/gi, `.png?alt=media"`)
        ?.replace(/.gif"/gi, `.gif?alt=media"`)
        ?.split(`.svg"`)?.join(`.svg?alt=media"`)
        ?.split(`.svg+xml"`)?.join(`.svg+xml?alt=media"`)

    return(
        <PopupContainer>
            <div className="popup-button" onClick={() => setIsOpen(true)}>
                <div className={`popup-indicator ${type}`} />
                <h1 className="popup-title">{title}</h1>
                <div className="popup-icon"></div>
            </div>

            {isOpen && 
            <PopupContentComponent isOpen={isOpen} onClose={() => setIsOpen(false)}>
                <TableHTMLPreview extra={{content: parsed, title: title}} onClose={() => setIsOpen(false)}/>
            </PopupContentComponent>
            }
        </PopupContainer>
    )
}