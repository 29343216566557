import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { StorageProvider, useFirebaseApp } from 'reactfire';
import { getStorage } from 'firebase/storage';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';

import api from '../../../services/axios';
import UploadImageContainer from './UploadImageContainer';
import { routineLegends } from '../../../services/utils/routineLegends';

import { RoutineBasicInformationFormContainer } from './style';
import { updateResumeCardKeywords } from '../../../services/utils/updateResumeCardKeywords';

export function RoutineBasicInformationForm({ routine, setRoutineTitle, getResumeTabCards = null }) {
  const appFirebase = useFirebaseApp();

  const history = useHistory();
  const [coverImage, setCoverImage] = useState({
    imageHasChange: false,
    name: '',
    file: null,
  });

  async function handleUpdateResumeCards(routine_title) {
    const re = await getResumeTabCards?.()
    re?.cards_ids.map(async (card) => {
      await updateResumeCardKeywords(
        card, 
        routine.keywords_id, 
        routine_title
      )
    });
  }

  const formik = useFormik({
    initialValues: {
      title: routine?.title || '',
      legend: routine?.legend || '',
      image: routine?.image || '',
    },

    onSubmit: async (values) => {
      if (routine) {
        // ATUALIZAR ROTINA
        try {
          if (coverImage.imageHasChange) {
            const parseImageName = coverImage.file?.name
              .normalize('NFD')
              .replace(/([\u0300-\u036f]|[^0-9a-zA-Z.])/g, '');
            if (coverImage.name) {
              if (routine.image) {
                await api.delete(
                  `/image/${routine.image.replace('images/', '')}/${
                    routine._id
                  }`
                );
              }
            }
            await api.post(`/image/create`, {
              url: coverImage.url,
              name: parseImageName,
              origin: {
                id: routine._id,
                collection: 'r_routine',
                field: 'image',
              },
            });
            values.image = `images/${parseImageName}`;
            setCoverImage({ ...coverImage, imageHasChange: false });
          }

          const response = await api.put(`routine/${routine._id}`, values);
          setRoutineTitle(response.data);
          await handleUpdateResumeCards(response.data.title)
          toast.success('Rotina atualizada com sucesso!', {
            theme: 'colored',
            closeOnClick: true,
          });
        } catch (error) {
          console.error(error);
          toast.error('Ocorreu um erro!', {
            theme: 'colored',
            closeOnClick: true,
          });
        }
      } else {
        // CRIAR ROTINA
        try {
          let parseImageName = '';
          if (coverImage.imageHasChange) {
            parseImageName = coverImage.file?.name
              .normalize('NFD')
              .replace(/([\u0300-\u036f]|[^0-9a-zA-Z.])/g, '');
          }
          const apiResponse = await api.post('routine/create', {
            ...values,
            image: `images/${parseImageName}`,
          });
          if (coverImage.imageHasChange) {
            await api.post(`/image/create`, {
              url: coverImage.url,
              name: parseImageName,
              origin: {
                id: apiResponse.data._id,
                collection: 'r_routine',
                field: 'image',
              },
            });
            setCoverImage({ ...coverImage, imageHasChange: false });
          }
          history.push(
            apiResponse
              ? `/routines/update/${apiResponse.data._id}`
              : '/routines'
          );
          toast.success('Rotina criada com sucesso!', {
            theme: 'colored',
            closeOnClick: true,
          });
        } catch (error) {
          console.error(error);
          toast.error('Ocorreu um erro!', {
            theme: 'colored',
            closeOnClick: true,
          });
        }
      }
    },
  });

  return (
    <StorageProvider sdk={getStorage(appFirebase)}>
      <RoutineBasicInformationFormContainer id="basicInfo">
        <div className="header">
          <h3 className="header__title">Informações básicas</h3>
        </div>

        <form onSubmit={formik.handleSubmit}>
          <div className="content">
            <div className="image__box">
              <UploadImageContainer setCoverImage={setCoverImage} coverImage={coverImage} initialImage={formik.values.image}/>
            </div>

            <div className="inputs__box">
              <label>
                <h3>Título</h3>
                <input
                  className="input__title"
                  type="text"
                  name="title"
                  value={formik.values?.title}
                  onChange={formik.handleChange}
                />
              </label>
              <label>
                <h3>Legenda</h3>
                <select
                  className="subtitle__select"
                  name="legend"
                  onChange={formik.handleChange}
                  value={formik.values?.legend}
                >
                  <option value="">Selecione uma legenda</option>
                  {routineLegends &&
                    routineLegends.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                </select>
              </label>
            </div>
          </div>
          <button disabled={formik.isSubmitting ? true : false} type="submit">
            {formik.isSubmitting ? 'Carregando...' : 'Salvar'}
          </button>
        </form>
      </RoutineBasicInformationFormContainer>
    </StorageProvider>
  );
}
