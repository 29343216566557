import { createContext, useState } from 'react';
import api from '../services/axios';

export const ContextAplication = createContext(null);

const AplicationProvider = ({ children }) => {

  const [entityNewData, setEntityNewData] = useState(null);

  const [currentContent, setCurrentContent] = useState(null);

	const [isModalLinkOpen, setIsModalLinkOpen] = useState(false);
	const [linkModalInfos, setLinkModalInfos] = useState(null);
  
  async function handleGetCurrentContent(content_id) {
    if(!content_id) {
      setCurrentContent(null);
      return;
    }

    try {
      setCurrentContent(null)
      const response = await api.get(`/content/${content_id}`);
      setCurrentContent(response.data);
    } catch (error) {
      console.log(error)
    }
  }

  function handleSetNewEntity(data, entityKey, entityType) {
    setEntityNewData({data: data, entityKey: entityKey, entityType: entityType})
  }

  const context = {
    currentContent,
    handleGetCurrentContent,
    entityNewData, 
    setEntityNewData,
    handleSetNewEntity,
    isModalLinkOpen, 
    setIsModalLinkOpen,
    linkModalInfos, 
    setLinkModalInfos,
  }

  return (
    <>
      <ContextAplication.Provider value={context}>
        {children}
      </ContextAplication.Provider>
    </>
  );
};


export default AplicationProvider;
