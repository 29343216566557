import { useState } from "react";
import { FiChevronDown } from "react-icons/fi";
import { Accordion } from "../Accordion";
import { AccordionWithTitleContainer } from "./style";
let htmlToReact = require("html-to-react").Parser;

export function AccordionWithTitle({ title, children, type, isOpen }) {
  const conteudo = new htmlToReact();
  const [isContainerOpen, setIsContainerOpen] = useState(isOpen);

  function toggleContainer() {
    setIsContainerOpen(!isContainerOpen);
  }

  function parseContent(item) {
    const temp = item.text?.match(/<img src="(.*?)">/gm);

    temp?.length &&
      temp.forEach((image) => {
        const aux = image
          .replaceAll(
            '<img src="images/',
            `<img src="https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/o/images%2F`
          )
          .replaceAll('">', '?alt=media">');

          item.text = item.text.replaceAll(image, aux);
      });

      return item
  }

  return (
    <AccordionWithTitleContainer>
      <section className="accordion-header">
        <div
          className={`accordion-type-icon ${type}`}
        />
        <div className="accordion-title">{title}</div>
        <div className="accordion-open-close-indicator" onClick={()=>toggleContainer()}>
          <FiChevronDown />
        </div>
      </section>
      {isContainerOpen && children.map((item) => (
        <Accordion content={parseContent(item)} open={false}/>
      ))}
    </AccordionWithTitleContainer>
  );
}
