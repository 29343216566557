export const contentTypeList = [
  {
    "_id": "5fa0769a34e0d331019ef880",
    "title": "Accordion",
    "code": "accordion",
    "is_title_required": true
  },
  {
    "_id": "5fa0769a34e0d331019ef881",
    "title": "Accordion HTML",
    "code": "accordion_html",
    "is_title_required": true
  },
  {
    "_id": "5fa0769b34e0d331019ef882",
    "title": "Carousel",
    "code": "caroseul",
    "is_title_required": false
  },
  {
    "_id": "5fa0769b34e0d331019ef884",
    "title": "Container Accordions Com Título",
    "code": "container_accordion_title",
    "is_title_required": true
  },
  {
    "_id": "5fa0769b34e0d331019ef883",
    "title": "Container Accordions Sem Título",
    "code": "container_accordion",
    "is_title_required": false
  },
  {
    "_id": "5fa0769b34e0d331019ef885",
    "title": "Imagem",
    "code": "image",
    "is_title_required": false
  },
  {
    "_id": "5fa0769c34e0d331019ef886",
    "title": "Popup",
    "code": "popup_html",
    "is_title_required": true
  },
  {
    "_id": "5fa0769c34e0d331019ef887",
    "title": "Scrollable Imagem",
    "code": "scrollable_image",
    "is_title_required": false
  },
  {
    "_id": "5fa0769c34e0d331019ef888",
    "title": "Texto",
    "code": "text",
    "is_title_required": false
  }
]