import { ListLinkContainer } from "./style";
import { FaEye } from 'react-icons/fa';
import api from '../../../services/axios';
import drugApi from '../../../services/axiosDrugApi';
import { useParams, useHistory, Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { Pagination } from '../../atoms/Pagination';

export default function ListLinkComponent() {
  const [linkPointingHere, setLinkPointingHere] = useState(null);
  const [filteredLinks, setFilteredLinks] = useState(null);
  const [paginatedLinks, setPaginatedLinks] = useState(null);
  const [page, setPage] = useState(1);
  const { id } = useParams();
  const history = useHistory();

  function handleLinkType(link) {
    switch (link) {

      case "basic_information":
        return "Informações básicas";

      case "presentation":
        return "Apresentação";

      case "dosage":
        return "Dose";

      case "specialsituation":
        return "Situação especial";

      case "contraindications":
        return "Contraindicação";

      case "adverse":
        return "Efeitos colaterais";

      case "useandsafety":
        return "Uso e segurança";
        
      case "content":
        return "Conteúdo de rotina";
      
      case "d_icon":
        return "Ícone de Drogas";
        
      default:
        return "Link sem origem";
        
    }
  }

  function handleLinksArray() {
    setPaginatedLinks(filteredLinks?.slice((page - 1) * 10, page * 10));
  }

  const filterLinks = (value) => {
    if (value !== '' && linkPointingHere) {
      const result = linkPointingHere.filter(({ link_origin_text }) =>
        link_origin_text?.toLowerCase()
          .normalize("NFD")
          .replace(/([\u0300-\u036f]|[^0-9a-zA-Z ])/g, "")
          .includes(value?.toLowerCase()
            .normalize("NFD")
            .replace(/([\u0300-\u036f]|[^0-9a-zA-Z ])/g, "")
          )
      );
      setFilteredLinks(result);
      setPage(1);
      return
    }
    setFilteredLinks([...linkPointingHere]);
  }

  async function handleGetLinkPointingHere() {
    try {
      const response = await api.get(`/link-manager/${id}/routine_id`)
      setLinkPointingHere(response.data);
      setFilteredLinks(response.data);
    } catch (error) {
      console.log(error)
    }
  }

  async function handleLinkFromDrugs(drugLinkId) {
    try {
      if (window.confirm("Você será redirecionado(a) para o Gerenciador de Drogas. Deseja continuar?")) {
        const result = await drugApi.get(`/linkmanager/origin/link/${drugLinkId}`)
        window.open(`http://drogas.blackbook.com.br/activeprinciple/${result?.data?.active_principle}`, `_blank`)
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    handleGetLinkPointingHere();
  }, [])

  useEffect(() => {
    handleLinksArray();
  }, [filteredLinks, page]);

  return (
    <>
      <ListLinkContainer>
        <div className="input__container">
          <label htmlFor="header__input">Digite sua busca:</label>
          <input
            className="search__input"
            type="text"
            onChange={(e) => filterLinks(e.target.value)}
          />
        </div>
        <table className="linksTables__table">
          <thead>
            <tr>
              <th>Palavra</th>
              <th>Origem</th>
              <th>Ações</th>
            </tr>
          </thead>

          <tbody>
            {paginatedLinks && paginatedLinks.map((link) =>
              <tr>
                <td>{link?.link_origin_text}</td>
                <td>{handleLinkType(link?.field_origin)}</td>
                <td>
                  {link?.field_origin === "content" &&
                    <Link
                      to={{
                        pathname: `/pendinglinks/update/${link._id}`,
                        search: '',
                        hash: '',
                        state: {
                          routineId: id
                        }
                      }}

                      title="Ver link"
                      className="showLink__button"
                      onClick={() => history.push(`/pendingLinks/update/${link._id}`)}
                    >
                      <FaEye />
                    </Link> 
                  }
                  {link?.field_origin !== "content" 
                    && link?.field_origin !== "d_icon" 
                    && handleLinkType(link?.field_origin) !== "Link sem origem" &&
                    <button
                      title="Ver link"
                      className="showLink__button"
                      onClick={() => handleLinkFromDrugs(link._id)}
                    >
                      <FaEye />
                    </button>
                  }
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </ListLinkContainer>
      {filteredLinks?.length > 10 && (
        <Pagination
          currentPage={page}
          totalCountOfRegisters={filteredLinks.length}
          onPageChange={(e) => setPage(e)}
        />
      )}
    </>
  )
}
